export function breadcrumb(
  state = [
    // { page: 'Home', url: '#' },
  ],
  action
) {
  switch (action.type) {
    case 'SET_BREADCRUMB':
      action.items.unshift({ page: 'Home', url: '/' })
      return action.items
    default:
      return state
  }
}

export const setBreadcrumb = items => {
  return {
    type: 'SET_BREADCRUMB',
    items
  }
}
