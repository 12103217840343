import React, { useEffect, useContext } from 'react';
// import CartIcon from '../../cart-icon/CartIcon'
import { isAuthenticated } from '../../../actions/auth';
import Logo from '../../logo';
// import ToggleRightSidebar from '../ToggleRightSidebar'
import MenuItems from '../navbar-1/MenuItems';
import Profile from '../../profile/Profile';
// import { loadCheckout } from '../../../actions/checkout'
import '../../../css/navbar/navbar-2.css';
import { connect } from 'react-redux';
import { TenantContext } from '../../../contexts/TenantContext';
import styled from 'styled-components';

function Navbar2(props) {
  const { tenant } = useContext(TenantContext);
  // const [checkout, setCheckout] = useState([])
  // const [userLogged, setUserLogged] = useState(false)

  // useEffect(() => {
  //   if (isAuthenticated()) {
  //     setUserLogged(true)
  //   }
  // }, [props])

  // useEffect(() => {
  //   let isSubscribed = true;

  //   if (userLogged) {
  //     loadCheckout().then(checkout => {
  //       if (isSubscribed) setCheckout(checkout)
  //     })
  //   }

  //   return () => (isSubscribed = false)
  // }, [userLogged])

  useEffect(() => {
    console.log(tenant);
  }, []);

  return (
    <Container
      tenant={tenant}
      className="navbar  d-flex justify-content-around align-items-center flex-nowrap"
    >
      <div className="container">
        <Logo />

        <div className="separator" />

        <ul className="nav nav-inline nav-inline-2">
          <li className="nav-item nav-item-dropdown">
            {isAuthenticated() && <Profile />}
          </li>
          <li className="nav-item nav-item-dropdown">
            {/* <CartIcon checkout={checkout} /> */}
          </li>
          {/* <ToggleRightSidebar /> */}
          <MenuItems />
        </ul>
      </div>
    </Container>
  );
}

export default Navbar2;

export const Container = styled.nav`
  min-height: 0;
  height: 70px;
  border: none;

  background-color: ${props => props.tenant.color_nav};
`;
