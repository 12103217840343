import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import TableFaqs from './TableFaqs'
import { indexFaqs } from '../../../actions/faq'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

const actions = [
  {
    name: 'Novo FAQ',
    class_btn: 'btn-info',
    class_collumn: 'col col-md-2',
    route: '/faqs-adm/new'
  }
]

const ListFaq = () => {
  const [faqs, setFaqs] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const loadFaqs = useCallback(() => {
    indexFaqs().then(response => {
      const _faqs = response.map((faq, i) => ({
        ...faq,
        number: i + 1
      }))
      setFaqs(_faqs)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    let isSubscribed = true

    if (isSubscribed) {
      dispatch(setBreadcrumb([{ page: 'FAQs', url: '#' }]))
      setLoading(true)
      loadFaqs()
    }

    return () => (isSubscribed = false)
  }, [loadFaqs, dispatch])

  return (
    <Widget title="Perguntas Frequentes" actions={actions} line={false}>
      <TableFaqs
        data={faqs}
        loading={loading}
        setLoading={setLoading}
        loadFaqs={loadFaqs} />
    </Widget>
  )
}

export default ListFaq