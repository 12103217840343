import styled from 'styled-components'

export const Container = styled.div`

  button {
    padding: 12px !important;
    border: 0 !important;
    font-size:  16px !important;
    border-radius: ${props => {
    if (props.template === 'button') {
      return '3px'
    } else if (props.template === 'button1') {
      return '27px 0px 27px 27px'
    } else if (props.template === 'button2') {
      return '0'
    } else if (props.template === 'button3') {
      return '20px'
    }
  }} !important;
    background: ${props => props.bgColor} !important;
    background-color: ${props => props.bgColor} !important;
    color: ${props => props.color} !important;

    :hover {
      background: ${props => props.bgColorHover} !important;
      background-color: ${props => props.bgColorHover} !important;
      color: ${props => props.colorHover} !important;
    }
  }
`
