import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F1F1F1; 

  p {
    color: #777
  }
`

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;
`

export const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-style: italic;
  color: #777
`