import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import InputLabel from '../../../components/Form/input/InputLabel'

function FormLesson(props) {
  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate>

      <InputLabel name="title" label="Título" />

      <InputLabel name="content" label="Conteúdo" />

      <div className="d-flex justify-content-end mt-5">
        <div className="col-sm col-md-2 pl-0">
          <Link to={`/courses/${props.initialData.course_id}`}>
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
            </button>
          </Link>
        </div>

        <div className="col-sm col-md-2 pr-0">
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Atualizar
          </button>
        </div>
      </div>
    </Form>
  )
}

FormLesson.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object,
}

export default FormLesson


