import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import FormClass from './FormClass'
import Subscriptions from './Subscriptions'
import { loadClassById, saveClass, updateClass } from '../../../actions/class'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

function Class(props) {
  const formRef = useRef(null)
  const [class_, setClass] = useState({})
  const [initialData, setInitialData] = useState({})
  const dispatch = useDispatch()

  const save = useCallback(async (data) => {
    saveClass(data)
      .then(response => {
        setClass(response)
      })
  }, [])

  const update = useCallback(data => {
    data.id = class_.id
    updateClass(data)
      .then(response => {
        setClass(response)
      })
  }, [class_])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        course_id: Yup.string(),
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        comments: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Descrição mínimo 8 caracteres') : Yup.string()
        })
      })

      await schema.validate(data, { abortEarly: false })

      class_.id ? update(data) : save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [class_, save, update])

  useEffect(() => {
    let isSubscribed = true
    const id = props.match.params.id

    if (id && isSubscribed) {
      loadClassById(id)
        .then(response => {
          if (!formRef.current) return null
          setClass(response)
          setInitialData(response)

          const items = [
            { page: 'Turmas', url: '/classes' },
            { page: response.name.substr(0, 10) + '...', url: '#' },
            { page: 'Editar', url: '#' },
          ]
          dispatch(setBreadcrumb(items))
        })
    }

    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Widget title={class_.id ? `Editar ${class_.name}` : 'Nova Turma'} >
      <FormClass
        initialData={initialData}
        submit={submit}
        formRef={formRef}
      />

      <Subscriptions subscriptions={initialData.subscriptions} />
    </Widget >
  )
}

export default Class;