import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { FaShoppingCart } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { loadCheckout, deleteCheckoutItem, removeCheckoutItem } from '../../actions/checkout'
import { formatPrice, formatTimeString } from '../../actions/util'
import Widget from '../../components/widget'
import { EmptyCart, TotalCart, TableCart } from './styles'

const Cart = () => {
  const [cart, setCart] = useState({ courses: [] })
  const dispatch = useDispatch()

  const removeItem = useCallback(id => {
    deleteCheckoutItem(id).then(() => {
      setCart({ courses: [] })

      const index = cart.courses.findIndex(course => course.id === id)
      cart.courses.splice(index, 1)

      setCart(cart)
      dispatch(removeCheckoutItem(id))
    })
  }, [cart, dispatch])

  useEffect(() => {
    let isSubscribed = true;

    loadCheckout()
      .then(response => {
        if (isSubscribed) {
          setCart(response)
        }
      })

    return () => (isSubscribed = false)
  }, [])

  return (
    <div className="container">
      <Widget title="Carrinho" line={false}>
        <div className="table-widget-3">
          {cart.courses && cart.courses.length > 0 ? (
            <TableCart>
              <table className="table table-unbordered table-striped mt-5">
                <tbody>
                  {cart.courses.map((course, i) => (
                    <tr key={i} className="row justify-content-between align-items-center">
                      <td>
                        <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/course-image-files/${course.resources[0].id}`}
                          crossOrigin="anonymous"
                          width="50"
                          className="img-fluid img-thumbnail"
                          alt="image_course"
                        />
                      </td>
                      <td>{course.name}</td>
                      <td>{formatTimeString(course.duration)}</td>
                      <td>{formatPrice(course.price)}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => removeItem(course.id)}>
                          X
                      </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <TotalCart className="row justify-content-between align-items-center mt-5">
                <div><span>Total</span></div>
                <div><span>{formatPrice(cart.total_pay)}</span></div>
              </TotalCart>

              <div className="row justify-content-end mt-4">
                <Link to="/checkout" className="btn btn-info btn-lg p-3 col-3">
                  Finalizar compra
                </Link>
              </div>
            </TableCart>
          ) : (
            <EmptyCart className="text-center">
              <FaShoppingCart size={60} color="#999" />

              <p>Você ainda não possui itens no carrinho!</p>

              <Link to="/" className="p-0 m-0">
                <span>Continuar comprando</span>
              </Link>
            </EmptyCart>
          )}
        </div>
      </Widget>
    </div>
  )
}

export default Cart

