import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import { getStates, cepConsult } from '../../../actions/util'
import InputLabel from '../../../components/Form/input/InputLabel'
import InputMaskLabel from '../../../components/Form/input/InputMaskLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function FormTenant(props) {
  const [optionsStates, setOptionsState] = useState([])
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    setOptionsState(getStates())
    props.formRef.current.setFieldValue('cpf_cnpj', props.initialData.cpf_cnpj)
    props.formRef.current.setFieldValue('phone', props.initialData.phone)
    props.formRef.current.setFieldValue('cell_phone', props.initialData.cell_phone)
    props.formRef.current.setFieldValue('cep', props.initialData.cep)
    props.formRef.current.setFieldValue('state',
      optionsStates.find(state => state.value === props.initialData.state))

    if (props.initialData.type) setType(props.initialData.type)
    if (props.initialData.status) setStatus(props.initialData.status)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const changeType = useCallback(e => {
    setType(e.target.value)
    props.formRef.current.setFieldValue('type', e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeStatus = useCallback(e => {
    setStatus(e.target.value)
    props.formRef.current.setFieldValue('status', e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCep = useCallback((e, optionsStates) => {
    cepConsult(e.target.value).then(address => {
      if (!address.erro) {
        props.formRef.current.setFieldValue('state',
          optionsStates.find(state => state.value === address.uf))
        props.formRef.current.setFieldValue('city', address.localidade)
        props.formRef.current.setFieldValue('neighborhood', address.bairro)
        props.formRef.current.setFieldValue('street', address.logradouro)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate>

      <div className="row ">
        <div className="col-sm">
          <div className="card p-2">
            <label><b>Dados</b></label>

            <div className="row">
              <RadioButton className="col">
                {/* usada para mapear o atributo type no FORM - NAO REMOVER */}
                <div style={{ visibility: 'hidden', height: '1px' }}>
                  <InputLabel name="type" />
                </div>
                {/* ///// */}

                <div>
                  <span>* Tipo</span>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    checked={type === 'FISICA'}
                    onChange={e => changeType(e)}
                    type="radio"
                    value="FISICA"
                    id="fisica"
                    name="type"
                    className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="fisica">Física</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    checked={type === 'JURIDICA'}
                    onChange={e => changeType(e)}
                    type="radio"
                    value="JURIDICA"
                    id="juridica"
                    name="type"
                    className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="juridica">Jurídica</label>
                </div>
              </RadioButton>

              <RadioButton className="col">
                {/* usada para mapear o atributo status no FORM - NAO REMOVER */}
                <div style={{ visibility: 'hidden', height: '1px' }}>
                  <InputLabel name="status" />
                </div>
                {/* ///// */}

                <div>
                  <span>Status</span>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    checked={status === 'ATIVO'}
                    onChange={e => changeStatus(e)}
                    type="radio"
                    value="ATIVO"
                    id="ativo"
                    name="status"
                    className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="ativo">Ativo</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    checked={status === 'INATIVO'}
                    onChange={e => changeStatus(e)}
                    type="radio"
                    value="INATIVO"
                    id="inativo"
                    name="status"
                    className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="inativo">Inativo</label>
                </div>
              </RadioButton>
            </div>

            <InputLabel name="name" label="* Nome" />

            <div className="row">
              <div className="col-sm">
                <InputLabel name="email" label="* E-mail" />
              </div>

              <div className="col-sm mt-3">
                <InputMaskLabel
                  mask="(99) 9999-9999"
                  name="phone"
                  label="Telefone"
                />
              </div>

              <div className="col-sm mt-3">
                <InputMaskLabel
                  mask="(99) 9999-9999"
                  name="cell_phone"
                  label="Celular"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-3 p-2">
        <label><b>Domínio</b></label>
        <div className="row">
          <div className="col-6">
            <InputLabel name="domain" label="* Domínio" />
          </div>

          <div className="col-6">
            <InputLabel name="subdomain" label="Subdomínio" />
          </div>
        </div>
      </div>

      <div className="card p-2 mt-3">
        <label><b>Documentos</b></label>
        <div className="row">
          <div className="col-sm col-md-6 mt-3">
            <InputMaskLabel
              name="cpf_cnpj"
              label="CPF / CNPJ"
              mask={props.initialData.type === 'FISICA'
                ? '999.999.999-99' : '99.999.999/9999-99'}
            />
          </div>

          <div className="col-sm col-md-6">
            <InputLabel name="ie" label="Inscrição estatual" />
          </div>
        </div>
      </div>

      <div className="card p-2 mt-3">
        <label><b>Endereço</b></label>
        <div className="row">
          <div className="col-sm col-md-3 mt-3">
            <InputMaskLabel
              name="cep"
              label="CEP"
              mask="99999-999"
              onBlur={e => handleCep(e, optionsStates)}
            />
          </div>

          <div className="col-sm col-md-3">
            <SelectLabel
              name="state"
              label="Estado"
              options={optionsStates}
            />
          </div>

          <div className="col-sm">
            <InputLabel name="city" label="Cidade" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm col-md-3">
            <InputLabel name="neighborhood" label="Bairro" />
          </div>

          <div className="col-sm col-md-7">
            <InputLabel name="street" label="Rua" />
          </div>

          <div className="col-sm">
            <InputLabel name="number" label="Nº" />
          </div>

          <div className="col-12">
            <InputLabel name="complement" label="Complemento" />
          </div>
        </div>
      </div>

      <div className="mt-4 d-flex justify-content-end mb-5">
        <div className="col-sm col-md-3 pl-0 ">
          <Link to="/">
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
            </button>
          </Link>
        </div>

        <div className="col-sm col-md-3 pr-0">
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}

FormTenant.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object
}

export default FormTenant

export const RadioButton = styled.div`
  span {
    font-size: 13px;
    color: #4d4d4d;
  }
`