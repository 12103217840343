import React, { useCallback, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import DeleteModal from '../../../components/delete-modal'
import Loading from '../../../components/loading'
import { deleteArea } from '../../../actions/area'

const TableAreas = (props) => {
  const [itemDelete, setItemDelete] = useState({ name: '' })
  const [openModal, setOpenModal] = useState(false)
  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      name: 'Ações',
      cell: (item) => (
        <div>
          <Link to="#">
            <i className="material-icons text-danger rtd_button" onClick={() => confirmDelete(item)}>delete_forever</i>
          </Link>
        </div>
      ),
      button: true,
    },
  ]

  const handleRowClicked = useCallback(reg => {
    props.history.push(`/areas/${reg.id}`)
  }, [props])

  const confirmDelete = useCallback((item) => {
    setOpenModal(true)
    setItemDelete({ id: item.id, name: item.name })
  }, [])

  const deleteItem = useCallback(() => {
    deleteArea(itemDelete.id).then(() => {
      props.loadAreas()
      setOpenModal(false)
      setItemDelete({ name: '' })
    })
  }, [props, itemDelete])

  const cancelDelete = useCallback(() => {
    setOpenModal(false)
    setItemDelete({ name: '' })
  }, [])

  return (
    < div className="row" >
      <div className="col">
        <DataTable
          columns={columns}
          data={props.data}
          pointerOnHover
          highlightOnHover
          noDataComponent="Nenhum registro encontrado"
          onRowClicked={handleRowClicked}
          progressPending={props.loading}
          progressComponent={<Loading />}
        />
      </div>

      <DeleteModal
        isOpen={openModal}
        isHide={cancelDelete}
        itemDeleteName={`a àrea ${itemDelete.name}`}
        onDeleteItem={deleteItem}
      />
    </div >
  )
}

TableAreas.defaultProps = {
  data: Array,
  loadAreas: Function,
  loading: Boolean,
  setLoading: Function
}

export default withRouter(TableAreas)