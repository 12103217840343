import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import DataTable from 'react-data-table-component';
import Loading from '../../../components/loading'
import { loadPurchases } from '../../../actions/purchase'
import { dateToString, formatPrice } from '../../../actions/util'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

const columns = [
  {
    name: '#',
    selector: 'number',
  },
  {
    name: 'Data',
    selector: 'purchase_date',
    sortable: true,
    grow: 2
  },
  {
    name: 'Valor',
    selector: 'total_pay_format',
    sortable: true,
    grow: 3
  }
]
const ListPurchase = () => {
  const [purchases, setPurchases] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const getPurchases = useCallback(() => {
    loadPurchases().then(response => {
      const _purchases = response.map((purchase, i) => ({
        ...purchase,
        number: i + 1,
        purchase_date: dateToString(purchase.created_at),
        total_pay_format: formatPrice(purchase.total_pay)
      }))

      setPurchases(_purchases)
      setLoading(false)
      dispatch(setBreadcrumb([{ page: 'Minhas compras', url: '#' }]))
    })
  }, [dispatch])

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      setLoading(true)
      getPurchases()
    }

    return () => (isSubscribed = false)
  }, [getPurchases])


  const handleRowClicked = reg => {
    this.props.history.push(`/purchases/${reg.id}`)
  };

  return (
    <Widget title="Minhas Compras" line={false}>
      <div className="row d-flex">
        <div className="col table">
          <DataTable
            columns={columns}
            data={purchases}
            progressPending={loading}
            pointerOnHover
            highlightOnHover
            noDataComponent="Nenhum registro encontrado"
            onRowClicked={handleRowClicked}
            progressComponent={<Loading />}
          />
        </div>
      </div>
    </Widget >
  )
}

export default ListPurchase