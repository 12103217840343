import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import CurrencyFormat from 'react-currency-format'

import { Container } from './styles'

export default function InputCurrencyLabel({ name, label, defaultValue, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value'
    });
  }, [fieldName, registerField]);

  return (
    <Container >
      <label className="mt-3 text-left" htmlFor={fieldName}>{label}</label>

      <CurrencyFormat
        id={fieldName}
        ref={inputRef}
        value={defaultValue}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'R$'}
        className={error ? 'input-error' : ''}
        {...rest}
      />

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
}