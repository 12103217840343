import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Progress } from 'reactstrap'
import { formatSimplePrice } from '../../../actions/util'
import { GoGraph } from 'react-icons/go'

const Subscriptions = (props) => (

  <div className="row">
    {
      props.subscriptions && props.subscriptions.length > 0 && (
        <div className="col-12 mt-5">
          <h5>
            <Link to="/subscriptions">Matrículas</Link>
          </h5>

          <table className="table table-striped table-hover mt-4">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col">Curso</th>
                <th scope="col">%</th>
                <th scope="col"><GoGraph /></th>
              </tr>
            </thead>
            <tbody>
              {props.subscriptions && props.subscriptions.map((subscription, i) => (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>
                    <Link to={`/users/${subscription.user.id}`}>
                      <Name>{subscription.user.name}</Name>
                    </Link>
                  </td>
                  <td>
                    {subscription.course.name}
                  </td>
                  <td>
                    {formatSimplePrice(subscription.progress.perc_completed)} %
                  </td>
                  <td>
                    <Progress value={subscription.progress.perc_completed} max="100" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  </div>
)

export default Subscriptions;

export const Name = styled.div`
  :hover{
    color: #0288d1;
  }
`