import React, { useCallback, useRef } from 'react'
import { Form } from '@unform/web'
import ReactTooltip from 'react-tooltip'
import InputFilter from '../../../components/Form/input/InputFilter'

const FormSearch = (props) => {
  const formRef = useRef(null)

  const onChangeName = useCallback(e => {
    let params = { value: '', type: 'user_name' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
    }
  }, [props])

  const onChangeCourse = useCallback(e => {
    let params = { value: '', type: 'course_name' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
    }
  }, [props])

  const onChangeClass = useCallback(e => {
    let params = { value: '', type: 'class_name' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
    }
  }, [props])

  const clearFilter = useCallback(() => {
    props.filter({ type: 'clear' })
    formRef.current.setFieldValue('user_name', '')
    formRef.current.setFieldValue('course_name', '')
    formRef.current.setFieldValue('class_name', '')
  }, [props])

  return (
    <Form
      ref={formRef}
      noValidate>
      <div className="row">
        <div className="col-sm mb-2">
          <InputFilter
            name="user_name"
            onChange={onChangeName}
            placeholder="Filtrar por nome" />
        </div>

        <div className="col-sm mb-2">
          <InputFilter
            name="course_name"
            onChange={onChangeCourse}
            placeholder="Filtrar por curso" />
        </div>

        <div className="col-sm mb-2">
          <InputFilter name="class_name"
            onChange={onChangeClass}
            placeholder="Filtrar por turma" />
        </div>

        <div className="col-sm mb-2 col-md-1 text-right btn-clear">
          <button
            type="button"
            className="btn border-danger btn-lg btn-block"
            onClick={clearFilter}
            data-tip='' data-for="clear">X</button>
          <ReactTooltip id='clear'>Limpar filtro</ReactTooltip>
        </div>
      </div>
    </Form >
  )
}

FormSearch.defaultProps = {
  filter: Function
}

export default FormSearch