import { logoff } from './auth'

export const handleError = (error, props) => {
  const erro = error.response
  console.log(erro);

  if (erro && erro.status === 400) {
    if (erro.data.length > 0) {
      return erro.data[0].message
    }

    if (erro.data.error) {
      return erro.data.error.message
    }
  };

  if (erro && erro.status === 401 &&
    erro.data.error.message === 'The Jwt token is invalid') logoff()

  if (erro === undefined) {
    // logoff()
    // window.location.href = "/error"
    return `Servidor não encontrado! Entre em contato com o suporte!`
  }

  if (erro.status === 403) {
    window.location.href = "/forbiden"

    return erro.data.error.message
  }

  if (erro.status === 500) {
    // window.location.href = "/error"
    return erro.data.error.message
  }

  if (erro.data.error && erro.data.error.message) {
    return erro.data.error.message
  }

  if (erro.data.error && erro.data.error.messages) {
    for (let i = 0; i < erro.data.error.messages.length; i++) {
      const message = erro.data.error.messages[i];
      return message
    }
  }
}
