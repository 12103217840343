import React from 'react';
import { useField } from '@unform/core';

import { Container } from './styles'

export default function Label({ name, label }) {
  const { fieldName } = useField(name);

  return (
    <Container >
      <label htmlFor={fieldName}>{label}</label>
    </Container>
  );
}