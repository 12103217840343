import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'

import Widget from '../../../components/widget'
import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import FormLesson from './FormLesson'
import UploadFile from '../UploadFile'
import LessonResources from '../LessonResources'

function Lesson(props) {
  const formRef = useRef(null)
  const [lesson, setLesson] = useState({})
  const [initialData, setInitialData] = useState({})
  const [uploadingFile, setUploadingFile] = useState(false)

  const getLesson = useCallback(async (id) => {
    await api.get(`lessons/${id}`)
      .then(result => {
        if (!formRef.current) return null
        result.data.course_id = props.match.params.course_id

        setLesson(result.data)
        setInitialData(result.data)

        props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Aulas', url: '#' },
            { page: result.data.title, url: '#' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) props.history.push('/')
        toastError(handleError(error))
      });
  }, [props])

  const update = useCallback(async (data) => {
    lesson.title = data.title
    lesson.content = data.content

    await api.put(`lessons/${lesson.id}`, lesson)
      .then(result => {
        toastSuccess(`Registro de ${result.data.title} atualizado!`);
        getLesson(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }, [lesson, getLesson])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        title: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        content: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Conteúdo mínimo 8 caracteres') : Yup.string()
        })
      })

      await schema.validate(data, { abortEarly: false })

      update(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [update])

  const isUploadingFile = e => {
    setUploadingFile(e)
  }

  useEffect(() => {
    const id = props.match.params.id
    if (id && Object.entries(lesson).length === 0) getLesson(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, lesson])

  return (
    <Widget title={`Editar ${lesson.title}`} >
      <FormLesson
        initialData={initialData}
        submit={submit}
        formRef={formRef}
      />

      <UploadFile
        lesson_id={lesson.id || null}
        isUploadingFile={e => isUploadingFile(e)}
        updateLessonPage={() => getLesson(lesson.id)} />

      <LessonResources
        uploadingFile={uploadingFile}
        resources={lesson.resources || []}
        lesson_id={lesson.id || ''}
        updateLessonPage={() => getLesson(lesson.id)} />

    </Widget >
  )
}

export default Lesson;