import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../actions/api'
import axios from 'axios'
import { loadImageArea } from '../../../actions/area'

function Areas(props) {
  const [areas, setAreas] = useState([])
  const [layout, setLayout] = useState({})

  const prepareImages = useCallback(areas => {
    for (let i = 0; i < areas.length; i++) {
      const area = areas[i];
      const item = areas[i].resources[0];

      if (item && item.id) {
        loadImageArea(item.id).then(thumbnail => {
          let img = document.getElementById(area.id)
          img.src = thumbnail
        })
      } else {
        let img = new Image()
        img.src = '/assets/images/crop.png'
        img.id = 'image'
        img.classList.add('img-fluid')

        document.getElementById(area.id)
          .appendChild(img)
      }
    }
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()

    api.get('layout-areas', { cancelToken: source.token })
      .then(result => setLayout(result.data))
      .catch(() => { })

    return () => source.cancel()
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()

    api.get('areas', { cancelToken: source.token })
      .then(result => {
        setAreas(result.data)
        prepareImages(result.data)
      })
      .catch(() => { })

    return () => source.cancel()
  }, [prepareImages])

  return (
    <div className="container mt-4">
      <Container className="row text-center" layout={layout}>
        <div className="col-12 mt-3 p-5 mb-4">
          <h1>{layout.title}</h1>
        </div>

        {areas.map((area, i) => (
          <CardArea key={i} className="card mb-5" layout={layout}>
            <div className="row no-gutters">
              <div className="col-md-6">
                <img
                  id={area.id}
                  src="/assets/images/no-image.png"
                  className="img-fluid" alt="foto area" />
              </div>
              <div className="col-md-6">
                <div className="card-body">
                  <p className="card-text text-left">Área</p>
                  <div className="line"></div>
                  <h5 className="card-title text-left">{area.name}</h5>
                </div>
                <div className="card-footer">
                  <Link
                    to={`area-detail/${area.id}`}
                    className="btn btn-info btn-sm col-sm">
                    + CURSOS
                  </Link>
                </div>
              </div>
            </div>
          </CardArea>
        ))}
      </Container>
    </div >
  )
}

export default Areas

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  
  h1 {
    font-size: 26px;
    color: ${props => props.layout.title_font_color !== '#ffffff'
    ? props.layout.title_font_color : '#000000'};
    font-weight: bold;
  }
`

export const CardArea = styled.div`
width: 380px;
color: ${props => props.layout.card_text_color};
border: none!important;
  .card-footer {
  border: none!important;
  background: none;
}
  .card-body {
  height: 145px;
}

h5{
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

  .line {
  margin-top: -15px;
  margin-bottom: 10px;
  height: 3px;
  width: 40px;
  background-color: ${props => props.layout.button_background_color};
}

a {
  border: none;
  background: ${props => props.layout.button_background_color} !important;
  color: ${props => props.layout.button_text_color} !important;
  border-radius: ${props => {
    switch (props.layout.button_name) {
      case 'button1':
        return 'none'

      case 'button2':
        return '24px'

      case 'button3':
        return '24px 0 24px 24px'

      case 'button4':
        return '24px 24px 24px 0'

      case 'button5':
        return '0 24px 24px 24px'

      case 'button6':
        return '0 0 0 0'

      default:
        return 'none'
    }
  }
  };
    
  :hover {
    background: ${props => props.layout.button_background_color_hover} !important;
    color: ${props => props.layout.button_text_color_hover} !important;
  }
}

img {
  border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px'

      case 'card3':
        return '24px 0 24px 24px'

      case 'card4':
        return '24px 24px 24px 0'

      case 'card5':
        return '0 24px 24px 24px'

      case 'card6':
        return '12px'

      default:
        return 'none'
    }
  }
  };
}

@media(max-width: 800px) {
  width: 350px;
  p { font-size: 12px };
  h5 { font-size: 14px };
    .card-body {
    height: 135px;
  }
}

@media(max-width: 736px) {
  width: 180px;
  p { font-size: 12px };
  h5 { font-size: 14px };
}

@media(max-width: 568px) {
  width: 100%;
  p { font-size: 14px };
  h5 { font-size: 20px };
    .card-body {
    height: 100px;
  }
}

@media(max-width: 499px) {
  width: 100%;
  p { font-size: 14px };
  h5 { font-size: 18px };

  a {
    height: 40px;
  }

    .btn.btn-sm {
    padding: 10px 10px!important;
    font-size: 14px!important;
  }
}
`