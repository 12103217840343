import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import TableUser from './TableUser'
import DeleteModal from '../../../components/delete-modal'
import SearchUser from './SearchUser'
import { index, remove } from '../../../actions/user'

const ListUser = (props) => {

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [itemDelete, setItemDelete] = useState({ name: '' })
  const [params, setParams] = useState({ page: 1, perPage: 10 })
  const dispatch = useDispatch()
  const actions = [
    {
      name: 'Novo Usuário',
      class_btn: 'btn-info',
      class_collumn: 'col col-md-2',
      route: 'users/new'
    }
  ]
  // const paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' }

  const loadUsers = useCallback(() => {
    index(params)
      .then(users => {
        setUsers(users)
        setLoading(false)
      })
  }, [params])

  const handleRowClicked = useCallback(reg => {
    props.history.push(`/users/${reg.id}`)
  }, [props])

  const cancelDelete = useCallback(() => {
    setOpenModal(false)
    setItemDelete({ name: '' })
  }, [])

  const confirmDelete = useCallback((item) => {
    setOpenModal(true)
    setItemDelete(item)
  }, [])

  const deleteItem = useCallback(() => {
    remove(itemDelete.id).then(() => {
      loadUsers()
      setOpenModal(false)
      setItemDelete({ name: '' })
    })
  }, [loadUsers, itemDelete])

  const handlePerRowsChange = useCallback(async (perPage,) => {
    params.perPage = perPage
    setParams(params)
    loadUsers()
    window.scrollTo(0, 0)
  }, [params, loadUsers])

  const onChangePage = useCallback(async page => {
    params.page = page
    setParams(params)
    loadUsers()
  }, [params, loadUsers])

  const filter = useCallback(e => {
    if (e.type === 'username') params.username = e.value
    if (e.type === 'name') params.name = e.value
    if (e.type === 'email') params.email = e.value
    if (e.type === 'clear') {
      params.username = ''
      params.name = ''
      params.email = ''
    }
    setParams(params)
    loadUsers()
  }, [params, loadUsers])

  useEffect(() => {
    window.scrollTo(0, 0)

    let isSubscribed = true

    if (isSubscribed) {
      dispatch({
        type: 'SET_BREADCRUMB', items: [{ page: 'Usuários', url: '#' }]
      })
      setLoading(true)
      loadUsers()
    }

    return () => (isSubscribed = false)
  }, [loadUsers, dispatch])

  return (
    <Widget title="Usuários" actions={actions} line={false}>

      <SearchUser filter={filter} />

      <TableUser
        data={users}
        progressPending={loading}
        confirmDelete={confirmDelete}
        onRowClicked={handleRowClicked}
        paginationPerPage={params.perPage}
        handlePerRowsChange={handlePerRowsChange}
        onChangePage={onChangePage}
      />

      <DeleteModal
        isOpen={openModal}
        isHide={cancelDelete}
        itemDeleteName={itemDelete.name}
        onDeleteItem={deleteItem}
      />
    </Widget>
  )
}

export default ListUser