import styled from 'styled-components'

export const ImageArea = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  background-color: #F0F0F0;
  padding: 20px;
  /* margin-right: 10px; */

  .image {
    /* border-radius: 100px; */
    max-height: 195px;
  }
`