import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../actions/api'
import { toastSuccess } from '../../../actions/toast'
import { Form } from '@unform/web'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'

function BannerArea(props) {
  const [layout, setLayout] = useState({})
  const [editing, setEditing] = useState(false)
  const formRef = useRef(null)

  const submit = useCallback(async data => {
    await api.post('layout-area-details', layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Banner atualizado!')
      })
  }, [layout])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
  }, [props])

  return (
    <>
      <Banner layout={layout} area={props.area}>
        <div className="bg-color"></div>
        <div className="text-card col-md-5 text-center p-3">
          {!editing && (
            <>
              <h1>{props.area.name}</h1>
              <p>{props.area.description}</p>
            </>
          )}

          {editing && (
            <>
              <h1>Atenção!!</h1><p>Para alterar o nome da área ou descrição,
              altere as informações em
                <Link to={`/areas`}> <b>Áreas.</b></Link>
              </p>
            </>
          )}
        </div>

        {!editing && (
          <div className="row no-editing">
            <div className="col">
              <button
                className="btn btn-info mb-3"
                onClick={() => setEditing(true)}>
                Editar Layout Banner
              </button>
            </div>
          </div>
        )}

        {editing && (
          <div className="editing">
            <Form
              ref={formRef}
              initialData={layout}
              onSubmit={submit}
              noValidate>
              <div className="row col pr-0 pl-3">
                <div className="col">
                  <InputColorLabel
                    name="banner_background_color"
                    label="Cor Fundo"
                    height="40px"
                    setColor={() => setLayout({ ...layout, banner_background_color: formRef.current.getFieldValue('banner_background_color') })}
                    defaultValue={layout.banner_background_color} />
                </div>
                <div className="col">
                  <InputColorLabel
                    name="banner_text_color"
                    label="Cor Fonte"
                    height="40px"
                    setColor={() => setLayout({ ...layout, banner_text_color: formRef.current.getFieldValue('banner_text_color') })}
                    defaultValue={layout.banner_text_color} />
                </div>
              </div>

              <div className="col-12 text-center mb-3">
                <button type="submit" className="btn btn-lg btn-info">
                  Atualizar
                  </button>
                <button type="button"
                  className="btn btn-lg btn-warning m-2"
                  onClick={() => setEditing(false)}>
                  Cancelar
                  </button>
              </div>
            </Form>
          </div>
        )}
      </Banner>
    </>
  )
}

BannerArea.defaultProps = {
  area: Object
}

export default BannerArea

export const Banner = styled.div`
  font-family: 'Oswald', sans-serif;
  height: 400px;
  margin-left: -15px;
  margin-right: -20px;
  margin-top: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: url('/assets/images/course.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  h1 { font-size: 26px; }
  p { 
    font-size: 16px; 
    font-weight: normal;
  }

  .bg-color {
    width: 100%;
    height: 400px;
    background: ${props => props.area.primary_color};
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.5;
  }
  
  .text-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 180px;
    background: ${props => props.layout.banner_background_color};
    font-size: 30px;
    color: ${props => props.layout.banner_text_color};
    position: absolute;
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-weight: bold;
      text-transform: uppercase; 
    }
  }
  
  .no-editing {
    flex-direction: column;
    
    position: relative;
    margin-top: 220px;
  }
  
  .editing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    position: relative;
    margin-top: 300px;
  }
`
