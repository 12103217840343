import api from './api'
import { toastError } from './toast'
import { handleError } from './handle-error'

export function setConfig(key, value) {
  let config = {
    [key]: value
  }
  return {
    type: 'SET_CONFIG',
    config
  }
}

export function setTheme(theme) {
  let config = {
    layout: theme.layout,
    background: theme.background,
    navbar: theme.navbar,
    topNavigation: theme.top_navigation,
    logo: theme.logo,
    leftSidebar: theme.left_sidebar,
    collapsed: theme.collapsed,
    leftSidebarIcons: theme.left_sidebar_icons,
    rightSidebar: theme.right_sidebar,
  }
  return {
    type: 'SET_CONFIG',
    config
  }
}

export function setLayout(layout) {
  return {
    type: 'SET_LAYOUT',
    layout
  }
}

export const getTheme = () => {
  const result = new Promise((resolve, reject) => {
    api.get('themes')
      .then(result => {
        resolve(result.data)
      })
      .catch(error => toastError(handleError(error)))
  })

  return result
}
