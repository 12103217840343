import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { loadImageCourse } from '../../../actions/course'
import { formatPrice, formatTimeString } from '../../../actions/util'

function AreaCourses(props) {
  const [layout, setLayout] = useState({})

  const prepareImages = useCallback(courses => {
    for (let i = 0; i < courses.length; i++) {
      const course = courses[i];
      if (course.resources.length > 0 && course.resources[0].id) {
        loadImageCourse(course.resources[0].id).then(thumbnail => {
          let img = document.getElementById(course.id)
          img.src = thumbnail
        })
      }
    }
  }, [])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.area.courses && props.area.courses.length > 0) {
      prepareImages(props.area.courses);
    }
  }, [props, prepareImages])

  return (
    <div className="container mt-4 mb-3">
      <Container className="row text-center" layout={layout}>
        <div className="col-12 mt-3 p-5 mb-3">
          <h1>{layout.courses_title}</h1>
        </div>

        {props.area.id && (
          props.area.courses.map((course, i) => (
            <div key={i} className="col-sm col-md-4">
              <Link to={`/course-detail/${course.id}`}>
                <CardCourse index={i} className="row no-gutters mb-3"
                  area={props.area}
                  layout={layout}>
                  <div className="col-md-5">
                    <img id={course.id} src="/assets/images/no-image.png" className="img-fluid"
                      alt="course" />
                  </div>
                  <div className="col-md-7">
                    <div className=" card-body">
                      <h5 className="card-title">{course.name}</h5>
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                      <p>{formatTimeString(course.duration)} H </p>
                      <p className="ml-3">|</p>
                      <p>{formatPrice(course.value)}</p>
                    </div>
                  </div>
                </CardCourse>
              </Link>
            </div>
          ))
        )}
      </Container>
    </div >
  )
}

AreaCourses.defaultProps = {
  area: Object,
  layout: Object
}

export default AreaCourses

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  
  h1 {
    font-size: 26px;
    color: ${props => props.layout.courses_title_color};
    font-weight: bold;
  }
`

export const CardCourse = styled.div`
  background-color: ${props => props.area.primary_color};
  color: ${props => props.layout.card_text_color};
  max-height: 120px;

  border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px'

      case 'card3':
        return '24px 0 24px 24px'

      case 'card4':
        return '24px 24px 24px 0'

      case 'card5':
        return '0 24px 24px 24px'

      default:
        return 'none'
    }
  }};
  
  h5{
    font-size: 20px;
    font-weight: bold;
  }

  p { font-size: 16px; }

  .card-footer {
    border: none !important;
    background: none;
  }
  .card-body {
    height: 75px;
  }

  :hover {
    cursor: pointer;
    background-color: ${props => props.area.second_color};
    color: ${props => props.layout.card_text_color_hover};
  }

  img{
    max-height: 120px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px 0 0 24px'

      case 'card3':
        return '24px 0 0 24px'

      case 'card4':
        return '24px 0 0 0'

      case 'card5':
        return '0 0 0 24px'

      default:
        return 'none'
    }
  }};
  }

  @media (max-width: 800px) {

    p {font-size: 12px};
    h5 {font-size: 14px};
    max-height: 100px;

    .card-body {
      height: 60px;
    }
  }
  
  @media (max-width: 736px) {
    max-height: 200px;
    p {font-size: 12px};
    h5 {font-size: 14px};

    img {
      border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card2':
        return '24px 24px 0 0'

      case 'card3':
        return '24px 0 0 0'

      case 'card4':
        return '24px 24px 0 0'

      case 'card5':
        return '0 24px 0 0'

      default:
        return 'none'
    }
  }};
    }
  }
  
  
  @media (min-width: 350px) and (max-width: 499px) {
    max-height: 240px;
    h5 {font-size: 22px};
    p {font-size: 18px};
  }
  
  @media (max-width: 349px) {
    max-height: 230px;
    h5 {font-size: 18px};
    p {font-size: 16px};
  }
`