import React, { useEffect } from 'react'
import IndigoPlayer from "indigo-player";
import "indigo-player/lib/indigo-theme.css";

import Widget from '../../components/widget'


// https://hy-videos.s3-sa-east-1.amazonaws.com/video.mpd

function IndigoPlayerFunction() {
  useEffect(() => {
    const element = document.getElementById('playerContainer');

    const config = {
      aspectRatio: 16 / 9,
      sources: [
        {
          type: 'dash',
          // src: 'https://ead-api.s3-sa-east-1.amazonaws.com/videos/1597683673787/1597683673787.mpd',
          src: 'https://hy-videos.s3-sa-east-1.amazonaws.com/video.mpd',

        },
      ],
      ui: {
        locale: 'pt-BR',
      },
    }

    const player = IndigoPlayer.init(element, config);
    return () => player.destroy()
  }, [])

  return (
    <Widget title="Vídeo" >
      <div className="row">
        <div className="col text-center">
          {/* <div id="playerContainer" style={{ width: `100vmin` }}></div> */}
          <div id="playerContainer"></div>
        </div>
      </div>
    </Widget >
  )
}

export default IndigoPlayerFunction
