import React from 'react';

import { Container } from './styles'

export default function Button({ template, onClick, label, bgColor, bgColorHover, color, colorHover, ...rest }) {
  return (
    <Container bgColor={bgColor} bgColorHover={bgColorHover} color={color} colorHover={colorHover} template={template}>
      <button onClick={onClick} {...rest}>
        {label}
      </button>
    </Container>
  );
}