import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AiOutlineEdit } from 'react-icons/ai'
import { FcPicture, FcVideoCall, FcLink, FcFile } from 'react-icons/fc'
import { AiFillYoutube } from 'react-icons/ai'

import DeleteLesson from './DeleteLesson'

function LessonItem(props) {
  const [lesson, setLesson] = useState({})
  const [canRemove, setCanRemove] = useState({})

  const handleCanRemove = useCallback(() => {
    if (lesson.resources && lesson.resources.length > 0) {
      setCanRemove(true)
    } else {
      setCanRemove(false)
    }
  }, [lesson])

  useEffect(() => {
    setLesson(props.lesson)
    handleCanRemove()
  }, [props, lesson, handleCanRemove])
  return (
    <tr className="mt-1">
      <td>
        <div className="row">
          <div className="col-sm col-md-9">
            <div className="d-flex">
              <div className="col-1 mr-3" />
              <NameLesson className="col-sm" onClick={() => setLesson(true)}>
                <Link to={`/courses/${props.course_id}/lessons/${lesson.id}`}>
                  <div className="row">
                    <div className="col-5 mt-2">
                      {lesson.title}
                    </div>
                    <div className="col-sm d-flex">
                      {lesson.resources && lesson.resources.map((resource, i) => (
                        <div key={i} className="p-2">
                          {resource.type === 'video' && (
                            <FcVideoCall size={20} />
                          )}
                          {resource.type === 'image' && <FcPicture size={20} />}
                          {resource.type === 'url' && <FcLink size={20} />}
                          {resource.type === 'youtube' && (
                            <AiFillYoutube size={20} color="#c82829" />
                          )}
                          {resource.type === 'application' && <FcFile size={20} />}
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>
              </NameLesson>
            </div>
          </div>

          <div className="col-sm row justify-content-end">
            <Link to={`/courses/${props.course_id}/lessons/${lesson.id}`}>
              <button
                className="btn btn-sm border-primary m-1">
                <AiOutlineEdit />
              </button>
            </Link>

            <div>
              <DeleteLesson
                id={lesson.id}
                name={lesson.title}
                disabled={canRemove}
                updateCoursePage={() => props.updateCoursePage()} />
            </div>
          </div>
        </div>
      </td >
    </tr >
  )
}
LessonItem.defaultProps = {
  lesson: Object,
  updateCoursePage: Function
}

export default LessonItem

export const NameLesson = styled.div`
  :hover {
    cursor: pointer;
    a {
      color: #0288d1;
    }
  }
`