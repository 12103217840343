import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import Widget from '../../../../components/widget'
import FormSubscription from './FormSubscription'
import { store } from '../../../../actions/subscription'
import { students } from '../../../../actions/user'
import { indexCourses } from '../../../../actions/course'
import { indexClassNoPaginalble } from '../../../../actions/class'

function NewSubscription(props) {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const [optionsUser, setOptionsUser] = useState([])
  const [optionsCourse, setOptionsCourse] = useState([])
  const [optionsClass, setOptionsClass] = useState([])
  const [noDataMessageClass, setNoDataMessageClass] = useState('Selecione um CURSO')

  const save = useCallback(data => {
    store(data)
      .then(subscription =>
        props.history.push(`/subscriptions/${subscription.id}`))
  }, [props])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        course_id: Yup.string().required('Curso é obrigatório'),
        class_id: Yup.string(),
        user_id: Yup.string().required('Aluno é obrigatório'),
      })

      await schema.validate(data, { abortEarly: false })

      save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [save])

  const prepareOptionsUser = useCallback(() => {
    students()
      .then(response => {
        const options = response.map(item => {
          return { label: item.name, value: item.id }
        })
        setOptionsUser(options)
      })
  }, [])

  const prepareOptionsCourse = useCallback(() => {
    indexCourses({ status: 'ATIVO' })
      .then(response => {
        const options = response.map(item => {
          return { label: item.name, value: item.id }
        })
        setOptionsCourse(options)
      })
  }, [])

  const prepareOptionsClass = useCallback(() => {
    setOptionsClass([])
    
    indexClassNoPaginalble()
      .then(response => {
        const options = response.data.map(item => {
          return { label: item.name, value: item.id }
        })
        setOptionsClass(options)
        if (response.length === 0)
          setNoDataMessageClass('Não exitem turmas para o curso selecionado')
      })
  }, [])

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      prepareOptionsCourse()
      prepareOptionsUser()
      dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Matrículas', url: '/subscriptions' },
          { page: 'Novo', url: '#' },
        ]
      })
    }

    return () => (isSubscribed = false)
  }, [prepareOptionsCourse, prepareOptionsUser, dispatch])

  return (
    <Widget title="Nova Matrícula" >
      <FormSubscription
        submit={submit}
        formRef={formRef}
        optionsCourse={optionsCourse}
        optionsClass={optionsClass}
        optionsUser={optionsUser}
        noDataMessageClass={noDataMessageClass}
        prepareOptionsClass={prepareOptionsClass}
      />
    </Widget >
  )
}

export default NewSubscription;