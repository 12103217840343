import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Progress } from 'reactstrap';
import { FcViewDetails } from 'react-icons/fc'
import SelectLabel from '../../../../components/Form/input/SelectLabel'
import InputLabel from '../../../../components/Form/input/InputLabel'
import { ProgressCourse } from './styles'

const FormSubscription = ({ initialData, formRef, submit,
  selectedClass, optionsClass, removeClass, setSelectedClass }) => (
    <Form
      ref={formRef}
      initialData={initialData}
      onSubmit={submit}
      noValidate>
      <div className="row justify-content-center ">
        <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
          <div className="col">
            <InputLabel
              name="course.name"
              label="Curso"
              disabled={true}
            />
          </div>

          <div className="col-1">
            <Link to={`/courses/${initialData.course_id}`} >
              <FcViewDetails size={47} data-tip='' data-for="course_id" />
              <ReactTooltip id='course_id'>Ver detalhes do curso</ReactTooltip>
            </Link>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
          <div className="col">
            <SelectLabel
              name="class_id"
              label="Turma"
              value={selectedClass}
              onChange={e => setSelectedClass(e)}
              placeholder="Selecione.."
              options={optionsClass}
            />
          </div>

          <div className="col-1">
            <Link to={`/classes/${initialData.class_id}`} >
              <FcViewDetails size={47} data-tip='' data-for="class_id" />
              <ReactTooltip id='class_id'>Ver detalhes da turma</ReactTooltip>
            </Link>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
          <div className="col">
            <InputLabel
              name="user.name"
              label="Aluno"
              disabled={true}
            />
          </div>

          <div className="col-1">
            <Link to={`/users/${initialData.user_id}`} >
              <FcViewDetails size={47} data-tip='' data-for="user_id" />
              <ReactTooltip id='user_id'>Ver detalhes do aluno</ReactTooltip>
            </Link>
          </div>
        </div>
      </div>

      {initialData.id && (
        <div className="row justify-content-center">
          <div className="col-sm col-md-6 mt-3">
            <label>Progresso do curso</label>
            <ProgressCourse>
              <Progress
                value={initialData.course.perc_completed}
                max="100"
                color="info"
                striped={true}
                animated={true}>
                {initialData.course.perc_completed}%
                </Progress>
            </ProgressCourse>
          </div>
        </div>
      )}

      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-6 row p-0">
          {initialData.class_id && (
            <div className="col-sm mb-3">
              <button
                type="button"
                className="btn btn-lg  btn-block border-danger"
                onClick={removeClass}>
                Remover aluno da turma
              </button>
            </div>
          )}

          <div className="col-sm mb-3">
            <Link to="/subscriptions" className="btn btn-lg  btn-block border-info">
              Voltar
            </Link>
          </div>

          <div className={initialData.class_id ? `col-12` : `col-sm`}>
            <button type="submit" className="btn btn-lg btn-block btn-info">
              {initialData.id ? 'Atualizar' : ' Salvar'}
            </button>
          </div>
        </div>
        {/* <div className="col-sm col-md-6 d-flex justify-content-end p-0">
        <div className="col">
          <button
            type="button"
            className="btn btn-lg  btn-block border-danger"
            onClick={removeClass}>
            Remover aluno da turma
        </button>
        </div>
        <div className="col">
          <Link to="/subscriptions" className="btn btn-lg  btn-block border-info">
            Voltar
          </Link>
        </div>
      </div> */}
      </div>

      {/* <div className="row justify-content-center mt-3">
      <div className="col-sm col-md-6">
        <button type="submit" className="btn btn-lg btn-block btn-info">
          {initialData.id ? 'Atualizar' : ' Salvar'}
        </button>
      </div>
    </div> */}
    </Form >
  )

FormSubscription.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object,
  selectedClass: Object,
  setSelectedClass: Function,
  optionsClass: Object,
  removeClass: Function
}

export default FormSubscription