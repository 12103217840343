import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function InputLabel({ name, label, link, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label className="mt-3 text-left" htmlFor={fieldName}>
        {link ? <Link to={link}>{label}</Link> : <>{label}</>}
      </label>

      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={e => e.target.value}
        className={error ? 'input-error' : ''}
        {...rest}
      />

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
}
