import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const index = params => {
  const response = new Promise((resolve, reject) => {
    api.get(`subscriptions`, { params: params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const store = data => {
  const response = new Promise((resolve, reject) => {
    api.post(`subscriptions`, data)
      .then(response => {
        resolve(response.data)
        toastSuccess('Nova matrícula incluída com sucesso!')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}

export const update = data => {
  const response = new Promise((resolve, reject) => {
    api.put(`subscriptions/${data.id}`, data)
      .then(response => {
        resolve(response.data)
        toastSuccess('Matrícula atualizada!')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}

export const remove = id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`subscriptions/${id}`)
      .then(() => {
        toastSuccess('Matrícula removida com sucesso!');
        resolve()
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const show = id => {
  const response = new Promise((resolve, reject) => {
    api.get(`subscriptions/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}
