import React, { useState, useCallback, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import api from '../../actions/api'
import { logoff } from '../../actions/auth'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

function ModalAlterPassword(props) {
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [user, setUser] = useState({ name: '' })

  const submit = useCallback(async () => {
    await api.post('forgot', { email: user.email })
      .then(res => {
        toastSuccess('E-mail enviado - confira seu e-mail')
        setSuccess(true)
        setTimeout(() => {
          logoff()
        }, 5000);
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }, [user])

  useEffect(() => {
    setUser(props.user)
    setShow(props.isOpen)
  }, [props])

  return (
    <Modal
      isOpen={show}
      wrapClassName="modal-info"
      size="default">
      <ModalHeader >{!success ? 'Alterar Senha' : 'E-mail enviado'}</ModalHeader>
      <ModalBody>
        {!success ? (
          `Deseja alterar a senha do usuário ${user.name}?`
        ) : (
            <div className="text-center">
              Um e-mail foi mandado para <b>{user.email}</b>
              <div>Confira sua caixa de entrada!</div>
            </div>
          )}
      </ModalBody>
      <ModalFooter>
        {!success ? (
          <>
            <button className="btn border-danger" onClick={() => props.isHide()}>
              Cancelar
            </button>
            <button className="btn btn-info col-3" onClick={() => submit()}>
              Sim
            </button>
          </>
        ) : (
            <button className="btn btn-info col-3" onClick={() => props.isHide()}>
              Ok
            </button>
          )}
      </ModalFooter>
    </Modal>
  )
}

ModalAlterPassword.defaultProps = {
  user_email: String,
  isOpen: Boolean,
  isHide: Function,
}

export default ModalAlterPassword