import React, { useState, useCallback } from 'react'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Loading from '../../../components/loading'
import DeleteModal from '../../../components/delete-modal'
import { deleteClass } from '../../../actions/class'

const TableClasses = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [itemDelete, setItemDelete] = useState({ name: '' })
  const paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' }
  const columns = [
    {
      name: 'Turma',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      name: 'Curso',
      selector: 'course.name',
      sortable: true,
      grow: 2
    },
    {
      name: 'Ações',
      cell: (item) => (
        <div>
          <Link to="#">
            <i className="material-icons text-danger rtd_button" onClick={() => confirmDelete(item)}>delete_forever</i>
          </Link>
        </div>
      ),
      button: true,
    },
  ]

  const handleRowClicked = useCallback(reg => {
    props.history.push(`/classes/${reg.id}`)
  }, [props])

  const cancelDelete = useCallback(() => {
    setOpenModal(false)
    setItemDelete({ name: '' })
  }, [])

  const confirmDelete = useCallback((item) => {
    setOpenModal(true)
    setItemDelete({ id: item.id, name: item.name })
  }, [])

  const deleteItem = useCallback(() => {
    deleteClass(itemDelete.id).then(() => {
      props.loadClasses()
      setOpenModal(false)
      setItemDelete({ name: '' })
    })
  }, [props, itemDelete])

  return (
    < div className="row" >
      <div className="col">
        <DataTable
          columns={columns}
          data={props.data.data}
          progressPending={props.progressPending}
          pointerOnHover
          highlightOnHover
          noDataComponent="Nenhum registro encontrado"
          onRowClicked={handleRowClicked}
          progressComponent={<Loading />}
          pagination
          paginationServer
          paginationComponentOptions={paginationOptions}
          paginationPerPage={props.perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          paginationTotalRows={parseInt(props.data.total)}
          onChangeRowsPerPage={props.handlePerRowsChange}
          onChangePage={props.onChangePage}
        />
      </div>

      <DeleteModal
        isOpen={openModal}
        isHide={cancelDelete}
        itemDeleteName={`a turma ${itemDelete.name}`}
        onDeleteItem={deleteItem}
      />
    </div >
  )
}

TableClasses.defaultProps = {
  data: Array,
  progressPending: Boolean,
  paginationPerPage: Number,
  onChangeRowsPerPage: Function,
  onChangePage: Function,
  loadClasses: Function
}

export default withRouter(TableClasses)