import React, { Component } from 'react'
import { showArea, indexAreas, loadLayoutAreaDetail } from '../../../actions/area'
import Contact from '../../site/shared/Contact'
import BannerArea from './BannerArea'
import AreaCourses from './AreaCourses'

class AreaDetalhe extends Component {

  constructor(props) {
    super()

    this.state = {
      area: {},
      layout: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const id = this.props.match.params.id
    if (id) {
      showArea(id)
        .then(area => this.setState({ area }))
    } else {
      indexAreas()
        .then(areas => {
          let area = {}

          if (areas.length === 0) {
            area = {
              name: 'Você não possui áreas cadastradas!',
              description: '',
              courses: []
            }
            this.setState({ area })
          } else {
            area = areas[0]
            showArea(area.id)
              .then(area => this.setState({ area }))
          }
        })
    }

    loadLayoutAreaDetail()
      .then(layout => this.setState({ layout }))
  }

  render() {
    return (
      <div className="mb-5" style={{ marginTop: '55px' }}>
        <BannerArea area={this.state.area} layout={this.state.layout} />

        <AreaCourses area={this.state.area} layout={this.state.layout} />

        <Contact />
      </div>
    )
  }
}

export default AreaDetalhe
