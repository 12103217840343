import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import InputLabel from '../../components/Form/input/InputLabel'
import Input from '../../components/Form/input/Input'
import InputMaskLabel from '../../components/Form/input/InputMaskLabel'
import Label from '../../components/Form/input/Label'
import { Subdomain } from './styles'

function Register(props) {

  return (
    <>
      <div className="col-12 text-center">
        <h1>Faça uma avaliação grátis</h1>
      </div>
      <div className="col-12 text-center">
        <h2>Tenha acesso a plataforma em instantes</h2>
      </div>

      <div className="mt-5 p-1">
        <Form ref={props.formRef} onSubmit={props.submit} noValidate>
          <div>
            <InputLabel name="email" label="E-mail" />
          </div>

          <div className="mt-3">
            <InputLabel name="name" label="Nome / Razão Social" />
          </div>

          <div className="mt-3">
            <InputMaskLabel mask="(99) 99999-9999" name="phone" label="Telefone / Whatsapp" />
          </div>

          <div className="mt-3">
            <Label name="subdomain" label="URL do seu EAD" />
            <Subdomain>
              <span>http://</span>
              <Input name="subdomain" />
              <span>.hyberica.io</span>
            </Subdomain>
          </div>

          <div className="col-12 mt-3">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Declaro que li e aceito os <Link className="text-info" to="#">termos de uso</Link>
              </label>
            </div>
          </div>

          <div className="col-sm mt-4 text-center">
            <button type="submit" className="btn btn-lg btn-info p-2 col-6">
              Criar Conta
            </button>
          </div>
        </Form>
      </div>
    </>
  )
}

Register.defaultProps = {
  submit: Function,
  formRef: Function
}

export default Register