import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { config } from './config';
import { colors, backgroundColors } from './colors';
import { navigation } from './navigation';
import { breadcrumb } from './breadcrumb';
import { checkout } from './checkout';
// import { myCourse } from './myCourse';
import { user } from './user';

const rootReducer = combineReducers({
  config,
  colors,
  backgroundColors,
  navigation,
  breadcrumb,
  user,
  checkout,
  // myCourse,
  router: routerReducer,
});
export default rootReducer;
