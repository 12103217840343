import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { loadLastLesson, loadLessonCourse } from '../../actions/course';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { loadImage, loadVideoThumbnail } from '../../actions/resources';
import { loadPdfThumbnail, loadUrlThumbnail } from '../../actions/resources';
import {
  loadFileThumbnail,
  postProgress,
  deleteProgress,
} from '../../actions/resources';
import { formatTitle } from '../../actions/util';
import { toastError } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import Preview from './Preview';
import '../../css/tabs/tabs.css';

function LessonCourse(props) {
  const [lesson, setLesson] = useState({ resources: [] });
  const [selectedResource, setSelectedResource] = useState({});
  const [disabledDoubleClick, setDisabledDoubleClick] = useState(false);

  const setElementImage = useCallback((id, img) => {
    let imagem = document.getElementById(id);
    if (imagem) {
      imagem.src = img;
      imagem.classList.add('card-img-top');
    }
  }, []);

  const imageThumbnail = useCallback(
    async item => {
      loadImage(item.id).then(thumbnail => {
        setElementImage(item.id, thumbnail);
      });
    },
    [setElementImage]
  );

  const videoThumbnail = useCallback(
    async item => {
      if (item.url) {
        loadVideoThumbnail(item.id).then(thumbnail => {
          setElementImage(item.id, thumbnail);
        });
      }
    },
    [setElementImage]
  );

  const pdfThumbnail = useCallback(
    async item => {
      loadPdfThumbnail().then(thumbnail => {
        setElementImage(item.id, thumbnail);
      });
    },
    [setElementImage]
  );

  const urlThumbnail = useCallback(
    async item => {
      loadUrlThumbnail().then(thumbnail => {
        setElementImage(item.id, thumbnail);
      });
    },
    [setElementImage]
  );

  const fileThumbnail = useCallback(
    async item => {
      loadFileThumbnail().then(thumbnail => {
        setElementImage(item.id, thumbnail);
      });
    },
    [setElementImage]
  );

  const prepareImages = useCallback(resources => {
    for (let i = 0; i < resources.length; i++) {
      const item = resources[i];

      if (item.type === 'image') {
        imageThumbnail(item);
      } else if (item.type === 'video') {
        videoThumbnail(item);
      } else if (item.subtype === 'pdf') {
        pdfThumbnail(item);
      } else if (item.type === 'url' || item.type === 'youtube') {
        urlThumbnail(item);
      } else {
        fileThumbnail(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLesson = useCallback(
    (subscription_id, lesson_id, resource_id = null) => {
      loadLessonCourse(subscription_id, lesson_id).then(lesson => {
        setLesson(lesson);

        prepareImages(lesson.resources);

        if (resource_id) {
          const index = lesson.resources.findIndex(
            item => item.id === resource_id
          );
          setSelectedResource(lesson.resources[index]);
        } else {
          setSelectedResource(lesson.resources[0]);
        }
      });
    },
    [prepareImages]
  );

  const setCompleted = useCallback(
    item => {
      const subscription_id = props.match.params.id;
      const progress = {
        subscription_id: subscription_id,
        progress_time: item.duration,
        resource_id: item.id,
        completed: true,
      };
      postProgress(progress)
        .then(() => {
          setLesson({});
          getLesson(subscription_id, lesson.id, item.id);
        })
        .catch(error => toastError(handleError(error)));
    },
    [props.match.params.id, lesson, getLesson]
  );

  const resetCompleted = useCallback(
    item => {
      const subscription_id = props.match.params.id;
      deleteProgress(item.progress_id)
        .then(() => {
          setLesson({});
          getLesson(subscription_id, lesson.id, item.id);
        })
        .catch(error => toastError(handleError(error)));
    },
    [props.match.params.id, lesson, getLesson]
  );

  const changeCompleted = useCallback(
    item => {
      setDisabledDoubleClick(true);
      if (item.completed) {
        resetCompleted(item);
      } else {
        setCompleted(item);
      }
      props.reflesh();

      setTimeout(() => {
        setDisabledDoubleClick(false);
      }, 800);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [props, resetCompleted, setCompleted]
  );

  const getLastLesson = useCallback(
    async id => {
      loadLastLesson(id).then(lesson => {
        if (lesson) {
          setLesson(lesson);
          prepareImages(lesson.resources);
          setSelectedResource(lesson.resources[0]);
        }
      });
    },
    [prepareImages]
  );

  useEffect(() => {
    let isSubscribed = true;

    const subscription_id = props.match.params.id;

    const lesson_id = props.match.params.lesson_id;

    if (lesson_id && isSubscribed) {
      getLesson(subscription_id, lesson_id);
    } else {
      getLastLesson(props.match.params.id);
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className="row">
      {lesson.resources && lesson.resources.length > 0 && (
        <>
          <div className="col mt-5">
            <ResourceTitle className="row justify-content-end mr-2">
              <h5>{lesson.title}</h5>
            </ResourceTitle>
          </div>

          <ViewResource className="col-12">
            <div style={{ height: '1px' }} />
            <Preview
              resource={selectedResource || null}
              my_course_id={props.match.params.id}
            />

            {/* <Controller className="justify-content-center row mt-2">
              <div className="col-3 pt-3 pb-3 text-left prev-next d-flex align-items-center">
                <MdNavigateBefore size={30} color="#fff" />
                {lesson.prev && (
                  <Link className="d-none d-md-block"
                    to={`/my-courses/${props.match.params.id}/lessons/${lesson.prev.id}`}>
                    <span>{lesson.prev.title}</span>
                  </Link>
                )}
              </div>
              <button className="btn  btn-block status col-6 p-0 text-center  align-items-center"
                disabled={disabledDoubleClick}
                onClick={() => changeCompleted(selectedResource)}>
                {selectedResource.completed ? (
                  <span className="text-success"
                    data-tip=''
                    data-for="status"><FiCheckSquare size={25} />  Concluído
                  </span>
                ) : (
                  <span data-tip='' data-for="status">Marcar como concluído</span>
                )}

                {selectedResource.completed && (
                  <ReactTooltip id="status">Marcar como não realizado</ReactTooltip>
                )}
              </button>
              <div className="col-3 pt-3 pb-3 justify-content-end align-items-center prev-next d-flex">
                {lesson.next && (
                  <Link className="d-none d-md-block"
                    to={`/my-courses/${props.match.params.id}/lessons/${lesson.next.id}`}>
                    <span>{lesson.next.title}</span>
                  </Link>
                )}
                <MdNavigateNext size={30} color={`#fff`} />
              </div>
            </Controller> */}
          </ViewResource>

          <div className="col mt-2">
            <Controller className="row" completed={selectedResource.completed}>
              <div className="col-3 pt-3 pb-3 text-left prev-next d-flex align-items-center">
                <MdNavigateBefore size={30} color="#524F4F" />
                {lesson.prev && (
                  <Link
                    className="d-none d-md-block"
                    to={`/my-courses/${props.match.params.id}/lessons/${lesson.prev.id}`}
                  >
                    <span>{lesson.prev.title}</span>
                  </Link>
                )}
              </div>
              <button
                className="btn  btn-block status col-6 p-0 text-center  align-items-center"
                disabled={disabledDoubleClick}
                onClick={() => changeCompleted(selectedResource)}
              >
                {selectedResource.completed ? (
                  <span className="text-success" data-tip="" data-for="status">
                    <FiCheckSquare size={25} /> Concluído
                  </span>
                ) : (
                  <span data-tip="" data-for="status" className="text-white">
                    Marcar como concluído
                  </span>
                )}

                {selectedResource.completed && (
                  <ReactTooltip id="status">
                    Marcar como não realizado
                  </ReactTooltip>
                )}
              </button>
              <div className="col-3 pt-3 pb-3 justify-content-end align-items-center prev-next d-flex">
                {lesson.next && (
                  <Link
                    className="d-none d-md-block"
                    to={`/my-courses/${props.match.params.id}/lessons/${lesson.next.id}`}
                  >
                    <span>{lesson.next.title}</span>
                  </Link>
                )}
                <MdNavigateNext size={30} color={`#524F4F`} />
              </div>
            </Controller>
          </div>

          <div className="col-12 mt-5 mb-5">
            <h5>Recursos da aula</h5>

            <div className="row mt-3">
              {lesson.resources.map((item, i) => (
                <div className="col-6 col-md-2" key={i}>
                  <Card
                    className="card text-center mb-2 shadow"
                    onClick={() => setSelectedResource(item)}
                  >
                    {item.id && <PreviewThumbnail id={item.id} />}

                    <div className="card-body p-1">
                      <div
                        className="d-flex justify-content-center mt-3"
                        onClick={() => changeCompleted(item)}
                      >
                        {item.completed ? (
                          <FiCheckSquare className="text-success" size={18} />
                        ) : (
                          <FiSquare className="text-danger" size={18} />
                        )}

                        <p className="ml-2" data-tip="" data-for={item.id}>
                          {formatTitle(item.name, 14)}
                        </p>
                        <ReactTooltip id={item.id}>
                          {item.completed
                            ? 'Marcar como não realizado'
                            : 'Marcar como realizado'}
                        </ReactTooltip>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* {completedCourse && (
        < div className="col-12 text-center" style={{ marginTop: '150px' }}>
          <h1>Parabéns!</h1>

          <div className="col-12">
            <h2>Você conclui seus estudos.</h2>
          </div>
        </div>
      )} */}
    </div>
  );
}

LessonCourse.defaultProps = {
  reflesh: Function,
  firstLesson: Object,
};

export default withRouter(LessonCourse);

export const PreviewThumbnail = styled.img`
  height: 5rem;
`;

export const CheckBox = styled.label`
  cursor: pointer;
`;

export const Card = styled.div`
  max-height: 15rem;
  background-color: transparent !important;

  :hover {
    cursor: pointer;
    background-color: #f0f0f0 !important;
    color: #0288d1;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const ViewResource = styled.div`
  /* background-color: #EEEDED; */
  background-color: #000;
`;

export const ResourceTitle = styled.div`
  /* background-color: #524F4F; */
  /* background-color: #000; */
  padding-top: 5px;
  color: #524f4f;
  text-transform: uppercase;
`;

export const Controller = styled.div`
  background-color: #d3d3d3;

  span {
    color: #524f4f;
    font-size: 14px;
  }

  .status {
    background-color: ${props => (props.completed ? '#98FB98' : '#DC3545')};
    border-radius: 0;

    :hover {
      background-color: #524f4f;
      cursor: pointer;
    }
  }

  .prev-next:hover {
    background-color: gray;
    border-radius: 0;
    cursor: pointer;

    span {
      color: #fff;
    }

    svg {
      color: #fff !important;
    }
  }
`;
