const urls = [
  {
    title: 'SITE',
    items: [
      {
        url: '/',
        icon: 'dashboard',
        title: 'Site',
        items: []
      }
    ]
  },
  // {
  //   title: 'Ações',
  //   items: [
  //     {
  //       url: '#',
  //       icon: 'settings_applications',
  //       title: 'Administração',
  //       items: [
  //         {
  //           url: '/areas',
  //           icon: 'terrain',
  //           title: 'Áreas',
  //           items: []
  //         },
  //         {
  //           url: '/courses',
  //           icon: 'spa',
  //           title: 'Cursos',
  //           items: []
  //         },
  //         {
  //           url: '/classes',
  //           icon: 'spa',
  //           title: 'Turmas',
  //           items: []
  //         },
  //         {
  //           url: '/subscriptions',
  //           icon: 'spa',
  //           title: 'Matrículas',
  //           items: []
  //         },
  //       ]
  //     },
  //     {
  //       url: '#',
  //       icon: 'people',
  //       title: 'Pessoas',
  //       items: [
  //         {
  //           url: '/users',
  //           icon: 'supervisor_account',
  //           title: 'Usuários',
  //           items: []
  //         },
  //       ]
  //     },
  //   ]
  // },
]

export function navigation(state = Array.from(urls), action) {
  switch (action.type) {
    case 'SET_NAVIGATION':
      return Object.assign({}, state, {
        ...action.navigation
      })
    default:
      return state
  }
}

export function setNavigation(value) {
  const navigation = Array.from(value)
  return {
    type: 'SET_NAVIGATION',
    navigation
  }
}