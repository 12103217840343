import React, { useCallback, useState } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'

function DeleteCourse(props) {
  const [showModal, setShowModal] = useState(false)

  const deleteCourse = useCallback(async id => {
    await api.delete(`courses/${id}`)
      .then(() => {
        toastSuccess(`Curso excluido!`);
        setShowModal(false)
      }).catch(error => {
        toastError(handleError(error));
      })
  }, [])

  return (
    <>
      <Delete onClick={() => setShowModal(true)}>
        <MdDeleteForever className="text-danger" size={18} />
      </Delete>

      <Modal
        isOpen={showModal}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader >Deletar Curso</ModalHeader>
        <ModalBody>
          Deseja excluir o curso {props.name}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-info col-4" onClick={() => deleteCourse(props.id)}>
            Sim
          </button>
          <button className="btn border-danger" onClick={() => setShowModal(false)}>
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}
DeleteCourse.defaultProps = {
  id: String,
  name: String
}

export default DeleteCourse

export const Delete = styled.div`
  svg:hover {
    width: 20px !important;
    height: 20px !important;
    color: #ab2424 !important;
    cursor: pointer;
  }
`