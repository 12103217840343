import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../actions/api'
import { toastSuccess } from '../../../actions/toast'
import { Form } from '@unform/web'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'

import ReactTooltip from 'react-tooltip'
import { formatPrice, formatTimeString } from '../../../actions/util'

function BannerCourse(props) {
  const [layout, setLayout] = useState({})
  const [course, setCourse] = useState({ duration: { hours: 0 } })
  const [editing, setEditing] = useState(false)
  const formRef = useRef(null)

  const submit = useCallback(async data => {
    await api.post('layout-course-details', layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Banner atualizado!')
      })
  }, [layout])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.course) setCourse(props.course)
  }, [props])

  return (
    <>
      <Banner layout={layout} areaColor={course.area || { primary_color: '#005DFF' }}>
        <div className="text-card col-md-5 text-center p-3">

          {!editing ? (
            <>
              <h1>{course.name}</h1>
              <div className="col-12 row justify-content-between">
                <p>{course.duration ? formatTimeString(course.duration) : '00:00'} H</p>
                <p>|</p>
                <p>{formatPrice(course.value)}</p>
                <p>|</p>
                <span data-tip='' data-for='pre-requisitos'>Pré-requisitos </span>
                <ReactTooltip id='pre-requisitos' type="ligth" place="bottom">
                  <div>
                    <h5>Pré-requisitos</h5>
                    {course.requirements ? (
                      <h6>{course.requirements}</h6>
                    ) : (
                        <h6>Sem pré-requisitos para este curso!</h6>
                      )}
                  </div>
                </ReactTooltip>
              </div>
            </>
          ) : (
              <div className="attention">
                <h1>Atenção!!</h1>
                <p>Para alterar as informações sobre o curso,
                vá para a página de
                <Link to={`/courses`}> <b>Cursos.</b></Link>
                </p>
              </div>
            )}

          {!editing && (
            <div className="row no-editing">
              <div className="col">
                <button
                  className="btn btn-info mb-3"
                  onClick={() => setEditing(true)}>
                  Editar Layout Banner
              </button>
              </div>
            </div>
          )}

          {editing && (
            <div className="editing">
              <Form
                ref={formRef}
                initialData={layout}
                onSubmit={submit}
                noValidate>
                <div className="row col pr-0 pl-3">
                  <div className="col">
                    <InputColorLabel
                      name="banner_card_background_color"
                      label="Cor Fundo"
                      height="40px"
                      setColor={() => setLayout({ ...layout, banner_card_background_color: formRef.current.getFieldValue('banner_card_background_color') })}
                      defaultValue={layout.banner_card_background_color} />
                  </div>
                  <div className="col">
                    <InputColorLabel
                      name="banner_card_text_color"
                      label="Cor Fonte"
                      height="40px"
                      setColor={() => setLayout({ ...layout, banner_card_text_color: formRef.current.getFieldValue('banner_card_text_color') })}
                      defaultValue={layout.banner_card_text_color} />
                  </div>
                </div>

                <div className="col-12 text-center mb-3">
                  <button type="submit" className="btn btn-lg btn-info">
                    Atualizar
                  </button>
                  <button type="button"
                    className="btn btn-lg btn-warning m-2"
                    onClick={() => setEditing(false)}>
                    Cancelar
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Banner>
    </>
  )
}

BannerCourse.defaultProps = {
  course: Object,
  layout: Object
}

export default BannerCourse

export const Banner = styled.div`
  font-family: 'Oswald', sans-serif;
  height: 400px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* background: ${props => props.layout.banner_background_color}; */
  background: ${props => props.areaColor.primary_color};

  .text-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background: ${props => props.layout.banner_card_background_color};
    color: ${props => props.layout.banner_card_text_color};
    position: absolute;
    margin-left: auto;
    margin-right: auto;

    h1 { 
      font-size: 30px; 
      margin-top: 120px;
      font-weight: bold;
      text-transform: uppercase; 
    }
    p { 
      font-size: 26px; 
      font-weight: normal;
      margin-top: 30px;
    }
    span { 
      font-size: 26px; 
      font-weight: normal;
      margin-top: 30px;
      /* color: ${props => props.layout.banner_background_color} !important; */
      color: ${props => props.areaColor.primary_color} !important;
      :hover{
        cursor: pointer;
      }
    }
  }

  /* TOOLTIP adjustments */
  .type-ligth {
    background: ${props => props.layout.banner_card_background_color} !important;
    color: ${props => props.layout.card_text_color} !important;
    border: 2px solid ${props => props.layout.banner_card_background_color} !important;
    box-shadow: 0 0 1px #000000;
  }

  .no-editing {
    flex-direction: column;
    
    position: relative;
    margin-top: -20px;
  }
  
  .editing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    position: relative;
    margin-top: 30px;
  }

  .attention {
    h1 {
      margin-top: -10px;
    }
    p {
      font-size: 16px;
      margin-top: -5px;
      margin-bottom: 10px;
    }
  }
`
