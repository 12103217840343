import React from 'react'

function Logout(props) {
  localStorage.removeItem('api_key');
  props.history.push('/login')
  window.location.reload();

  return (<div />)
}

export default Logout