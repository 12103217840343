import React, { useState, useEffect } from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import InputColor from 'react-input-color'

import InputLabel from '../../../components/Form/input/InputLabel'

function FormArea(props) {
  const [primaryColor, setPrimaryColor] = useState('#0288d1')
  const [secondColor, setSecondColor] = useState('#EBEBEB')

  useEffect(() => {
    if (props.initialData.primary_color) {
      setPrimaryColor(props.initialData.primary_color);
      setSecondColor(props.initialData.second_color);
    }
  }, [props.initialData])

  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate>

      <InputLabel name="name" label="* Nome" />

      <InputLabel name="description" label="Descrição" />

      <div className="row">
        <div className="col-sm col-md-4 d-flex">
          <InputLabel name="primary_color" label="* Cor Primária" disabled />
          <div className="col-sm">
            <InputColor
              initialValue={primaryColor}
              onChange={e => props.formRef.current.setFieldValue('primary_color', e.hex)}
              placement="top"
              style={{ height: '43px', marginTop: '33px' }}
            />
          </div>
        </div>

        <div className="col-sm col-md-4 d-flex">
          <InputLabel name="second_color" label="* Cor Secundária" disabled />
          <div className="col-sm">
            <InputColor
              initialValue={secondColor}
              onChange={e => props.formRef.current.setFieldValue('second_color', e.hex)}
              placement="top"
              style={{ height: '43px', marginTop: '33px' }}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 d-flex justify-content-end">
        <div className={props.initialData.id ? 'col-sm col-md-3 pl-0' : 'col-sm col-md-2 pl-0'}>
          <Link to="/areas">
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
             </button>
          </Link>
        </div>

        <div className={props.initialData.id ? 'col-sm col-md-3 pr-0' : 'col-sm col-md-2 pr-0'}>
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}

FormArea.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object
}

export default FormArea