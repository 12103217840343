import React, { useState, useEffect, useCallback } from 'react'
// import { useDispatch } from 'react-redux'
import api from '../../actions/api'
import styled from 'styled-components'
import Widget from '../../components/widget'
import Course from './Course'

function Dashboardstudent(props) {
  const [courses, setCourses] = useState([])
  // const dispatch = useDispatch()

  const loadCourses = useCallback(async () => {
    await api.get('my-courses')
      .then(result => {
        setCourses(result.data)
        // dispatch({
        //   type: 'layout',
        //   items: 'top-navigation-1'
        // })
      })
  }, [])

  useEffect(() => {
    loadCourses()
  }, [loadCourses])

  return (
    <Widget title="Meus cursos" actions={[]} >
      <Container className="row">
        <div className="col-12 mb-3">
          <h2>BEM VINDO(A),</h2>
        </div>

        {
          courses.length === 0 && (
            <div className="col-12">
              <h5 className="text-center">Você não possui cursos!</h5>
            </div>
          )
        }

        {
          courses.map((course, i) => (
            <div key={i} className="col-md-4">
              <Course course={course} />
            </div>
          ))
        }
      </Container>
    </Widget >
  )
}


export default Dashboardstudent

export const Container = styled.div`
  padding: 0;

  h2 {
    font-weight: bold;
    font-size: 26px;
    color: #DC3545;
  }
`