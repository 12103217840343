import React, { useEffect, useState, useCallback } from 'react'
import { IoMdAdd } from 'react-icons/io'
import styled from 'styled-components'
import { AiOutlineEdit } from 'react-icons/ai'
// import { Collapse, Button, CardBody, Card } from 'reactstrap';


import SubtopicItem from './SubtopicItem'
import KeepTopic from './KeepTopic'
import KeepSubtopic from './KeepSubtopic'
import DeleteTopic from './DeleteTopic'
import LessonItem from '../lesson/LessonItem'
import NewLessonModal from '../lesson/NewLessonModal'

function TopicItem(props) {
  const [topic, setTopic] = useState({})
  const [addSubtopic, setAddSubtopic] = useState(false)
  const [addLesson, setAddLesson] = useState(false)
  const [selectTopic, setSelectTopic] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  // const [isOpen, setIsOpen] = useState(false)

  const handleCanRemove = useCallback(() => {
    if (topic.lessons && topic.lessons.length > 0) {
      setCanRemove(true)
    } else if (topic.topics && topic.topics.length > 0) {
      setCanRemove(true)
    } else {
      setCanRemove(false)
    }
  }, [topic])

  useEffect(() => {
    setTopic(props.topic)
    handleCanRemove()
  }, [props, topic, handleCanRemove])

  return (
    <>
      <tr className="mt-1">
        <td>
          <div className="row">
            {/* <button
              className="btn btn-sm border-info p-0"
              onClick={() => setIsOpen(!isOpen)}>
              <IoIosArrowForward />
            </button> */}
            <div className="col-sm col-md-6">
              <NameTopic className="d-flex" onClick={() => setSelectTopic(true)}>
                <div className="col-2">
                  {topic.number}
                </div>
                <div className="col-sm">
                  {topic.name}
                </div>
              </NameTopic>

              <KeepTopic
                course_id={props.course_id}
                topic={topic}
                showModal={selectTopic}
                hideModal={() => setSelectTopic(false)}
                updateCoursePage={() => props.updateCoursePage()}
              />
            </div>

            <div className="col-sm row justify-content-end">
              <>
                <button
                  className="btn btn-sm border-info m-1"
                  onClick={() => setAddSubtopic(true)}>
                  <IoMdAdd />  Subtópico
                </button>

                <KeepSubtopic
                  course_id={props.course_id}
                  topic_id={topic.id}
                  showModal={addSubtopic}
                  hideModal={() => setAddSubtopic(false)}
                  updateCoursePage={() => props.updateCoursePage()} />
              </>

              <>
                <button
                  className="btn btn-sm border-success m-1"
                  onClick={() => setAddLesson(true)}>
                  <IoMdAdd />  Aula
                </button>

                <NewLessonModal
                  data={topic}
                  showModal={addLesson}
                  hideModal={() => setAddLesson(false)}
                  updateCoursePage={() => props.updateCoursePage()} />
              </>

              <button
                className="btn btn-sm border-primary m-1"
                onClick={() => setSelectTopic(true)}>
                <AiOutlineEdit />
              </button>

              <div>
                <DeleteTopic
                  id={topic.id}
                  name={topic.name}
                  updateCoursePage={() => props.updateCoursePage()}
                  disabled={canRemove} />
              </div>
            </div>
          </div>
        </td>
      </tr>

      {
        topic.lessons &&
        topic.lessons.map((lesson, i) => (
          // <Collapse className="col-sm" key={i} isOpen={isOpen}>
          <LessonItem
            key={i}
            lesson={lesson}
            course_id={props.course_id}
            updateCoursePage={() => props.updateCoursePage()}
          />
          // </Collapse>
        ))
      }

      {
        topic.topics &&
        topic.topics.map((subtopic, i) => (
          <SubtopicItem
            key={i}
            subtopic={subtopic}
            course_id={props.course_id}
            updateCoursePage={() => props.updateCoursePage()} />
        ))
      }
    </>
  )
}
TopicItem.defaultProps = {
  topic: Object,
  updateCoursePage: Function
}

export default TopicItem

export const NameTopic = styled.div`
  font-weight: bold;

  :hover {
    cursor: pointer;
    color: #0288d1;
  }
`