import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { isAuthenticated } from './actions/auth';

// pages
import Login from './pages/login';
import Logout from './pages/logout';
import Forgot from './pages/forgot';
import ForgotSuccess from './pages/forgot';
import ResetPassword from './pages/reset-password';
import ResetSuccess from './pages/reset-password';

import Forbiden from './pages/forbiden';
import Error from './pages/error';
import PageError from './pages/error-page';
import FreeTrial from './pages/free-trial';

//site
import Home from './pages/site/home';
import AreaDetail from './pages/site/area-detail';
import CourseDetail from './pages/site/course-detail';
import Faqs from './pages/site/faqs';

// admin
import HomeAdmin from './pages/site-admin/home';
import AreaDetailAdmin from './pages/site-admin/area-detail';
import CourseDetailAdmin from './pages/site-admin/course-detail';

// dashbord
import Dashboad from './pages/dashboard';

// areas
import ListArea from './pages/area/list';
import FormArea from './pages/area/form';

// courses
import ListCourse from './pages/course/list';
import FormCourse from './pages/course/form';

// my-course
import MyCourse from './pages/my-course';

// classes
import ListClass from './pages/class/list';
import FormClass from './pages/class/form';

// subscriptions
import ListSubscription from './pages/subscription/list';
import NewSubscription from './pages/subscription/form/new';
import EditSubscription from './pages/subscription/form/edit';

// lessons
import FormLesson from './pages/lesson/form';

// users
import ListUser from './pages/user/list';
import FormNewUser from './pages/user/form/new';
import FormEditUser from './pages/user/form/edit';
import FormUserProfile from './pages/user-profile/form';

// contacts
import ListTenantContacts from './pages/tenant-contacts/list';
import FormTenantContact from './pages/tenant-contacts/form';

// tenant informations
import FormTenantInformations from './pages/tenant-informations/form';

// faqs
import ListFaqs from './pages/faq/list';
import FormFaq from './pages/faq/form';

// cart
import Cart from './pages/cart';

// checkout
import Checkout from './pages/checkout';

// purchases
import ListPurchase from './pages/purchase/list';
import FormPurchase from './pages/purchase/form';

import { CampanhaEscola } from './pages/campanha-escola/CampanhaEscola';
import { MatricularAlunoSite } from './pages/matricula-aluno-site/MatricularAlunoSite';

import TestVideo from './pages/test-video';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/home" component={Home} />
    <Route exact path="/campanha-escola" component={CampanhaEscola} />
    <Route path="/login" component={Login} />
    <Route path="/logout" component={Logout} />
    <Route path="/forbiden" component={Forbiden} />
    <Route path="/error" component={Error} />
    <Route exact path="/forgot" component={Forgot} />
    <Route exact path="/forgot-success" component={ForgotSuccess} />
    <Route path="/reset" component={ResetPassword} />
    <Route path="/reset-success" component={ResetSuccess} />
    <Route path="/free-trial" component={FreeTrial} />
    <Route path="/test-video" component={TestVideo} />
    <Route path="/area-detail/:id" component={AreaDetail} />
    <Route path="/course-detail/:id" component={CourseDetail} />
    <Route exact path="/faqs" component={Faqs} />
    <Route path="/matricular-aluno/:pedido" component={MatricularAlunoSite} />

    <PrivateRoute exact path="/users" component={ListUser} />
    <PrivateRoute path="/users/new" component={FormNewUser} />
    <PrivateRoute path="/users/:id" component={FormEditUser} />
    <PrivateRoute path="/profile/:id" component={FormUserProfile} />

    <PrivateRoute exact path="/dashboard" component={Dashboad} />

    <PrivateRoute exact path="/areas" component={ListArea} />
    <PrivateRoute path="/areas/new" component={FormArea} />
    <PrivateRoute exact path="/areas/:id" component={FormArea} />

    <PrivateRoute exact path="/courses" component={ListCourse} />
    <PrivateRoute path="/areas/:area_id/courses/new" component={FormCourse} />
    <PrivateRoute path="/courses/new" component={FormCourse} />
    <PrivateRoute exact path="/courses/:id" component={FormCourse} />

    <PrivateRoute exact path="/my-courses/:id" component={MyCourse} />
    <PrivateRoute
      exact
      path="/my-courses/:id/lessons/:lesson_id"
      component={MyCourse}
    />

    <PrivateRoute exact path="/classes" component={ListClass} />
    <PrivateRoute exact path="/classes/new" component={FormClass} />
    <PrivateRoute exact path="/classes/:id" component={FormClass} />

    <PrivateRoute exact path="/subscriptions" component={ListSubscription} />
    <PrivateRoute exact path="/subscriptions/new" component={NewSubscription} />
    <PrivateRoute
      exact
      path="/subscriptions/:id"
      component={EditSubscription}
    />

    <PrivateRoute
      exact
      path="/courses/:course_id/lessons/:id"
      component={FormLesson}
    />

    <PrivateRoute
      exact
      path="/tenant-contacts"
      component={ListTenantContacts}
    />
    <PrivateRoute
      exact
      path="/tenant-contacts/new"
      component={FormTenantContact}
    />
    <PrivateRoute
      exact
      path="/tenant-contacts/:id"
      component={FormTenantContact}
    />

    <PrivateRoute
      exact
      path="/tenant-informations"
      component={FormTenantInformations}
    />

    <PrivateRoute exact path="/site-admin/home" component={HomeAdmin} />
    <PrivateRoute
      exact
      path="/site-admin/area-detail"
      component={AreaDetailAdmin}
    />
    <PrivateRoute
      exact
      path="/site-admin/area-detail/:id"
      component={AreaDetailAdmin}
    />
    <PrivateRoute
      exact
      path="/site-admin/course-detail"
      component={CourseDetailAdmin}
    />
    <PrivateRoute
      exact
      path="/site-admin/course-detail/:id"
      component={CourseDetailAdmin}
    />

    <PrivateRoute exact path="/faqs-adm" component={ListFaqs} />
    <PrivateRoute exact path="/faqs-adm/new" component={FormFaq} />
    <PrivateRoute exact path="/faqs-adm/:id" component={FormFaq} />

    <PrivateRoute exact path="/cart" component={Cart} />
    <PrivateRoute exact path="/checkout" component={Checkout} />

    <PrivateRoute exact path="/purchases" component={ListPurchase} />
    <PrivateRoute exact path="/purchases/:id" component={FormPurchase} />

    <PrivateRoute path="*" component={PageError} />
  </Switch>
);

export default Routes;
