import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import FormFaq from './FormFaq'
import { showFaq, saveFaq, updateFaq } from '../../../actions/faq'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

function Faq(props) {
  const formRef = useRef(null)
  const [faq, setFaq] = useState({})
  const dispatch = useDispatch()

  const getFaq = useCallback(async id => {
    showFaq(id).then(faq => {
      setFaq(faq)
      dispatch(setBreadcrumb([
        { page: 'FAQs', url: '#' },
        { page: 'Editar', url: '#' },
      ]))
    })
  }, [dispatch])

  const save = useCallback(async (data) => {
    saveFaq(data).then(faq => {
      setFaq(faq)
    })
  }, [])

  const update = useCallback(async (data) => {
    data.id = props.match.params.id
    updateFaq(data).then(faq => {
      setFaq(faq)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        question: Yup.string().min(10, 'Pergunta mínimo 10 caracteres'),
        answer: Yup.string().min(3, 'Resposta mínimo 3 caracteres'),
      })

      await schema.validate(data, { abortEarly: false })

      faq.id ? update(data) : save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [faq, save, update])

  useEffect(() => {
    const id = props.match.params.id
    if (id && Object.entries(faq).length === 0) {
      getFaq(id)
    } else {
      dispatch(setBreadcrumb([
        { page: 'FAQs', url: '#' },
        { page: 'Novo', url: '#' },
      ]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <Widget title={faq.id ? `Editar Pergunta` : 'Nova FAQ'} >
      <FormFaq
        initialData={faq}
        formRef={formRef}
        submit={submit}
      />
    </Widget >
  )
}

export default Faq;