import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import InputLabel from '../../components/Form/input/InputLabel';
import InputMaskLabel from '../../components/Form/input/InputMaskLabel';
import InputDateLabel from '../../components/Form/input/InputDateLabel';
import SelectLabel from '../../components/Form/input/SelectLabel';
import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import axios from 'axios';

import {
  CampanhaEscolaContainer,
  Banner,
  Cadastro,
  Footer,
} from './CampanhaEscolaStyle';
import { dateToStringFilter } from '../../actions/util';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export function CampanhaEscola() {
  const formRef = useRef(null);

  const [registered, setRegistered] = useState(false);

  const [cidadeSelecionada, setCidadeSelecionada] = useState({});

  const validaIdade = useCallback(() => {
    var data = new Date();

    data.setDate(data.getDate() - 3287);

    return data;
  }, []);

  const optionsCity = [
    { label: 'Água Doce', value: 'Água Doce' },
    { label: 'Caçador', value: 'Caçador' },
    { label: 'Campos Novos', value: 'Campos Novos' },
    { label: 'Fraiburgo', value: 'Fraiburgo' },
    { label: 'Joaçaba', value: 'Joaçaba' },
    { label: 'Videira', value: 'Videira' },
  ];

  const searchCep = useCallback(
    async value => {
      const cep = value.replace('_', '');

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (data.cep) {
        formRef.current.setFieldValue('address', data.logradouro);
      }
    },
    [formRef]
  );

  const salvar = useCallback(async data => {
    data.birth_date = dateToStringFilter(data.birth_date);

    await api
      .post('sign-up', data)
      .then(() => {
        toastSuccess('Cadastro realizado com sucesso!');
        setRegistered(true);
      })
      .catch(erro => {
        toastError(handleError(erro));
      });
  }, []);

  const submit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        data.city = cidadeSelecionada.value;
        data.state = 'SC';

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail é obrigatório'),
          birth_date: Yup.date()
            .nullable()
            .required('Data Nasc. é obrigatório')
            .max(validaIdade(), 'Idade minima 9 anos'),
          phone_number: Yup.string().required('WhatsApp é obrigatório'),
          document: Yup.string(),
          zipcode: Yup.string().required('CEP é obrigatório'),
          address: Yup.string().required('Rua é obrigatório'),
          street_number: Yup.string().required('Nº é obrigatório'),
          street_complement: Yup.string(),
          city: Yup.string().required('Cidade é obrigatório'),
          state: Yup.string(),

          password: Yup.string()
            .min(8, 'Senha mínimo 8 caracteres')
            .required('Senha é obrigatório'),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas não conferem'
          ),
        });

        await schema.validate(data, { abortEarly: false });

        salvar(data);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [cidadeSelecionada, validaIdade, salvar]
  );

  return (
    <CampanhaEscolaContainer>
      <Banner className="row align-items-center m-0">
        <div className="col text-center" style={{ marginTop: '300px' }}>
          <h1>Seja bem-vindo</h1>
          <h1>ao EAD Cetesc</h1>
        </div>
      </Banner>

      {!registered && (
        <Cadastro className=" m-0">
          <div className="row mx-1">
            <div className="col-12 text-center mb-5">
              <h1>Inscreva-se</h1>

              <p>Faça seu cadastro e aproveite nossos cursos gratuitos!</p>
            </div>
          </div>

          <div className="row m-1 justify-content-center">
            <div className="col-sm col-md-10 col-lg-6">
              <Form ref={formRef} onSubmit={submit} noValidate>
                <div className="row">
                  <div className="col-sm">
                    <InputLabel name="name" label="Nome" />
                  </div>

                  <div className="col-sm col-md-3">
                    <InputDateLabel name="birth_date" label="Data Nasc." />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm col-md-7">
                    <InputLabel name="email" label="E-mail" />
                  </div>

                  <div className="col-sm">
                    <InputMaskLabel
                      mask="(99) 99999-9999"
                      name="phone_number"
                      label="WhatsApp"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <SelectLabel
                      options={optionsCity}
                      name="city"
                      label="Cidade"
                      onChange={e => setCidadeSelecionada(e)}
                      placeholder=""
                    />
                  </div>

                  <div className="col-sm">
                    <InputMaskLabel
                      mask="99999-999"
                      name="zipcode"
                      label="CEP"
                      onBlur={e => searchCep(e.target.value)}
                    />
                  </div>
                  {/* <div className="col col-md-3">
                    <InputMaskLabel
                      mask="999.999.999-99"
                      name="document"
                      label="CPF"
                    />
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-sm">
                    <InputLabel name="address" label="Rua" />
                  </div>

                  <div className="col-sm col-md-2">
                    <InputLabel name="street_number" label="Nº" />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <InputLabel name="street_complement" label="Complemento" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <InputLabel name="password" label="Senha" type="password" />
                  </div>

                  <div className="col-sm">
                    <InputLabel
                      name="confirm_password"
                      label="Confirmar senha"
                      type="password"
                    />
                  </div>
                </div>

                <div className="row mt-3 justify-content-center">
                  <div className="col-sm col-md-6">
                    <button className="btn btn-primary btn-block">
                      Salvar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Cadastro>
      )}

      {registered && (
        <Cadastro className=" m-0">
          <div className="row mx-1">
            <div className="col text-center">
              <h1>Parabéns!</h1>

              <p>Seu cadastro realizado com sucesso!</p>

              <p>
                Agora você já pode acessar nossa plataforma e ter acesso a
                diversos cursos.
              </p>

              <p>Seja um profissional de destaque e alcance o sucesso!</p>

              <div className="row justify-content-center">
                <div className="col-sm col-md-2">
                  <Link to="/login" className="btn btn-primary btn-block">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Cadastro>
      )}

      <Footer className="col-12">
        <div className="col text-center">
          <img src="/assets/images/logo_vermelho.svg" alt="Logo Cetesc" />
          <div>
            <span>Direitos reservados | Hyberica Softwares</span>
          </div>
        </div>
      </Footer>
    </CampanhaEscolaContainer>
  );
}
