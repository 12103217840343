import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import api from '../../../actions/api'

function BannerTop(props) {
  const [layout, setLayout] = useState({})

  useEffect(() => {
    let isSubscribed = true;

    api.get('layout-banners')
      .then(result => {
        if (isSubscribed) setLayout(result.data)
      })
      .catch(() => { })

    return () => (isSubscribed = false)
  }, [])

  return (
    <Container className="row text-center align-items-center" layout={layout}>
      <div className="col-12">
        <h1>{layout.title}</h1>
        <h5>{layout.description}</h5>
      </div>
    </Container>
  )
}

BannerTop.defaultProps = {
  initialData: Object
}

export default BannerTop

export const Container = styled.div`
  height: 400px;
  font-family: 'Oswald', sans-serif;
  margin-top: -15px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
  background-color: ${props => props.layout.background_color};
  margin: 0;
  
  h1{
    color: ${props => props.layout.title_font_color};
    font-size: 36px;
    font-weight: bold;
    /* margin-top: 5%; */
  }
  
  h5{
    color: ${props => props.layout.description_font_color};
    font-size: 22px;
    margin-top: 20px;
  }

  @media (max-width: 668px) {
    height: 300px;
    /* h1{ margin-top: 15%; }
    h5{ margin-top: -50px; } */
  }
`