import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IoMdHome } from 'react-icons/io';

import './breadcrumbs.css'

function Breadcrumb({ items, layout }) {
  return (
    <>
      {(layout === 'fullScreen-layout' || layout === 'error-layout') ?
        null :
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb row">
            {items.map((item, i) => (
              <li className="breadcrumb-item" key={i}>
                <Link to={item.url}>
                  {item.page === 'Home' ? <IoMdHome size={18} /> : item.page}
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      }
    </>
  );
}

export default compose(
  connect(state => {
    return {
      items: state.breadcrumb,
      layout: state.config.layout,
    }
  }),
  withRouter)(Breadcrumb);
