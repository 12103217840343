import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FcGraduationCap, FcTimeline, FcPodiumWithSpeaker, FcTodoList } from 'react-icons/fc'
import Widget from '../../components/widget'

class DashboardAdmin extends Component {
  constructor() {
    super();

    this.state = {}
  }

  componentDidMount() {

  }

  render() {
    return (
      <Widget title="EAD" actions={[]}>
        <div className="row text-center">
          <div className="col-6 col-md-3 mb-5">
            <Link to="/areas">
              <Card className="card shadow-sm">
                <FcTimeline size={50} />
                  Áreas
              </Card>
            </Link>
          </div>

          <div className="col-6 col-md-3 mb-5">
            <Link to="/courses">
              <Card className="card shadow-sm">
                <FcGraduationCap size={50} />
                  Cursos
              </Card>
            </Link>
          </div>

          <div className="col-6 col-md-3 mb-5">
            <Link to="/classes">
              <Card className="card shadow-sm">
                <FcPodiumWithSpeaker size={50} />
                  Turmas
              </Card>
            </Link>
          </div>

          <div className="col-6 col-md-3 mb-5">
            <Link to="/subscriptions">
              <Card className="card shadow-sm">
                <FcTodoList size={50} />
                  Matrículas
              </Card>
            </Link>
          </div>
        </div>
      </Widget >
    )
  }
}

export default DashboardAdmin

export const Card = styled.div`
  padding: 20px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #4e4e4e;
  font-weight: 600;

  :hover{
    color: #fff;
    box-shadow: 1px 1px 5px 2px #9999 !important;
    background: #DC3545;
  }
  
  svg {
    margin-bottom: 20px;
  }

  @media (max-width: 576px) {
    height: 200px;
  }
`