import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './logo.css';
import { TenantContext } from '../../contexts/TenantContext';
import styled from 'styled-components';

const Logo = () => {
  const { tenant } = useContext(TenantContext);

  return (
    <Container tenant={tenant}>
      <Link to="/" className="row align-items-center flex-nowrap">
        <img src={tenant?.logo_url} alt="logo cetesc" className="img-fluid" />
      </Link>
    </Container>
  );
};

export default Logo;

export const Container = styled.div`
  width: 280px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.tenant.color_nav};

  a {
    /text-decoration: none;
  }

  img {
    width: ${props => (props.tenant.name === 'gosc' ? '220px' : '200px')};
  }
`;
