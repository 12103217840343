
export const isAuthenticated = () => localStorage.getItem('api_key') !== null

export const login = (token) => {
  localStorage.setItem('api_key', token)
}

export const logoff = () => {
  localStorage.removeItem('api_key')
  window.location.reload()
}

export const getToken = () => localStorage.getItem('api_key')

