import { format, parseISO, addHours } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import axios from 'axios'

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

export const { format: formatSimplePrice } = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 })

export const { format: formatWeight } = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 })

export const wheigthToFloat = wheigth => {
  return wheigth.replace('.', '').replace(',', '.')
}

export const formatTitle = (title, index) => {
  return title.length <= index ? title : title.substr(0, index) + '...'
}

export const currencyToString = currency => {
  let text = currency.toString()
  if (text.includes('R$')) {
    text = text.replace('R$', '')
    text = text.replace('.', '')
    text = text.replace(',', '.')
  }

  return parseFloat(text)
}

export const dateToString = date => {
  const parsedDate = parseISO(date)
  const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo')
  const addedDate = addHours(znDate, 1)
  const stringDate = format(new Date(addedDate), 'dd/MM/yyyy')

  return stringDate
}

export const dateToStringFilter = date => {
  const timeZone = 'America/Sao_Paulo'
  return format(new Date(date), 'yyyy-MM-dd', { timeZone })
}

export const stringToDate = string => {
  const parsedDate = parseISO(string)
  const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo')

  const addedDate = addHours(znDate, 1)
  const date = new Date(addedDate)

  return date
}

export const numberToTime = number => {
  const stringNumber = number.toString()
  let time = ''
  let hora = ''
  let minutos = ''

  if (stringNumber.includes('.')) {
    minutos = stringNumber.slice(stringNumber.indexOf('.')).replace('.', '')
    minutos = minutos.length < 2 ? `${minutos}0` : minutos
    hora = stringNumber.slice('.', 2).replace('.', '')
    hora = hora.length < 2 ? `0${hora}` : hora
  } else {
    minutos = '00'
    hora = stringNumber.length < 2 ? `0${stringNumber}` : stringNumber
  }

  time = `${hora}:${minutos}`

  return time
}

export const timeToNumber = time => {
  let number = ''
  const hora = parseInt(time)
  const minutos = time.slice(time.indexOf(':')).replace(':', '')

  number = `${hora}.${minutos}`

  return number
}

export const timeToFloat = timeHHmm => {
  const fields = timeHHmm.split(':')
  const hours = Number(fields[0])
  const mins = Number(fields[1])

  return Number(hours + (mins / 60))
}

export const floatToTime = timeFloat => {
  const fields = timeFloat.toString().split('.')
  const hours = fields[0]
  const mins = fields.length > 1 ? Number(Number('0.' + fields[1]) * 60).toFixed(0) : 0
  const zeros = hours.length === 1 ? '00' : hours.length === 2 ? '0' : ''

  return `${zeros}${hours}:${mins > 9 ? '' : '0'}${mins}`
}

export const stringToTime = time => {
  const fields = time.toString().split(':')
  const hours = fields[0].length === 3 ? fields[0] : `0${fields[0]}`
  const mins = fields[1]
  const sec = fields[2]

  return `${hours}:${mins}${sec}`
}

export const formatTimeString = time => {
  const fields = time.toString().split(':')
  const hours = fields[0]
  const mins = fields[1]

  return `${hours}:${mins}`
}

export const floatToCurrency = number => {
  let value = number.toLocaleString('pt-BR')
  if (value.indexOf(',') === -1) value = `${value},00`
  return `R$ ${value}`
}

export const formatUnity = unity => {
  if (unity === 'CX') return 'Caixa'
  if (unity === 'M') return 'Metro'
  if (unity === 'M2') return 'Metro Quadrado'
  if (unity === 'M3') return 'Metro Cúbico'
  if (unity === 'L') return 'Litro'
  if (unity === 'ML') return 'Mililitro'
  if (unity === 'KG') return 'Quilograma'
  if (unity === 'G') return 'Grama'
  if (unity === 'UN') return 'Unidade'
  if (unity === 'MIL') return 'Milheiro'
  if (unity === 'SAC') return 'Saco'
}

export const formatType = type => {
  if (type === 'FERT') return 'Fertilizante'
  if (type === 'FITO') return 'Fitossanitário'
  if (type === 'OUTRO') return 'Outro'
}

export const formatDateToDashboard = data => {

  let year_month = ''
  let year = ''
  let month = ''
  let month_format = ''

  if (data.includes('-')) {
    year_month = data.split('-')
    year = year_month[0]
    month = year_month[1]
  } else {
    return data
  }

  if (month === '01') month_format = 'Jan'
  if (month === '02') month_format = 'Fev'
  if (month === '03') month_format = 'Mar'
  if (month === '04') month_format = 'Abr'
  if (month === '05') month_format = 'Mai'
  if (month === '06') month_format = 'Jun'
  if (month === '07') month_format = 'Jul'
  if (month === '08') month_format = 'Ago'
  if (month === '09') month_format = 'Set'
  if (month === '10') month_format = 'Out'
  if (month === '11') month_format = 'Nov'
  if (month === '12') month_format = 'Dez'

  return `${month_format}-${year}`
}

export const getStates = () => {
  return [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espirito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ]
}

export const cepConsult = cep => {
  cep = cep.replace('-', '')

  const response = new Promise((resolve, reject) => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
      })
  })
  return response
}