import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const saveCourse = data => {
  const result = new Promise((resolve, reject) => {
    api.post(`courses`, data)
      .then(result => {
        resolve(result.data)
        toastSuccess('Novo curso incluído!');
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const updateCourse = data => {
  const result = new Promise((resolve, reject) => {
    api.put(`courses/${data.id}`, data)
      .then(result => {
        resolve(result.data)
        toastSuccess('Curso atualizado!');
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadMyCourse = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`my-courses/${id}`)
      .then(result => {
        resolve(result.data.course)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result

}

export const loadLastLesson = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`my-courses/${id}/last-lesson`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadLessonCourse = (subscription_id, lesson_id) => {
  const result = new Promise((resolve, reject) => {
    api.get(`my-courses/${subscription_id}/${lesson_id}/lesson-with-prev-next`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadImageCourse = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`course-image-files/${id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const buffer = Buffer.from(result.data, 'base64');
        const blob = new Blob([buffer]);
        const imageUrl = URL.createObjectURL(blob);
        resolve(imageUrl)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return result
}

export const showCourse = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`courses/${id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const indexCourses = params => {
  const result = new Promise((resolve, reject) => {
    api.get(`courses`, { params: params })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadLayoutCourseDetail = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`layout-course-details`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const deleteCourse = id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`courses/${id}`)
      .then(() => {
        toastSuccess('Curso removida com sucesso!');
        resolve()
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

// export function setMyCourse(key, value) {
//   let user = {
//     [key]: value
//   }
//   return {
//     type: 'SET_MYCOURSE',
//     user
//   }
// }