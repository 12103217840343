import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import Widget from '../../../../components/widget'
import FormEditUser from './FormEditUser'
import CropThumbnail from './CropThumbnail'
import { showThumbnail, show, update } from '../../../../actions/user'
import { Container, Roles } from '../styles'

function EditUser(props) {
  const formRef = useRef(null)
  const [preview, setPreview] = useState('')
  const [user, setUser] = useState({})
  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])
  const [isUpdateThumbnail, setIsUpdateThumbnail] = useState(false)
  const dispatch = useDispatch()
  const userLogged = useSelector(state => state.user)

  const updateThumbnail = useCallback(user => {
    if (user.thumbnail) {
      showThumbnail(user.id)
        .then(result => setPreview(result))
    } else {
      setPreview('/assets/images/profile.jpeg')
    }
  }, [])

  const setBreadcrumb = useCallback(user => {
    dispatch({
      type: 'SET_BREADCRUMB',
      items: [
        { page: 'Usuários', url: '/users' },
        { page: user.name ? user.name.substr(0, 10) + '...' : '', url: '#' },
        { page: 'Editar', url: '#' },
      ]
    })
  }, [dispatch])

  const showUser = useCallback(id => {
    show(id)
      .then(response => {
        setUser(response.user)
        setRoles(response.roles)
        setPermissions(response.permissions)
        updateThumbnail(response.user)
        setBreadcrumb(response.user)
      })
      .catch(() => props.history.push('/users'))
  }, [props.history, setBreadcrumb, updateThumbnail])

  const updateUser = useCallback(async (data) => {
    data.id = user.id
    update(data).then(user => {
      setUser({})
      // props.setUser('user', user) //update thumbail in profile
      setUser(user)
    })
  }, [user])

  const submit = useCallback(async (data) => {
    data.email = data.email.toLowerCase()

    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        username: Yup.string().min(3, 'Usuário mínimo 3 caracteres'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
        // role: Yup.string().required('Perfil é obrigatório'),
        role: Yup.lazy(value => {
          return user.id ? Yup.string() : Yup.string().required('Perfil é obrigatório')
        }),
      })

      await schema.validate(data, { abortEarly: false })

      if (!user.id) data.roles = [data.role]

      updateUser(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [user, updateUser])


  useEffect(() => {
    window.scrollTo(0, 0)
    let isSubscribed = true
    const id = props.match.params.id

    if (id && isSubscribed) {
      showUser(id)
    }
    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Widget title={user.id ? `Editar ${user.name}` : 'Novo Usuário'} >
      <div className="row justify-content-center">

        <div className="col-sm col-md-8 row p-0">
          <div className="col-sm col-md-8">
            <FormEditUser
              user={user}
              roles={roles}
              permissions={permissions}
              formRef={formRef}
              submit={submit}
            />
          </div>
          <Container className="mt-4 text-center col-sm">
            <img
              className="img-fluid image mt-2 img-thumbnail"
              src={preview} alt="preview" />

            <div className="row justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-sm btn-info col-8"
                onClick={() => {
                  setIsUpdateThumbnail(true)
                  window.location.href = '#crop-image'
                }}>
                Alterar imagem
            </button>
            </div>
          </Container>

          {userLogged.roles[0] === 'administration' && (
            <Roles className="row">
              <div className="col-12 mt-3">
                <p>perfil</p>
                {roles.map(role => (
                  <span key={role}>{role}</span>
                ))}
              </div>

              <div className="col-12 mt-3">
                <p>Permissões</p>

                <div className="row justify-content-between">
                  {permissions.map(permision => (
                    <div key={permision} className="col-sm-6 col-md-4">
                      <span>{permision}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Roles>
          )}
        </div>
      </div>

      <div className="row mt-3 mb-5">
        <div className="col-sm">
          <CropThumbnail
            show={isUpdateThumbnail}
            hide={() => setIsUpdateThumbnail(false)}
            user_id={user.id}
            reloadShowUser={() => showUser(user.id)}
            thumbnail={preview} />
        </div>
      </div>
    </Widget >
  )
}


export default EditUser