import React, { useRef, useCallback, useState, useEffect } from 'react'
import * as Yup from 'yup'
import api from '../../actions/api'
import { toastError } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import Register from './Register'
import Success from './Success'
import { Container } from './styles'

function Index() {
  const formRef = useRef(null)

  const [isRegistered, setIsRegistered] = useState(false)

  const save = useCallback(async (tenant) => {
    await api.post('tenants', tenant)
      .then(result => {
        setIsRegistered(true)
      }).catch(error => {
        toastError(handleError(error))
      })
  }, [])

  const submit = useCallback(async (data) => {
    data.type = 'JURIDICA'
    data.subdomain = data.subdomain.toLowerCase()

    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        phone: Yup.string().required('Telefone é obrigatório'),
        subdomain: Yup.string().min(3, 'Mín 3 caracteres')
      })

      await schema.validate(data, { abortEarly: false })

      save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [save])

  useEffect(() => {
    // action on update of isRegistered 
  }, [isRegistered]);

  return (
    <Container className="container col-md-3 mb-5">
      {!isRegistered ?
        <Register formRef={formRef} submit={submit} /> :
        <Success />
      }
    </Container >
  )
}

export default Index