import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './widget.css'

const Widget = ({ title, actions = [], line = true, children }) => {
  // window.scrollTo(0, 0)

  return (
    <div className="widget">
      <div className="row mb-0">
        <div className="col text-right">
          <div className="title">{title.length > 25 ? `${title.substring(0, 25)}...` : title}</div>
        </div>
      </div>

      <div className="row col p-0 m-0">
        {actions.map(action => (
          <div key={action.name} className={`p-0 mb-3 ${action.class_collumn}`}>
            <Link to={action.route}>
              <button className={`btn ${action.class_btn} btn-lg btn-block text-white`}>
                <span>{action.name}</span>
              </button>
            </Link>
          </div>
        ))
        }
      </div>

      {
        line &&
        <hr className="line mt-0" />
      }

      <div className="row">
        <div className="col">{children}</div>
      </div>
    </div >
  )
}

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string,
  route: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string
  ])
}

export default Widget
