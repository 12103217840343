import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadMyCourse } from '../../actions/course'
import { setNavigation } from '../../reducers/navigation'
import { setBreadcrumb } from '../../reducers/breadcrumb'
import LessonCourse from './LessonCourse'

function MyCourse(props) {
  const dispatch = useDispatch()
  const setMenuSideBar = useCallback((course, my_course_id) => {
    let options = [{
      title: course.name,
      icon: 'dashboard',
      progress: course.perc_completed,
      area_color: course.area.primary_color,
      items: [
        {
          url: '/',
          icon: 'dashboard',
          title: 'Meus Cursos',
          items: []
        }
      ]
    }]

    let item = { title: 'Conteúdo do Curso', items: [] }

    for (let i = 0; i < course.topics.length; i++) {
      const topic = course.topics[i]

      const topic_ = {
        url: '#',
        progress: topic.perc_completed.toString(),
        area_color: course.area.primary_color,
        number: topic.number,
        title: topic.name,
        items: [],
      }

      for (let i = 0; i < topic.lessons.length; i++) {
        const lesson = topic.lessons[i];
        topic_.items.push({
          url: `/my-courses/${my_course_id}/lessons/${lesson.id}`,
          number: lesson.number,
          progress: lesson.perc_completed,
          area_color: course.area.primary_color,
          title: lesson.title,
          items: [],
        })
      }

      item.items.push(topic_)

      for (let i = 0; i < topic.subtopics.length; i++) {
        const subtopic = topic.subtopics[i];
        const subtopic_ = {
          url: '#',
          number: subtopic.number,
          progress: subtopic.perc_completed.toString(),
          area_color: course.area.primary_color,
          title: subtopic.name,
          items: []
        }

        for (let i = 0; i < subtopic.lessons.length; i++) {
          const lesson = subtopic.lessons[i];

          subtopic_.items.push({
            url: `/my-courses/${my_course_id}/lessons/${lesson.id}`,
            icon: 'supervisor_account',
            progress: lesson.perc_completed,
            area_color: course.area.primary_color,
            number: lesson.number,
            title: lesson.title,
            items: []
          })
        }
        item.items.push(subtopic_)
      }
    }

    options.push(item)
    dispatch(setNavigation(options))
  }, [dispatch])

  const refleshDataCourse = useCallback(course_id => {
    loadMyCourse(course_id).then(course => {
      setMenuSideBar(course, course_id)
    })
  }, [setMenuSideBar])

  useEffect(() => {
    const id = props.match.params.id

    loadMyCourse(id).then(course => {
      setMenuSideBar(course, id)

      const items = [
        { page: 'Meus Cursos', url: '/' },
        { page: course.name, url: '#' },
      ]
      dispatch(setBreadcrumb(items))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LessonCourse reflesh={() => refleshDataCourse(props.match.params.id)} />
  )
}


export default MyCourse