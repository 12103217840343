import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { saveAs } from 'file-saver';
import { FiAlertTriangle } from 'react-icons/fi';
import VideoPlayer from '../../components/video/VideoPlayer';
import { loadImage, loadPdfFile, loadOtherFile } from '../../actions/resources';

function Preview(props) {
  const [previewResource, setPreviewResource] = useState({});

  const getTypeMedia = useCallback(() => {
    const dispositive = window.navigator.platform;

    if (dispositive === 'iPhone') {
      return 'mp4';
    } else {
      return 'dash';
    }
  }, []);

  const prepareResource = useCallback(async resource => {
    switch (resource.type) {
      case 'video': {
        setPreviewResource({});

        setTimeout(() => {
          const _type = getTypeMedia();
          let _url = '';
          if (_type === 'mp4') {
            const urlMp4 = resource.url.replace('.mpd', '_720.mp4');
            _url = `${process.env.REACT_APP_API_BASE_URL}${urlMp4}`;
          } else {
            _url = `${process.env.REACT_APP_API_BASE_URL}${resource.url}`;
          }
          setPreviewResource({
            url: _url,
            type: resource.type,
            media_type: _type,
            extname: resource.extname,
            name: resource.name,
            id: resource.id,
            progress_time_in_ms: resource.progress_time_in_ms,
          });
        }, 100);

        break;
      }

      case 'image': {
        await loadImage(resource.id).then(image => {
          setPreviewResource({
            url: image,
            type: 'image',
            extname: resource.extname,
            name: resource.name,
          });
        });

        break;
      }

      case 'pdf': {
        loadPdfFile(resource.id).then(pdf => {
          setPreviewResource({
            url: pdf,
            type: 'pdf',
            extname: resource.extname,
            name: resource.name,
          });
        });

        break;
      }

      case 'application': {
        loadOtherFile(resource.id).then(file => {
          setPreviewResource({
            url: file,
            type: 'other',
            extname: resource.extname,
            name: resource.name,
          });
        });

        break;
      }

      case 'url': {
        setTimeout(() => {
          setPreviewResource({ url: resource.url, type: 'link' });
        }, 200);
        break;
      }

      case 'youtube': {
        setTimeout(() => {
          setPreviewResource({ url: resource.url, type: 'youtube' });
        }, 200);
        break;
      }

      default:
        setPreviewResource({ url: '', type: '', extname: '', name: '' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = useCallback(file => {
    saveAs(file.url, `${file.name}`);
  }, []);

  useEffect(() => {
    if (props.resource.id) prepareResource(props.resource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource.id]);

  useEffect(() => {
    setPreviewResource({});
  }, [props.resource.id]);

  return (
    <Container className="">
      {previewResource.type && (
        <div className="col-12 text-center">
          {previewResource.type === 'image' && (
            <>
              <img
                src={previewResource.url}
                className="img-fluid"
                alt="Imagem"
                style={{ maxHeight: '500px' }}
              />
              {/* <div>{previewResource.name}</div> */}
            </>
          )}

          {previewResource.type === 'pdf' && (
            <object
              data={previewResource.url}
              type="application/pdf"
              style={{ height: '500px', width: '100%' }}
            >
              <iframe
                title={previewResource.name}
                src={previewResource.url}
                width="100%"
                height="100%"
              />
            </object>
          )}

          {previewResource.type === 'link' && (
            <div className="row justify-content-center">
              <URL className="col-md-4">
                <h4 className="mb-5 mt-4">Link Externo</h4>

                <div className="mb-5">
                  <h6>Deseja abrir esse link em uma noja janela?</h6>
                  <span className="text-warning">{previewResource.url}</span>
                </div>

                <a
                  target="_blank"
                  href={`${previewResource.url}`}
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-lg btn-info">Abrir Link</button>
                </a>
              </URL>
            </div>
          )}

          {previewResource.type === 'other' && (
            <div className="row justify-content-center">
              <URL className="col-md-6">
                <div className="text-info">
                  <div>
                    <FiAlertTriangle size={100} />
                  </div>
                </div>

                <h5 className="mt-3">
                  Esse documento não pode ser visualizado.
                </h5>
                <h6>Deseja baixar esse documento?</h6>

                <div>
                  <span className="text-warning">{previewResource.name}</span>
                </div>

                <button
                  className="btn btn-lg btn-info mt-3"
                  onClick={() => downloadFile(previewResource)}
                >
                  Baixar Arquivo
                </button>
              </URL>
            </div>
          )}

          {previewResource.type === 'video' && (
            <VideoContainer>
              <VideoPlayer
                src={previewResource.url}
                type={previewResource.media_type}
                resource_id={previewResource.id}
                progress_time_in_ms={previewResource.progress_time_in_ms}
                my_course_id={props.my_course_id}
              />
            </VideoContainer>
          )}

          {previewResource.type === 'youtube' && (
            <VideoContainer className="col">
              <ReactPlayer url={previewResource.url} />
            </VideoContainer>
          )}
        </div>
      )}
    </Container>
  );
}

Preview.defaultProps = {
  resource: Object,
  my_course_id: String,
};

export default Preview;

export const Container = styled.div`
  /* max-height: 550px; */
  /* padding: 40px; */
  /* align-self: center; */
  /* background-color: #f9f9f9; */
`;
export const URL = styled.div`
  height: 300px;
  padding: 20px;
  background-color: #f9f9f9;
`;

export const VideoContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
