import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import SelectLabel from '../../../../components/Form/input/SelectLabel'

const FormSubscription = (props) => (
  <Form
    ref={props.formRef}
    onSubmit={props.submit}
    noValidate>
    <div className="row justify-content-center ">
      <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
        <SelectLabel
          name="course_id"
          label="Curso"
          placeholder="Selecione.."
          options={props.optionsCourse}
          noOptionsMessage={() => 'Nenhum curso ATIVO encontrado'}
          onChange={e => props.prepareOptionsClass(e.value)}
          required={true}
        />
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
        <SelectLabel
          name="class_id"
          label="Turma"
          placeholder="Selecione.."
          noOptionsMessage={() => props.noDataMessageClass}
          options={props.optionsClass}
        />
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-sm col-md-6 d-flex align-items-end no-gutters">
        <SelectLabel
          name="user_id"
          label="Aluno"
          required={true}
          placeholder="Selecione.."
          options={props.optionsUser}
        />
      </div>
    </div>

    <div className="row justify-content-center mt-5">
      <div className="col-sm col-md-6 d-flex justify-content-end p-0">
        <div className="col">
          <Link to="/subscriptions" className="btn btn-lg  btn-block border-info">
            Voltar
          </Link>
        </div>
        <div className="col">
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
            </button>
        </div>
      </div>
    </div>
  </Form >
)

FormSubscription.defaultProps = {
  submit: Function,
  formRef: Function,
  prepareOptionsClass: Function,
  optionsCourse: Object,
  optionsClass: Object,
  optionsUser: Object,
  noDataMessageClass: String
}

export default FormSubscription