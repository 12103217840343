import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'
// import { logoff } from './auth'

export function setUserRedux(key, value) {
  let user = {
    [key]: value
  }
  return {
    type: 'SET_USER',
    user
  }
}

export function setLoggedUser(value) {
  let user = {
    user: value.user,
    permissions: value.permissions,
    roles: value.roles
  }
  return {
    type: 'SET_LOGGED_USER',
    user
  }
}

export const showThumbnail = id => {
  if (id) {
    const result = new Promise((resolve, reject) => {
      api.get(`users/${id}/thumbnail`, { responseType: 'arraybuffer' })
        .then(result => {
          const buffer = Buffer.from(result.data, 'base64');
          const blob = new Blob([buffer]);
          const imageUrl = URL.createObjectURL(blob);
          resolve(imageUrl)
        })
        .catch(error => {
          resolve(null)
          console.log(handleError(error))
        })
    })

    return result
  } else {
    return '/assets/images/profile.jpeg'
  }
}

export const show = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`users/${id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(toastError(handleError(error)))
      });
  })
  return result
}

export const showUserProfile = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`users/${id}/profile`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(toastError(handleError(error)))
      });
  })
  return result
}

export const update = (data) => {
  if (data.id) {
    const result = new Promise((resolve, reject) => {
      api.put(`users/${data.id}`, data)
        .then(result => {
          const user = result.data.user
          toastSuccess(`Usuário ${user.name} atualizado!`)
          resolve(user)
        }).catch(error => {
          reject(toastError(handleError(error)))
        })
    })

    return result
  } else {
    return toastError('Id usuário inválido!')
  }
}

export const updateUserProfile = (data) => {
  if (data.id) {
    const result = new Promise((resolve, reject) => {
      api.put(`users/${data.id}/profile`, data)
        .then(result => {
          const user = result.data.user
          toastSuccess(`Usuário ${user.name} atualizado!`)
          resolve(user)
        }).catch(error => {
          reject(toastError(handleError(error)))
        })
    })

    return result
  } else {
    return toastError('Id usuário inválido!')
  }
}

export const store = (data) => {
  const result = new Promise((resolve, reject) => {
    api.post(`users`, data)
      .then(result => {
        const user = result.data.user
        toastSuccess(`Usuário incluído com sucesso!`)
        resolve(user)
      }).catch(error => {
        reject(toastError(handleError(error)))
      })
  })
  return result
}

export const remove = (id) => {
  const result = new Promise((resolve, reject) => {
    api.delete(`users/${id}`)
      .then(() => {
        toastSuccess(`Usuário excluído com sucesso!`)
        resolve()
      }).catch(error => {
        reject(toastError(handleError(error)))
      })
  })
  return result
}

export const index = params => {
  const result = new Promise((resolve, reject) => {
    api.get(`users`, { params: params })
      .then(response => {
        const users = response.data
        resolve(users)
      }).catch(error => {
        reject(toastError(handleError(error)))
      })
  })
  return result
}

export const students = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`students`)
      .then(response => {
        const users = response.data
        resolve(users)
      }).catch(error => {
        reject(toastError(handleError(error)))
      })
  })
  return result
}

export const loadRoles = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`roles`)
      .then(result => {
        resolve(result.data)
      }).catch(error => {
        reject(toastError(handleError(error)))
      });
  })

  return result
}

export const loadPermissions = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`permissions`)
      .then(result => {
        resolve(result.data)
      }).catch(error => {
        reject(toastError(handleError(error)))
      });
  })

  return result
}

export const hasPermission = (permission, permissions) => {
  return permissions.includes(permission) ? true : false
}
