import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { indexFaqs } from '../../../actions/faq'
// import api from '../../../actions/api'
// import axios from 'axios'

function Faq(props) {
  const [layout, setLayout] = useState({})
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    setLayout({
      title: 'Perguntas frequentes',
      title_font_color: '#4E4E4E',
      faq_card_background_color: '#303f9f',
      faq_card_name: 'card1',
      faq_question_color: '#ffffff',
      faq_answer_color: '#ffffff',
      button_label: 'Ver todas as perguntas',
      button_background_color: '#303f9f',
      button_background_color_hover: '#46a8dd',
      button_text_color: '#ffffff',
      button_text_color_hover: '#303f9f',
    })
    // const source = axios.CancelToken.source()

    // api.get('layout-faqs')
    //   .then(response => {
    //     setLayout(response.data)
    //   })
    //   .catch(() => { })

    // return () => source.cancel()
  }, [])

  useEffect(() => {
    let isSubscribed = true;

    indexFaqs().then(faqs => {
      if (isSubscribed) {
        setFaqs(faqs.slice(0, 3))
      }
    })

    return () => (isSubscribed = false)
  }, [])

  return (
    <Container className="container mb-3" layout={layout}>
      {faqs.length > 0 && (
        <>
          <div className="row text-center">
            <div className="col">
              <h2>{layout.title}</h2>
            </div>
          </div>

          <div className="row mt-4">
            {faqs.map((faq, i) => (
              <div key={i} className="col-sm">
                <CardFaq layout={layout}>
                  <div className="card-body text-center">
                    <h5 className="card-title">{faq.question}</h5>
                    <p className="card-text"> {faq.answer}</p>
                  </div>
                </CardFaq>
              </div>
            ))}
          </div>

          <div className="row mt-3 justify-content-center">
            <div className="col-sm col-md-4">
              <Link to="/faqs" className="btn btn-info btn-block button">
                {layout.button_label}
              </Link>
            </div>
          </div>
        </>
      )}
    </Container >
  )
}

export default Faq

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  
  h2 {
    color: ${props => props.layout.title_font_color};
    font-weight: bold;
    font-size: 22px
  }

  .button {
    border: 0;
    background: ${props => props.layout.button_background_color} !important;
    color: ${props => props.layout.button_text_color} !important;
    :hover{
      background: ${props => props.layout.button_background_color_hover} !important;
    }
  }
`

export const CardFaq = styled.div`
  background: ${props => props.layout.faq_card_background_color} !important;
  border-radius: ${props => {
    switch (props.layout.faq_card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px'

      case 'card3':
        return '24px 0 24px 24px'

      case 'card4':
        return '24px 24px 24px 0'

      case 'card5':
        return '0 24px 24px 24px'

      case 'card6':
        return '12px'

      default:
        return 'none'
    }
  }} !important;
 
  h5 {
    font-style: normal!important;
    color: ${props => props.layout.faq_question_color};
    font-weight: bold;
    font-size: 16px;
    height: 40px;
  }

  p{
    color: ${props => props.layout.faq_answer_color};
    height: 80px;
  }
`