import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaRegUser, FaPowerOff } from 'react-icons/fa'
import { RiLockPasswordLine } from 'react-icons/ri'
// import { FiTag } from 'react-icons/fi'
import '../../css/navbar/dropdown-user.css'

import { logoff } from '../../actions/auth'

import ModalConfirmAlterPassword from './ModalAlterPassword'

function DropdownUser(props) {
  const [alterPassword, setAlterPassword] = useState(false)

  return (
    <>
      < div className="navbar-dropdown dropdown-user" >
        <div className="dropdown-title">{props.user.name}</div>
        <Link to={`/profile/${props.user.id}`} className="dropdown-item">
          <FaRegUser />
          <span className="title">Meus dados</span>
          <div className="separator" />
        </Link>

        {/* <Link to={`/purchases}`} className="dropdown-item">
          <FiTag />
          <span className="title">Minhas compras</span>
          <div className="separator" />
        </Link> */}

        <div className="dropdown-item" onClick={() => setAlterPassword(true)}>
          <RiLockPasswordLine />
          <span className="title">Alterar senha</span>
          <div className="separator" />
        </div>

        <div className="dropdown-item" onClick={logoff}>
          <FaPowerOff />
          <span className="title" >Sair</span>
          {/* <Link to="/logout"><span>Sair</span></Link> */}
          <div className="separator" />
        </div>
      </div >

      <ModalConfirmAlterPassword
        user={props.user || null}
        isOpen={alterPassword}
        isHide={() => setAlterPassword(false)} />
    </>
  )
}

DropdownUser.defaultProps = {
  user: Object
}

export default DropdownUser
