import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const loadClassById = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`classes/${id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const indexClass = params => {
  const result = new Promise((resolve, reject) => {
    api.get(`classes`, { params: params })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const indexClassNoPaginalble = () => {
  const result = new Promise((resolve, reject) => {
    
    const params = {perPage : 1000}

    api.get(`classes`, { params: params })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const saveClass = data => {
  const result = new Promise((resolve, reject) => {
    api.post(`classes`, data)
      .then(response => {
        toastSuccess('Turma adicionada com sucesso!');
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const updateClass = data => {
  const result = new Promise((resolve, reject) => {
    api.put(`classes/${data.id}`, data)
      .then(result => {
        toastSuccess('Turma atualizada com sucesso!');
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const deleteClass = id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`classes/${id}`)
      .then(() => {
        toastSuccess('Turma removida com sucesso!');
        resolve()
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

