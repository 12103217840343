import React, { useEffect, useState, useCallback } from 'react'
import { IoMdAdd } from 'react-icons/io'
import styled from 'styled-components'
import { AiOutlineEdit } from 'react-icons/ai'

// import FormTopic from './FormTopic'
import DeleteTopic from './DeleteTopic'
import LessonItem from '../lesson/LessonItem'
import NewLessonModal from '../lesson/NewLessonModal'
import KeepSubtopic from './KeepSubtopic'

function SubtopicItem(props) {
  const [subtopic, setSubtopic] = useState({})
  const [addLesson, setAddLesson] = useState(false)
  const [selectSubtopic, setSelectSubtopic] = useState(false)
  const [canRemove, setCanRemove] = useState(false)

  const handleCanRemove = useCallback(() => {
    if (subtopic.lessons && subtopic.lessons.length > 0) {
      setCanRemove(true)
    } else {
      setCanRemove(false)
    }
  }, [subtopic])

  useEffect(() => {
    setSubtopic(props.subtopic)
    handleCanRemove()
  }, [props, subtopic, handleCanRemove])
  return (
    <>
      <tr className="mt-1">
        <td>
          <div className="row">
            <div className="col-sm col-md-6">
              <div className="d-flex">
                <div className="col-2">
                  {subtopic.number}
                </div>
                <NameTopic className="col-sm" onClick={() => setSelectSubtopic(true)}>
                  {subtopic.name}
                </NameTopic>
              </div>

              <KeepSubtopic
                course_id={props.course_id}
                subtopic={subtopic}
                showModal={selectSubtopic}
                hideModal={() => setSelectSubtopic(false)}
                updateCoursePage={() => props.updateCoursePage()}
              />
            </div>

            <div className="col-sm row justify-content-end">
              <>
                <button
                  className="btn btn-sm border-success m-1"
                  onClick={() => setAddLesson(true)}>
                  <IoMdAdd />  Aula
                </button>

                <NewLessonModal
                  data={subtopic}
                  showModal={addLesson}
                  hideModal={() => setAddLesson(false)}
                  updateCoursePage={() => props.updateCoursePage()}
                />
              </>

              <button
                className="btn btn-sm border-primary m-1"
                onClick={() => setSelectSubtopic(true)}>
                <AiOutlineEdit />
              </button>

              <div>
                <DeleteTopic
                  id={subtopic.id}
                  name={subtopic.name}
                  disabled={canRemove}
                  updateCoursePage={() => props.updateCoursePage()} />
              </div>
            </div>
          </div>
        </td>
      </tr>

      {subtopic.lessons &&
        subtopic.lessons.map((lesson, i) => (
          <LessonItem
            key={i}
            lesson={lesson}
            course_id={props.course_id}
            updateCoursePage={() => props.updateCoursePage()} />
        ))
      }

      {/* <li className="list-group-item bg-light">
        <div className="row justify-content-between">
          <div className="col-sm align-self-center" >
            <NameTopic onClick={() => setSelectSubtopic(true)}>
              {subtopic.number} {subtopic.name}</NameTopic>

            {/* TODO edit subtopic *
            <KeepSubtopic
              course_id={props.course_id}
              subtopic={subtopic}
              showModal={selectSubtopic}
              hideModal={() => setSelectSubtopic(false)}
            />
          </div>

          <div className="row col-sm col-md-4 justify-content-end align-items-center">
            <div className="col-sm" />

            <div className="col">
              <AddAula onClick={() => setAddLesson(true)}>
                <IoMdAdd /> Add Aula
              </AddAula>
            </div>

            <DeleteTopic id={subtopic.id} name={subtopic.name} />
          </div>
        </div>
      </li> */}

      {/* {subtopic.lessons &&
        subtopic.lessons.map((lesson, i) => (
          <LessonItem key={i} lesson={lesson} course_id={props.course_id} />
        ))
      } */}

      {/* <NewLessonModal
        data={subtopic}
        showModal={addLesson}
        hideModal={() => setAddLesson(false)}
      /> */}
    </>
  )
}
SubtopicItem.defaultProps = {
  subtopic: Object,
  updateCoursePage: Function
}

export default SubtopicItem

export const NameTopic = styled.div`
  :hover {
    cursor: pointer;
    color: #0288d1;
  }
`
