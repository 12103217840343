import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const loadCheckout = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`checkouts`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const postCheckoutItem = data => {
  const result = new Promise((resolve, reject) => {
    api.post(`checkout-items`, data)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const deleteCheckoutItem = id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`checkout-items`, { data: { course_id: id } })
      .then(() => {
        toastSuccess('Item removido do carrinho')
        resolve()
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const purchase = () => {
  const result = new Promise((resolve, reject) => {
    api.post(`purchases`, { instalments: 1 })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

// react-redux
export const addCheckout = checkout => {
  return {
    type: 'ADD_CHECKOUT',
    checkout: checkout
  }
}

export const addCheckoutItem = course => {
  return {
    type: 'ADD_CHECKOUT_ITEM',
    course: course
  }
}

export const removeCheckoutItem = id => {
  return {
    type: 'REMOVE_CHECKOUT_ITEM',
    course_id: { id }
  }
}