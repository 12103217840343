import React, { Component } from 'react'
import { showCourse, loadLayoutCourseDetail } from '../../../actions/course'
import Contact from '../shared/Contact'
import BannerCourse from './BannerCourse'
import Content from './Content'

class CourseDetail extends Component {

  constructor(props) {
    super()

    this.state = {
      course: {},
      course_content: [
        { content: 'Conteúdo 1' },
        { content: 'Conteúdo 2' },
        { content: 'Conteúdo 3' },
        { content: 'Conteúdo 4' },
        { content: 'Conteúdo 5' },
        { content: 'Conteúdo 6' },
        { content: 'Conteúdo 7' },
        { content: 'Conteúdo 8' },
        { content: 'Conteúdo 9' },
        { content: 'Conteúdo 10' },
      ],
      layout: {}
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      showCourse(id)
        .then(course => this.setState({ course }))
    }

    loadLayoutCourseDetail()
      .then(layout => this.setState({ layout }))
  }

  render() {
    return (
      <div className="mb-5">
        <BannerCourse course={this.state.course} layout={this.state.layout} />

        <Content
          course={this.state.course}
          course_content={this.state.course_content}
          layout={this.state.layout} />

        <Contact />
      </div>
    )
  }
}

export default CourseDetail
