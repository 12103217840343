import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import Widget from '../../../../components/widget'
import FormSubscription from './FormSubscription'
import { indexClass } from '../../../../actions/class'
import { show, update } from '../../../../actions/subscription'

function EditSubscription(props) {
  const formRef = useRef(null)
  const fixedProps = useRef()
  const dispatch = useDispatch()
  const [subscription, setSubscription] = useState({})
  const [selectedClass, setSelectedClass] = useState({})
  const [optionsClass, setOptionsClass] = useState([])

  const updateSubscription = useCallback(async (data) => {
    const _data = {
      id: subscription.id,
      course_id: subscription.course_id,
      class_id: data.class_id,
      user_id: subscription.user_id
    }
    update(_data)
      .then(subscription =>
        props.history.push(`/subscriptions/${subscription.id}`))
  }, [props, subscription])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        class_id: Yup.string().required('Turma é obrigatória'),
      })

      await schema.validate(data, { abortEarly: false })
      updateSubscription(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [updateSubscription])

  const prepareOptionsClass = useCallback(course_id => {
    indexClass({ course_id: course_id })
      .then(response => {
        const options = response.data.map(item => {
          return { label: item.name, value: item.id }
        })
        setOptionsClass(options)
      })
  }, [])

  const removeClass = useCallback(() => {
    updateSubscription({ class_id: null })
      .then(() => {
        setSelectedClass({})
      })
  }, [updateSubscription])

  useEffect(() => {
    fixedProps.current = props
  })

  useEffect(() => {
    const id = fixedProps.current.match.params.id
    let isSubscribed = true

    if (isSubscribed && id)
      show(id)
        .then(subscription => {
          setSubscription(subscription)

          prepareOptionsClass(subscription.course_id)

          if (subscription.class) {
            setSelectedClass({
              label: subscription.class.name,
              value: subscription.class.id
            })
          }

          dispatch({
            type: 'SET_BREADCRUMB',
            items: [
              { page: 'Matrículas', url: '/subscriptions' },
              { page: 'Edtar', url: '#' },
            ]
          })
        })
        .catch(() => fixedProps.current.history.push('/subscriptions'))

    return () => (isSubscribed = false)
  }, [prepareOptionsClass, dispatch])

  return (
    <Widget title={subscription.id ? `Editar Matrícula` : 'Nova Matrícula'} >
      <FormSubscription
        initialData={subscription}
        submit={submit}
        formRef={formRef}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        optionsClass={optionsClass}
        removeClass={removeClass}
      />
    </Widget >
  )
}

export default EditSubscription;