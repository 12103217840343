import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const showArea = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`areas/${id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadLayoutAreaDetail = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`layout-area-details`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const indexAreas = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`areas`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadImageArea = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`area-image-files/${id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const buffer = Buffer.from(result.data, 'base64');
        const blob = new Blob([buffer]);
        const imageUrl = URL.createObjectURL(blob);
        resolve(imageUrl)
      })
      .catch(() => {
        reject(null)
      })
  })
  return result
}

export const saveArea = area => {
  const result = new Promise((resolve, reject) => {
    api.post(`areas`, area)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const updateArea = area => {
  const result = new Promise((resolve, reject) => {
    api.put(`areas/${area.id}`, area)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const deleteArea = id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`areas/${id}`)
      .then(() => {
        toastSuccess('Àrea removida com sucesso!');
        resolve()
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}
