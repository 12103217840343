import React, { useCallback, useState } from 'react'
import { MdDeleteForever } from 'react-icons/md'
// import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

function DeleteTopic(props) {
  const [showModal, setShowModal] = useState(false)

  const deleteTopic = useCallback(async id => {
    await api.delete(`topics/${id}`)
      .then(() => {
        props.updateCoursePage()
        toastSuccess(`Tópico excluido!`)
        setShowModal(false)
      }).catch(error => {
        toastError(handleError(error));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <button
        disabled={props.disabled}
        className="btn btn-sm border-danger m-1"
        onClick={() => setShowModal(true)}>
        <MdDeleteForever />
      </button>
      {/* <Delete onClick={() => setShowModal(true)}>
        <MdDeleteForever className="text-danger" size={18} />
      </Delete> */}

      <Modal
        isOpen={showModal}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader >Deletar Tópico</ModalHeader>
        <ModalBody>
          Deseja excluir o tópico {props.name}?
        </ModalBody>
        <ModalFooter>
          <button className="btn border-danger" onClick={() => setShowModal(false)}>
            Cancelar
          </button>
          <button className="btn btn-info col-3" onClick={() => deleteTopic(props.id)}>
            Sim
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}
DeleteTopic.defaultProps = {
  id: String,
  name: String,
  updateCoursePage: Function
}

export default DeleteTopic

// export const Delete = styled.div`
//   svg:hover {
//     width: 20px !important;
//     height: 20px !important;
//     color: #ab2424 !important;
//     cursor: pointer;
//   }
// `