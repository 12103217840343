import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import InputLabel from '../../components/Form/input/InputLabel'
import { Form } from '@unform/web'

function UploadURL(props) {
  const formRef = useRef(null)
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [lessonId, setLessonId] = useState('')

  const changeType = useCallback(e => {
    setType(e.target.value)
    formRef.current.setFieldValue('type', e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = useCallback((data) => {
    setLoading(true)
    data.lesson_id = lessonId

    if (data.url.substr(0, 8) !== 'https://') {
      data.url = 'https://' + data.url
    }

    api.post(`url-resources`, data)
      .then(() => {
        toastSuccess(`Link inserido com sucesso!`)
        setLoading(false)
        props.cancelUpload()
        props.updateLessonPage()
      })
      .catch(error => {
        toastError(handleError(error))
        props.cancelUpload()
      })
  }, [props, lessonId])

  useEffect(() => {
    if (props.lesson_id) {
      setLessonId(props.lesson_id)
      setType('url')
      formRef.current.setFieldValue('type', 'url')
    }
  }, [props])

  return (
    <>
      {loading ? (
        <div className="text-center mt-3">
          <ClipLoader size={150} color={"#0288D1"} />
          <div>
            <label className="loading text-info">salvando url...</label>
          </div>
        </div>
      ) : (
          <Form
            ref={formRef}
            onSubmit={submit}
            noValidate>

            <div className="row col-12 justify-content-center">
              <div className="col-md-6">
                <RadioButton className="text-left">
                  {/* usada para mapear o atributo type no FORM - NAO REMOVER */}
                  <div style={{ visibility: 'hidden', height: '1px' }}>
                    <InputLabel name="type" />
                  </div>
                  {/* ///// */}

                  <div>
                    <span>Tipo do Link</span>
                  </div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      checked={type === 'url'}
                      onChange={e => changeType(e)}
                      type="checkbox"
                      value="url"
                      id="externo"
                      name="type"
                      className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="externo">Externo</label>
                  </div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      checked={type === 'youtube'}
                      onChange={e => changeType(e)}
                      type="checkbox"
                      value="youtube"
                      id="youtube"
                      name="type"
                      className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="youtube">Youtube</label>
                  </div>
                </RadioButton>

                <InputLabel name="name" label="Nome" placeholder="Nome da url" />
                <InputLabel name="url" label="Url" placeholder="https://..." />
              </div>
            </div>

            <div className="d-flex col-12 justify-content-center mt-3">
              <div className="col-sm col-md-2 pl-0">
                <button type="button" onClick={() => props.cancelUpload()}
                  className="btn btn-lg btn-block border-danger">
                  Cancelar
                </button>
              </div>

              <div className="col-sm col-md-2 pr-0">
                <button type="submit" className="btn btn-lg btn-block btn-info">
                  Upload url
                </button>
              </div>
            </div>
          </Form>
        )}
    </>
  )
}

UploadURL.defaultProps = {
  lesson_id: String,
  updateLessonPage: Function
}

export default UploadURL

export const PreviewUpload = styled.div`
  margin-top: 20px;
  background-color: #F0F0F0;
  padding: 20px;

  img {
    max-width: 400px;
    max-height: 300px;
  }
 
  video {
    max-width: 400px;
  }

  .loading {
    font-style: italic;
  }
`

export const RadioButton = styled.div`
  span {
    font-size: 13px;
    color: #4d4d4d;
  }
`