import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { Link } from 'react-router-dom'
import { Container } from './styles'

const SelectLabel = ({ name, label, defaultValue, options, onChange, link, required, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }

          return ref.state.value.value;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      <label className="mt-3" htmlFor={fieldName}>
        {link ? (
          <Link to={link}>{required ? '*' : ''}  {label}</Link>
        ) : (
            <>{label}</>
          )}
      </label>

      <ReactSelect
        defaultValue={
          defaultValue && options.find(option => option.value === defaultValue)
        }
        ref={selectRef}
        classNamePrefix="react-select"
        options={options}
        tabSelectsValue={false}
        onChange={onChange}
        {...rest}
      />

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
};

export default SelectLabel;