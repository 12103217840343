export function config(
  state = {
    layout: 'collapsed-sidebar',
    background: 'light',
    navbar: 'light',
    topNavigation: 'danger',
    logo: 'light',
    leftSidebar: 'dark',
    leftSidebarIcons: 'dark',
    rightSidebar: false,
    collapsed: false,
    buttonTemplate: 'button',
    buttonBgColor: '#0288d1',
    buttonColor: '#fff',
  },
  action
) {
  switch (action.type) {
    case 'SET_CONFIG':
      return Object.assign({}, state, {
        ...action.config
      })
    default:
      return state
  }
}
