import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import Widget from '../../../components/widget'
import { updateTenantContact, saveTenantContact, loadTenantContactById }
  from '../../../actions/contact'
import FormContact from './FormContact'

function Contact(props) {
  const formRef = useRef(null)
  const [contact, setContact] = useState({})

  useEffect(() => {
    const { id } = props.match.params

    if (id)
      loadTenantContactById(id).then(contact => {
        setContact(contact)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = useCallback(async (data) => {
    await saveTenantContact(data)
      .then(() => props.history.push('/tenant-contacts'))
      .catch(() => props.history.push('/'))
  }, [props])

  const update = useCallback(async (data) => {
    data.id = props.match.params.id
    await updateTenantContact(data)
      .then(props.history.push('/tenant-contacts'))
      .catch(() => props.history.push('/'))
  }, [props])

  const submit = useCallback(async (data) => {
    data.icon = "icon"

    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        value: Yup.string().required('Endereço é obrigatório'),
      })

      await schema.validate(data, { abortEarly: false })

      contact.id ? update(data) : save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [save, update, contact])




  return (
    <Widget title="Contatos">
      <div className="row justify-content-center">
        <div className="col-sm col-md-6">
          <FormContact
            initialData={contact}
            submit={submit}
            formRef={formRef}
          />
        </div>
      </div>
    </Widget >
  )
}

export default Contact;