
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import IndigoPlayerFunction from './IndigoPlayerFunction'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  branch(

    () => {
      return true
    },
    renderComponent(IndigoPlayerFunction),
    renderNothing
  )
)(IndigoPlayerFunction)

export default Component