import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { showThumbnail } from '../../actions/user'
import DropdownUser from './DropdownUser'

function Profile(props) {
  const [user, setUser] = useState({})
  const [preview, setPreview] = useState('')

  const setThumbnail = useCallback(() => {
    if (props.thumbnail) {
      setPreview(props.thumbnail)
    } else if (props.user.thumbnail) {
      showThumbnail(props.user.id)
      .then(result => {
        if(result)setPreview(result)
        else setPreview('/assets/images/profile.jpeg')
      })
    } else {
      setPreview('/assets/images/profile.jpeg')
    }
  }, [props])

  useEffect(() => {
    if (props.user.id) {
      setUser(props.user)
      setThumbnail()
    }
  }, [props, setThumbnail]);

  return (
    <>
      {user && (
        <div className="nav-link nav-link-avatar" style={{ marginBottom: '30px' }}>
          <img
            src={preview}
            className="rounded-circle"
            alt="avatar"
          />
          <DropdownUser user={user} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    thumbnail: state.user.thumbnail
  }
}

export default connect(mapStateToProps)(Profile) 
