import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import CourseDetail from './CourseDetail'

const layouts = ['default-sidebar', 'collapsed-sidebar', 'top-navigation-1']

const Component = compose(
  connect(state => {
    return {
      navigation: state.navigation,
      layout: state.config.layout
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Curso', url: '#' },
        ]
      })
    }
  }),
  branch(
    ({ layout }) => layouts.includes(layout),
    renderComponent(CourseDetail),
    renderNothing
  )
)(CourseDetail)

export default Component