export const menuSite = () => {
  const menu = [
    {
      title: '',
      items: [
        {
          url: '/',
          icon: 'dashboard',
          title: 'Cursos',
          items: []
        },
        {
          url: '/',
          icon: 'contacts',
          title: 'Contatos',
          items: []
        },
        {
          url: '/login',
          icon: 'login',
          title: 'Login',
          items: []
        }
      ]
    },
  ]
  return menu
}
