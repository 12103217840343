import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import Widget from '../../../components/widget'
import { updateTenant, loadTenants } from '../../../actions/tenant'
import FormTenant from './FormTenant'

function TenantInformations(props) {
  const formRef = useRef(null)
  const [tenant, setTenant] = useState({})

  useEffect(() => {
    let isSubscribed = true

    if (props.roles.length > 0) {
      if (props.roles.includes('administration') && isSubscribed) {
        loadTenants().then(tenants => {
          setTenant(tenants[0])
        })
      } else {
        props.history.push('/forbiden')
      }
    }


    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const update = useCallback(async (data) => {
    data.id = props.match.params.id
    await updateTenant(data)
      .then(tenant => setTenant(tenant))
      .catch(() => props.history.push('/'))
  }, [props])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        type: Yup.string().required('Tipo Pessoa é obrigatório'),
        name: Yup.string().required('Nome é obrigatório'),
        cpf_cnpj: Yup.string(),
        ie: Yup.string(),
        cep: Yup.string(),
        street: Yup.string(),
        number: Yup.string(),
        neighborhood: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        complement: Yup.string(),
        email: Yup.string().email('E-mail inválido'),
        phone: Yup.string(),
        cell_phone: Yup.string(),
        subdomain: Yup.string(),
        domain: Yup.string().required('Domínio é obrigatório'),
        status: Yup.string(),
        theme: Yup.object()
      })

      await schema.validate(data, { abortEarly: false })

      update(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [update])

  return (
    <Widget title="Inquilino">
      <FormTenant
        initialData={tenant}
        submit={submit}
        formRef={formRef}
      />
    </Widget >
  )
}

const mapStateToProps = (state) => {
  return {
    roles: state.user.roles
  }
}

export default connect(mapStateToProps)(TenantInformations);