import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'
import styled from 'styled-components'

export default function InputColorLabel({ setColor, name, label, height, width, defaultValue, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);


  return (
    <Container width={width} height={height}>
      <label className="mt-3 text-left" htmlFor={fieldName}>{label}</label>
      <div className="col-sm col-md-2 d-flex">
        <div>
          <input
            id={fieldName}
            ref={inputRef}
            type="color"
            defaultValue={defaultValue}
            onChange={event => event.target.value, setColor}
            className="input-color"
            {...rest}
          />
        </div>
      </div>

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
}

export const Container = styled.div`
  label {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #4D4D4D;
    margin: 0;
    margin-left: 10px;
  }

  .input-color {
    height: ${props => props.height || '60px'};;
    width: ${props => props.width || '60px'};
  }
`