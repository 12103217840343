import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DashboardAdmin from './DashboardAdmin'
import { useDispatch } from 'react-redux'
import DashboardStudent from './DashboardStudent'
import { setBreadcrumb } from '../../reducers/breadcrumb'

const Dashboard = () => {
  const roles = useSelector(state => state.user.roles)
  const dispatch = useDispatch()

  const isAdmin = useCallback(() => {
    return roles.includes("administration")
  }, [roles])

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'Dashboard', url: '#' }]))
  }, [dispatch])

  return (
    <>
      {isAdmin() ? (
        <DashboardAdmin />
      ) : (
        <DashboardStudent />
      )}
    </>
  )
}

export default Dashboard