import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles'

export default function InputFilter({ name, onChange, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container >
      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={onChange}
        className={error ? 'input-error' : ''}
        {...rest}
      />

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
}