import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { ToastContainer } from 'react-toastify';
import { getToken } from './actions/auth';
import { setLoggedUser, showUserProfile } from './actions/user';
import Navbar1 from './template/navbar/navbar-1';
import Navbar2 from './template/navbar/navbar-2';
import LeftSidebar from './template/left-sidebar';
import RightSidebar from './template/right-sidebar';
import Backdrops from './template/backdrops';
// import TopNavigation1 from './template/top-navigation-1'
import Breadcrumb from './template/breadcrumb';
// import Footer from './template/footer'
import Routes from './Routes';

// import { getTheme } from './actions/config'
import { setTheme, setConfig } from './actions/config';
import { isAuthenticated } from './actions/auth';
import './css/main.css';

class App extends Component {
  constructor() {
    super();

    this.state = {
      scrennWidth: window.innerWidth,
    };

    window.addEventListener('resize', this.configLayout);
  }

  componentDidMount() {
    this.configLayout();

    // getTheme().then(theme => {
    //   console.log(theme);
    //   this.props.setTheme(theme)
    // })
  }

  componentDidUpdate() {
    this.getUserLogged();
  }

  getUserLogged = async () => {
    const user = this.props.user;

    if (user.user && user.user.id) {
      return;
    } else {
      const token = getToken();
      if (token) {
        const decoded = jwt.decode(token);

        showUserProfile(decoded.uid).then(data => {
          const user = {
            user: data.user,
            permissions: data.permissions,
            roles: data.roles,
            thumbnail: data.thumbnail,
          };
          this.props.setLoggedUser(user);
        });
      }
    }
  };

  configLayout = () => {
    this.setState({ scrennWidth: window.innerWidth });

    // if (this.state.scrennWidth > 1200 && !isAuthenticated()) {
    //   this.props.setConfig('layout', 'collapsed-sidebar')
    // } else {
    //   this.props.setConfig('layout', 'default-sidebar')
    // }

    if (this.state.scrennWidth > 1200 && !isAuthenticated()) {
      this.props.setConfig('layout', 'top-navigation-1');
    } else {
      this.props.setConfig('layout', 'default-sidebar');
    }
  };

  render() {
    let {
      layout,
      background,
      navbar,
      topNavigation,
      logo,
      leftSidebar,
      collapsed,
    } = this.props.config;

    return (
      <BrowserRouter>
        <div
          data-layout={layout}
          data-background={background}
          data-navbar={navbar}
          data-top-navigation={topNavigation}
          data-logo={logo}
          data-left-sidebar={leftSidebar}
          data-collapsed={collapsed}
        >
          <ToastContainer autoClose={3500} />
          <Backdrops />
          <RightSidebar />
          <Navbar1 />
          <Navbar2 />
          {/* <TopNavigation1 /> */}
          <div className="container-fluid">
            <div className="row">
              {/* {layout === 'default-sidebar' && ( */}
              <LeftSidebar />
              {/* )} */}
              <div className="col main">
                {isAuthenticated() && <Breadcrumb />}
                <Routes />
              </div>
            </div>
          </div>
          {/* <Footer
            bgColor="#DC3545"
            color="#fff"
            fontSize={11}
            text="Cetesc - Cursos Profissionalizantes || Todos os direitos reservados" /> */}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config,
    user: state.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setTheme: (key, value) => dispatch(setTheme(value)),
    setConfig: (key, value) => dispatch(setConfig(key, value)),
    setLoggedUser: value => dispatch(setLoggedUser(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
