import React from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Loading from '../../../components/loading'

const TableUser = (props) => {
  const paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' }
  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      name: 'E-mail',
      selector: 'email',
      sortable: true,
      grow: 2
    },
    {
      name: 'Usuário',
      selector: 'username',
      sortable: true,
      grow: 2
    },
    {
      name: 'Ações',
      cell: (item) => (
        <div>
          <Link to="#">
            <i className="material-icons text-danger rtd_button" onClick={() => props.confirmDelete(item)}>delete_forever</i>
          </Link>
        </div>
      ),
      button: true,
    },
  ]

  return (
    < div className="row" >
      <div className="col">
        <DataTable
          columns={columns}
          data={props.data.data}
          progressPending={props.progressPending}
          pointerOnHover
          highlightOnHover
          noDataComponent="Nenhum registro encontrado"
          onRowClicked={props.onRowClicked}
          progressComponent={<Loading />}
          pagination
          paginationServer
          paginationComponentOptions={paginationOptions}
          paginationPerPage={props.perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          paginationTotalRows={parseInt(props.data.total)}
          onChangeRowsPerPage={props.handlePerRowsChange}
          onChangePage={props.onChangePage}
        />
      </div>
    </div >
  )
}

TableUser.defaultProps = {
  data: Array,
  progressPending: Boolean,
  onRowClicked: Function
}

export default TableUser