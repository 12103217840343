import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import InputLabel from '../../components/Form/input/InputLabel'

function NewLessonModal(props) {
  const formRef = useRef(null)
  // const [focus, setFocus] = useState(false)
  const closeBtn = <button className="close" onClick={() =>
    props.hideModal()}>&times;</button>;

  const [showModal, setShowModal] = useState(false)

  const save = useCallback(async (data) => {
    await api.post('lessons', data)
      .then(() => {
        toastSuccess(`Novo registro incluído!`)
        props.updateCoursePage()
      }).catch(error => {
        toastError(handleError(error))
      })
    props.hideModal()
  }, [props])

  const submit = useCallback(async (data) => {
    data.topic_id = props.data.id
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        topic_id: Yup.string().required('Tópico é obrigatório'),
        title: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        content: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Conteúdo mínimo 8 caracteres') : Yup.string()
        }),
      })

      await schema.validate(data, { abortEarly: false })

      save(data)

    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [props, save])

  useEffect(() => {
    setShowModal(props.showModal)
    // const x = document.getElementById('title')
    // if (!focus && x && props.showModal) {
    //   x.focus()
    //   setFocus(true)
    // }
    // if (!showModal) setFocus(false)

    // return () => { setFocus(false) }
  }, [props, showModal])

  return (
    <>
      <Modal
        isOpen={showModal}
        centered={true}
        fade={false}
        wrapClassName="modal-info"
        size="default">
        <ModalHeader close={closeBtn}>Nova Aula</ModalHeader>
        <ModalBody>
          <Form
            ref={formRef}
            onSubmit={submit}
            noValidate
          >
            <div className="col-sm mt-3  ml-md-2">
              <InputLabel id="title_lesson" name="title" label="Título" />

              <InputLabel name="content" label="Conteúdo" />

              <div className="d-flex justify-content-end mt-4">
                <div className="col-sm col-md-4 pl-0 mb-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-block border-danger"
                    onClick={e => {
                      e.preventDefault()
                      props.hideModal()
                    }}>
                    Cancelar
                  </button>
                </div>

                <div className="col-sm pr-0 col-md-4">
                  <button type="submit" className="btn btn-lg btn-block btn-info">
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
NewLessonModal.defaultProps = {
  data: Object,
  showModal: Boolean,
  updateCoursePage: Function
}
export default NewLessonModal

