import React, { useRef, useCallback, useEffect } from 'react'
// import { withRouter } from "react-router-dom";
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { login } from '../../actions/auth'
import { setLoggedUser } from '../../actions/user'
import { setConfig } from '../../actions/config'
import api from '../../actions/api'
import { toastError } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import FormLogin from './FormLogin'

const Login = (props) => {
  const formRef = useRef(null)

  const signin = useCallback(async (user) => {
    await api.post('authenticate', user)
      .then(res => {
        login(res.data.token)
        props.setLoggedUser(res.data)
        props.setConfig('layout', null) //para poder mostrar o  icon profile na sidebar
        props.setConfig('layout', 'default-sidebar')
        props.history.push('/home')

        // window.location.reload() //para atualizar menus sidebar
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }, [props])

  const submit = useCallback(async (data) => {
    data.email = data.email.toLowerCase()
    try {
      formRef.current.setErrors({})

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatório'),
      })

      await schema.validate(data, { abortEarly: false })

      signin(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [signin])

  useEffect(() => {
    localStorage.removeItem('api_key');
  }, [])

  return (
    <FormLogin formRef={formRef} submit={submit} />
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoggedUser: (value) => dispatch(setLoggedUser(value)),
    setConfig: (key, value) => dispatch(setConfig(key, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
// export default withRouter(Login)