import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Widget from '../../components/widget'
import { purchase, addCheckout } from '../../actions/checkout'
import { setBreadcrumb } from '../../reducers/breadcrumb'

const Checkout = (props) => {
  const cart = useSelector(state => state.checkout)
  const dispatch = useDispatch()

  const handlePurchase = useCallback(() => {
    purchase().then(() => {
      alert('Pagamento Finalizado')
      dispatch(addCheckout({ courses: [] }))
    })
  }, [dispatch])

  useEffect(() => {
    if (cart.courses && cart.courses.length === 0)
      props.history.push('/cart')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  useEffect(() => {
    const items = [
      { page: 'Carrinho', url: '/cart' },
      { page: 'Finalizar compra', url: '#' },
    ]
    dispatch(setBreadcrumb(items))
  }, [dispatch])

  return (
    <div className="container">
      <Widget title="Finalizar Compra" line={false}>
        <div className="row justify-content-center">
          <h1>dados de pagamento...</h1>
        </div>

        <div className="row justify-content-end mt-4">
          <ButtonCheckout
            className="btn btn-info btn-lg p-3 col-3"
            onClick={() => handlePurchase()}>
            Finalizar pagamento
          </ButtonCheckout>
        </div>
      </Widget>
    </div>
  )
}

export default Checkout

export const EmptyCheckout = styled.div`
  margin-top: 80px;
  
  p{
    color: #999;
    font-size: 16px;
    margin-top: 30px;
  }


  span {
    color: #999;
    font-size: 14px;

    :hover{
      color: #303030
    }
  }

`

export const TableCheckout = styled.div`
  td {
    border-top: 0 !important;
  }
`

export const TotalCheckout = styled.div`
  span {
    font-size: 18px;
    font-weight: bold;
  }
`
export const ButtonCheckout = styled.div`
  font-size: 18px !important;
`