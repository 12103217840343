import React from 'react'
import { Link } from 'react-router-dom'

function Success(props) {
  return (
    <>
      <div className="col-12 text-center mt-5">
        <h1>Cadastro realizado com sucesso!</h1>
      </div>

      <div className="col-12 text-center mt-5">
        <h2>Seja bem vindo ao EAD Fod@atico</h2>
      </div>

      <div className="col-12 text-center mt-5">
        <p>Em alguns minutos você receberá em seu e-mail os dados para acesso a sua plataforma EAD.</p>
      </div>

      <div className="col-12 text-center mt-5">
        <Link to="#">
          <h3>Enquanto aguarda, veja como é fácil gerenciar seus conteúdos no EAD Fod@tisco</h3>
        </Link>
      </div>
    </>
  )
}

export default Success