export function user(
  state = {
    user: {},
    thumbnail: '',
    permissions: [],
    roles: []
  },
  action
) {
  switch (action.type) {
    case 'SET_LOGGED_USER':
      return Object.assign({}, state, {
        ...action.user
      })
    default:
      return state
  }
}

