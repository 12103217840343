import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import Faqs from './Faqs'

const layouts = ['default-sidebar', 'collapsed-sidebar', 'top-navigation-1']

const Component = compose(
  connect(state => {
    return {
      navigation: state.navigation,
      layout: state.config.layout
    }
  }),
  branch(
    ({ layout }) => layouts.includes(layout),
    renderComponent(Faqs),
    renderNothing
  )
)(Faqs)

export default Component