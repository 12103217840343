import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const indexFaqs = id => {
  const response = new Promise((resolve, reject) => {
    api.get(`faqs`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const saveFaq = data => {
  const response = new Promise((resolve, reject) => {
    api.post(`faqs`, data)
      .then(response => {
        resolve(response.data)
        toastSuccess('Novo registro incluído')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const updateFaq = data => {
  const response = new Promise((resolve, reject) => {
    api.put(`faqs/${data.id}`, data)
      .then(response => {
        resolve(response.data)
        toastSuccess('Registro atualizado com sucesso')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const showFaq = id => {
  const response = new Promise((resolve, reject) => {
    api.get(`faqs/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const deleteFaq = id => {
  const response = new Promise((resolve, reject) => {
    api.delete(`faqs/${id}`)
      .then(() => {
        resolve(null)
        toastSuccess('Item removido com sucesso')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}