import React, { useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import styled from 'styled-components'

import TopicItem from './TopicItem'
import KeepTopic from './KeepTopic'

function Topics(props) {
  const [topics, setTopics] = useState([])
  const [addTopic, setAddTopic] = useState(false)

  useEffect(() => {
    setTopics(props.topics)
  }, [props])

  return (
    <Container>
      <h6>Tópicos</h6>

      <div className="mt-3 table-hover table-borderless">
        <table className="table">
          <tbody>
            {topics.map((topic, i) => (
              <TopicItem
                key={i}
                topic={topic}
                course_id={props.course_id}
                updateCoursePage={() => props.updateCoursePage()} />
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-3 p-0 mb-5">
        <button
          className="btn btn-lg btn-info"
          onClick={() => { setAddTopic(true) }}>
          <IoMdAdd /> Tópico
        </button>
      </div>

      <KeepTopic
        topic={null}
        showModal={addTopic}
        hideModal={() => setAddTopic(false)}
        course_id={props.course_id}
        updateCoursePage={() => props.updateCoursePage()} />
    </Container >
  )
}

Topics.defaultProps = {
  topics: Array,
  course_id: String,
  updateCoursePage: Function
}

export default Topics

export const Container = styled.div`
  margin-top: 20px;

  h6 {
    font-size: 1.05rem;
    font-weight: bold;
  }
`