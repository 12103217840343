import React, { useCallback, useRef, useState } from 'react'
import { Form } from '@unform/web'
import ReactTooltip from 'react-tooltip'
import InputFilter from '../../../components/Form/input/InputFilter'

const SearchUser = (props) => {
  const formRef = useRef(null)
  const [viewButton, setViewButton] = useState(false)

  const onChangeName = useCallback(e => {
    let params = { value: '', type: 'name' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
      setViewButton(true)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
      setViewButton(false)
    }
  }, [props])

  const onChangeUsername = useCallback(e => {
    let params = { value: '', type: 'username' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
      setViewButton(true)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
      setViewButton(false)
    }
  }, [props])

  const onChangeEmail = useCallback(e => {
    let params = { value: '', type: 'email' }
    if (e.target.value.length >= 3) {
      params.value = e.target.value
      props.filter(params)
      setViewButton(true)
    } else if (e.target.value.length === 0) {
      params.value = ''
      props.filter(params)
      setViewButton(false)
    }
  }, [props])

  const clearFilter = useCallback(() => {
    props.filter({ type: 'clear' })
    formRef.current.setFieldValue('username', '')
    formRef.current.setFieldValue('name', '')
    formRef.current.setFieldValue('email', '')
    setViewButton(false)
  }, [props])

  return (
    <Form
      ref={formRef}
      noValidate>
      <div className="row">
        <div className="col-sm mb-2">
          <InputFilter
            name="name"
            onChange={onChangeName}
            placeholder="Filtrar por nome" />
        </div>

        <div className="col-sm mb-2">
          <InputFilter
            name="email"
            onChange={onChangeEmail}
            placeholder="Filtrar por e-mail" />
        </div>

        <div className="col-sm mb-2">
          <InputFilter name="username"
            onChange={onChangeUsername}
            placeholder="Filtrar por usuário" />
        </div>

        {viewButton && (
          <div className="col-sm mb-2 col-md-1 text-right btn-clear">
            <button
              type="button"
              className="btn border-danger btn-lg btn-block"
              onClick={clearFilter}
              data-tip='' data-for="clear">X</button>
            <ReactTooltip id='clear'>Limpar filtro</ReactTooltip>
          </div>
        )}
      </div>
    </Form >
  )
}

SearchUser.defaultProps = {
  filter: Function
}

export default SearchUser