import React, { Component } from 'react'
import BannerTop from './BannerTop'
import Areas from './Areas'
import Contact from '../../site/shared/ContactAdmin'

class Home extends Component {
  render() {
    return (
      <div className="mb-5" style={{ marginTop: '60px' }}>
        <BannerTop />

        <Areas />

        <Contact />
      </div>
    )
  }
}

export default Home
