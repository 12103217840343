import styled from 'styled-components';
import bgDesk from '../../assets/images/bg_campanha_escola.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -90px;
  margin-left: -20px;
  margin-right: -20px;
  background-image: url(${bgDesk});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 736px) {
    margin-top: 0;
  }
`;

export const Cadastro = styled.div`
  flex-grow: 1; /* Permite que o Cadastro ocupe o espaço disponível */

  h1 {
    font-weight: bold;
    color: #f9f9f7;
    font-size: 46px;
    margin-top: 80px;
  }

  b {
    color: #000;
  }

  span {
    color: #d1434d;
    font-size: 46px;
    font-weight: bold;
  }

  p {
    color: #fff;
    font-size: 22px;
  }

  .alerta {
    background-color: #641b4d !important;
    font-size: 20px !important;
    border: none;
    color: #fff;
    padding: 20px;
    margin-top: 30px;

    b {
      color: #999;
      font-weight: 500;
    }
  }

  @media (max-width: 736px) {
    h1,
    span {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }

    .alerta {
      margin-bottom: 50px;
    }
  }
`;

export const Footer = styled.footer`
  background-color: #fff;
  padding: 10px;
  opacity: 0.5;
  margin-top: auto;

  span {
    color: #d1434d;
  }

  img {
    height: 50px;
  }
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: auto;

  span {
    color: #fff;
  }
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: auto;
  color: #fff;

  h1 {
    font-weight: bold;
    color: #d1434d;
    margin-bottom: 50px;
  }

  p {
    font-size: 18px;
  }

  .contato {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
`;
