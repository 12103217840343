import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import TableAreas from './TableAreas'
import { indexAreas } from '../../../actions/area'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

const actions = [{
  name: 'Nova Área',
  class_btn: 'btn-info',
  class_collumn: 'col col-md-2',
  route: '/areas/new'
}]

const ListArea = (props) => {
  const [areas, setAreas] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const loadAreas = useCallback(() => {
    indexAreas().then(response => {
      setAreas(response)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    let isSubscribed = true

    if (isSubscribed) {
      dispatch(setBreadcrumb([{ page: 'Áreas', url: '#' }]))
      setLoading(true)
      loadAreas()
    }

    return () => (isSubscribed = false)
  }, [loadAreas, dispatch])

  return (
    <Widget title="Áreas" actions={actions} line={false}>
      <TableAreas
        data={areas}
        loading={loading}
        setLoading={setLoading}
        loadAreas={loadAreas} />
    </Widget>
  )
}

export default ListArea