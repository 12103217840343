import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { FaRegFileVideo, FaRegFileImage, FaRegFileAlt, FaExternalLinkAlt } from 'react-icons/fa'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import ClipLoader from "react-spinners/ClipLoader";
import UploadURL from "./UploadURL";

function UploadFile(props) {
  const [file, setFile] = useState({ preview: '', raw: '' });
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const changeFile = useCallback((e, type) => {
    if (e.target.files.length) {
      setFile({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        type: type,
        name: e.target.files[0].name
      })

      if (type === 'video') setTitle('Vídeo')
      if (type === 'image') setTitle('Imagem')
      if (type === 'other') setTitle('Arquivo')

      props.isUploadingFile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const submit = useCallback((data) => {
    setLoading(true)

    let formData = new FormData()
    formData.append('file', file.raw)
    formData.append('lesson_id', props.lesson_id)

    api.post(`${file.type}-files`, formData)
      .then(() => {
        toastSuccess(`${title} inserido com sucesso!`)
        if (title === 'Vídeo') toastSuccess(`Aguarde até o vídeo ser completamente tratado!`)
        setFile({ preview: '', raw: '' })
        setLoading(false)
        props.isUploadingFile(false)
        props.updateLessonPage()
      })
      .catch(error => {
        toastError(handleError(error))
        setFile({ preview: '', raw: '' })
        setLoading(false)
      })
  }, [props, file, title])

  const cancelUpload = () => {
    setFile({ preview: '', raw: '' })
    setLoading(false)
    props.isUploadingFile(false)
    props.updateLessonPage()
    setTitle(null)
  }

  return (
    <>
      <div className="mt-4 row justify-content-between">
        <div className="d-flex col-sm p-0">
          <div className="col-6 mt-1">
            <button className="btn btn-lg btn-block border-info btn-file">
              <label htmlFor="video" className="text-info"><FaRegFileVideo /> Add vídeo</label>
              <input
                id="video"
                name="video"
                type="file"
                accept="video/*"
                onClick={e => e.target.value = null}
                onChange={e => changeFile(e, 'video')} />
            </button>
          </div>

          <div className="col-6 mt-1">
            <button className="btn btn-lg btn-block border-info btn-file">
              <label htmlFor="image" className="text-info"><FaRegFileImage /> Add imagem</label>
              <input
                id="image"
                name="image"
                type="file"
                accept="image/*"
                onClick={e => e.target.value = null}
                onChange={e => changeFile(e, 'image')} />
            </button>
          </div>
        </div>

        <div className="d-flex col-sm p-0">
          <div className="col-6 mt-1">
            <button className="btn btn-lg btn-block border-info btn-file">
              <label htmlFor="other" className="text-info"><FaRegFileAlt /> Add arquivo</label>
              <input
                id="other"
                type="file"
                name="other"
                onClick={e => e.target.value = null}
                onChange={e => changeFile(e, 'other')} />
            </button>
          </div>

          <div className="col-6 mt-1">
            <button className="btn btn-lg btn-block border-info btn-file"
              onClick={() => {
                setFile({ preview: {}, type: 'link' });
                props.isUploadingFile(true)
              }}>
              <label className="text-info"><FaExternalLinkAlt /> Add link</label>
            </button>
          </div>
        </div>
      </div>

      {file.preview && (
        <PreviewUpload >
          {loading && (
            <div className="text-center mt-3">
              <ClipLoader size={150} color={"#0288D1"} />
              <div>
                <label className="loading text-info">salvando arquivo...</label>
              </div>
            </div>
          )}

          {(!loading && file.preview) && (
            <>
              <div className="text-center">
                {file.type === 'link' && (
                  <UploadURL
                    lesson_id={props.lesson_id}
                    cancelUpload={() => cancelUpload()}
                    updateLessonPage={() => props.updateLessonPage()} />
                )}

                {file.type === 'video' && (
                  <video src={file.preview} className="img-fluid mt-2" />
                )}

                {file.type === 'image' && (
                  <img src={file.preview} className="img-fluid mt-2" alt="Imagem" />
                )}

                {file.type === 'other' && (
                  <div className="text-center">
                    <FaRegFileAlt className="text-primary mt-3 mb-2" size={100} />
                  </div>
                )}
                <p>{file.name}</p>
              </div>

              {file.type !== 'link' && (
                <div className="d-flex col-12 justify-content-center mt-2">
                  <div className="col-sm col-md-2 pl-0">
                    <button onClick={() => cancelUpload()}
                      className="btn btn-lg btn-block border-danger">
                      Cancelar
                    </button>
                  </div>

                  <div className="col-sm col-md-2 pr-0">
                    <button onClick={() => submit()} className="btn btn-lg btn-block btn-info">
                      Upload {title}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </PreviewUpload>
      )}
    </>
  )
}

UploadFile.defaultProps = {
  lesson_id: String,
  updateLessonPage: Function,
  isUploadingFile: Function
}

export default UploadFile

export const PreviewUpload = styled.div`
  margin-top: 20px;
  background-color: #F0F0F0;
  padding: 20px;

  img {
    max-width: 400px;
    max-height: 300px;
  }
 
  video {
    max-width: 400px;
  }

  .loading {
    font-style: italic;
  }
`