import React, { useCallback, useRef, useState } from 'react'
import { Form } from '@unform/web'
import ReactTooltip from 'react-tooltip'
import SelectLabel from '../../../components/Form/input/SelectLabel'

const FormSearch = (props) => {
  const formRef = useRef(null)
  const [viewButton, setViewButton] = useState(false)

  const onChangeCourse = useCallback(e => {
    let params = { value: '', type: 'course_id' }
    params.value = e.value
    setViewButton(true)
    props.filter(params)
  }, [props])

  const clearFilter = useCallback(() => {
    props.filter({ type: 'clear' })
    formRef.current.setFieldValue('course_id', '')
    setViewButton(false)
  }, [props])

  return (
    <Form
      ref={formRef}
      onSubmit={() => { }}
      noValidate>
      <div className="d-flex no-gutters">
        <div className="col-sm-8 col-md-6 mb-2">
          <SelectLabel
            name="course_id"
            onChange={onChangeCourse}
            options={props.courses}
            placeholder="Filtrar por curso" />
        </div>

        {viewButton && (
          <div className="col mt-3 ml-2 btn-clear">
            <button
              type="button"
              className="btn border-danger btn-lg"
              onClick={clearFilter}
              data-tip='' data-for="clear">X</button>
            <ReactTooltip id='clear'>Limpar filtro</ReactTooltip>
          </div>
        )}
      </div>
    </Form >
  )
}

FormSearch.defaultProps = {
  filter: Function,
  courses: Array
}

export default FormSearch