import React from 'react'
import { FaRegFileVideo, FaRegFileImage, FaRegFileAlt, FaExternalLinkAlt } from 'react-icons/fa'
import api from './api'
import { toastError } from './toast'
import { handleError } from './handle-error'

const createBlob = data => {
  const buffer = Buffer.from(data, 'base64');
  const blob = new Blob([buffer]);
  const imageUrl = URL.createObjectURL(blob);

  return imageUrl
}

export const loadImage = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`image-files/${id}`, { responseType: 'arraybuffer' })
      .then(result => {
        resolve(createBlob(result.data))
      })
      .catch(error => toastError(handleError(error)))
  })

  return result
}

export const loadVideoThumbnail = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`video-files/${id}/thumbnail/360`, { responseType: 'arraybuffer' })
      .then(result => {
        resolve(createBlob(result.data))
      })
      .catch(error => toastError(handleError(error)))
  })

  return result
}

export const loadPdfThumbnail = () => {
  const result = new Promise((resolve, reject) => {
    resolve('/assets/images/pdf.jpg')
  })

  return result
}

export const loadUrlThumbnail = () => {
  const result = new Promise((resolve, reject) => {
    resolve('/assets/images/link.jpeg')
  })

  return result
}

export const loadFileThumbnail = () => {
  const result = new Promise((resolve, reject) => {
    resolve('/assets/images/file.png')
  })

  return result
}

export const loadIcon = (item) => {
  if (item.type === 'video') return <FaRegFileVideo size={16} style={{ 'marginTop': '2px', 'marginRight': '5px' }} />
  if (item.type === 'image') return <FaRegFileImage size={14} style={{ 'marginTop': '2px', 'marginRight': '5px' }} />
  if (item.type === 'application') return <FaRegFileAlt size={14} style={{ 'marginTop': '2px', 'marginRight': '5px' }} />
  if (item.type === 'url') return <FaExternalLinkAlt size={14} style={{ 'marginTop': '2px', 'marginRight': '5px' }} />
}

export const loadPdfFile = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`other-files/${id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const file = new Blob([result.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        resolve(fileURL)
      }).catch(error => handleError(toastError(error)))
  })

  return result
}

export const loadOtherFile = id => {
  const result = new Promise((resolve, reject) => {
    api.get(`other-files/${id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const file = new Blob([result.data])

        resolve(file)
      }).catch(error => handleError(toastError(error)))
  })

  return result
}

export const postProgress = async progress => {
  const result = new Promise((resolve, reject) => {
    api.post(`progress`, progress)
      .then(() => {
        resolve()
      }).catch(error => reject(toastError(handleError(error))))
  })

  return result

}

export const deleteProgress = async progress_id => {
  const result = new Promise((resolve, reject) => {
    api.delete(`progress/${progress_id}`)
      .then(() => {
        resolve()
      }).catch(error => reject(toastError(handleError(error))))
  })

  return result
}


