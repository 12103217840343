import React, { useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Form } from '@unform/web'
import api from '../../../actions/api'
import axios from 'axios'
import { toastSuccess } from '../../../actions/toast'
import InputLabel from '../../../components/Form/input/InputLabel'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'

function BannerTop(props) {
  const formRef = useRef(null)
  const [editing, setEditing] = useState(false)
  const [layout, setLayout] = useState({})

  const submit = useCallback(async data => {
    const _layout = {
      "name": "banner",
      "title": data.title,
      "title_font_size": 26,
      "title_font_color": data.title_font_color,
      "description": data.description,
      "description_font_size": 18,
      "description_font_color": data.description_font_color,
      "font_family": "Helvetica",
      "background_color": data.background_color,
      "image": null
    }

    await api.post('layout-banners', _layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Banner atualizado!')
      })
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()
    api.get('layout-banners')
      .then(layout => {
        setLayout(layout.data)
      })
    return () => source.cancel()
  }, [])

  return (
    <div>
      <Container layout={layout}>
        {!editing && (
          <div className="row justify-content-center">
            <div className="col-12 mt-5 text-center">
              <h1>{layout.title}</h1>
            </div>
            <div className="col mb-5 text-center">
              <h5>{layout.description}</h5>
            </div>
          </div>
        )}

        {editing && (
          <Form
            ref={formRef}
            initialData={layout}
            onSubmit={submit}
            noValidate>
            <div className="row col pr-0 pl-3">
              <div className="col-12 mt-5 d-flex pr-0">
                <InputLabel name="title" label="Título" />
                <InputColorLabel
                  name="title_font_color"
                  label="Cor Fonte"
                  defaultValue={layout.title_font_color} />
              </div>

              <div className="col-12 d-flex pr-0">
                <InputLabel name="description" label="Descrição" />
                <InputColorLabel
                  name="description_font_color"
                  label="Cor Fonte"
                  defaultValue={layout.description_font_color} />
              </div>

              <div className="col-12 p-0">
                <InputColorLabel
                  name="background_color"
                  label="Cor Fundo"
                  setColor={() => setLayout({ ...layout, background_color: formRef.current.getFieldValue('background_color') })}
                  defaultValue={layout.background_color} />
              </div>

              <div className="col-12 text-center mb-3">
                <button type="submit" className="btn btn-lg btn-info">
                  Atualizar
                </button>
                <button type="button"
                  className="btn btn-lg btn-warning m-2"
                  onClick={() => setEditing(false)}>
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}

        {!editing && (
          <div className="col-12 text-center">
            <button
              className="btn btn-info mb-3"
              onClick={() => setEditing(true)}>
              Editar Banner
            </button>
          </div>
        )}
      </Container>
    </div>
  )
}

BannerTop.defaultProps = {
  initialData: Object
}

export default BannerTop

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  margin-top: -15px !important;
  margin-left: -20px !important;
  margin-right: -20px !important; 
  background-color: ${props => props.layout.background_color};
  padding: 0;
  min-height: 150px
  
  h1{
    color: ${props => props.layout.title_font_color};
    font-size: 26px;
    font-weight: bold;
  }
  
  h5{
    color: ${props => props.layout.description_font_color};
    font-size: 17px;
  }

  label {
    color: ${props => props.layout.title_font_color};
  }
`