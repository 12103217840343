import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loading = () => {
  return (
    <div>
      <Spinner />
    </div>
  );
}

export default Loading;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 4px solid grey;
  border-right: 4px solid grey;
  border-bottom: 4px solid grey;
  border-left: 6px solid #DC3545;
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;