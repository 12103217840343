import React from 'react'
import Button from '../../components/Form/button/Button'
import { Link } from 'react-router-dom'
import { Container } from './styles'

function ResetSuccess(props) {
  return (
    <Container className="container col-md-5">
      <div className="row justify-content-center pt-5">
        <div className="form-group col-12 text-center mt-5">
          <h1>Senha alterada!</h1>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <h5 className="mt-5 mb-2">Sua senha foi altera com sucesso!</h5>
          <h5>Tente logar novamente</h5>
        </div>
      </div>

      <div className="col-sm mt-5 text-center mb-2">
        <Link to={`/login`} >
          <Button
            type="button"
            template="button1"
            bgColor="#00FFA0"
            color="#4e4e4e"
            className="btn btn-lg col-7 font-weight-bolder"
            label="Login" />
        </Link>
      </div>
    </Container >
  )
}
ResetSuccess.defaultProps = {
  submit: Function,
  formRef: Function
}

export default ResetSuccess