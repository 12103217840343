import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { store } from '../../../../actions/user'
import Widget from '../../../../components/widget'
import FormUser from './FormUser'

function NewUser(props) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const createUser = useCallback(async (data) => {
    store(data)
      .then(user => props.history.push(`/users/${user.id}`))
  }, [props])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        username: Yup.string().min(3, 'Usuário mínimo 3 caracteres'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
        role: Yup.string().required('Perfil é obrigatório')
      })

      await schema.validate(data, { abortEarly: false })

      data.email = data.email.toLowerCase()
      data.roles = [data.role]

      createUser(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [createUser])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: 'SET_BREADCRUMB',
      items: [
        { page: 'Usuários', url: '/users' },
        { page: 'Novo', url: '#' },
      ]
    })
  }, [dispatch]);

  return (
    <Widget title="Novo Usuário" >
      <FormUser
        formRef={formRef}
        submit={submit}
      />
    </Widget >
  )
}

export default NewUser