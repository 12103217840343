import React, { useCallback, useState } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function DeleteResource(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteResource = useCallback(async id => {
    setLoading(true)

    await api.delete(`resources/${id}`)
      .then(() => {
        toastSuccess(`Recurso excluido!`);
        setShowModal(false)
        props.updateLessonPage(props.id)
        setLoading(false)
      }).catch(error => {
        toastError(handleError(error));
      })
  }, [props])

  return (
    <>
      <Delete onClick={() => {
        setShowModal(true)
      }}>
        {loading ? (
          <ClipLoader css={override} size={20} color={"#123abc"} />
        ) : (
            <>
              <MdDeleteForever className="text-danger" size={props.size} style={{ marginBottom: '4px' }} /> {props.label}
            </>
          )}
      </Delete>

      <Modal
        isOpen={showModal}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader >Deletar Recurso</ModalHeader>
        <ModalBody>
          Deseja excluir o recurso {props.name}?
        </ModalBody>
        <ModalFooter>
          <button className="btn border-danger" onClick={() => setShowModal(false)}>
            Cancelar
          </button>
          <button className="btn btn-info col-3" onClick={() => deleteResource(props.id)}>
            Sim
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}
DeleteResource.defaultProps = {
  id: String,
  name: String,
  cancelUpload: Function,
  updateLessonPage: Function,
}

export default DeleteResource

export const Delete = styled.div`
  font-size: 12px;
  margin-left: -10px;

  :hover {
    color: #ab2424 !important;
  }

  svg:hover {
    /* width: 35px !important;
    height: 35px !important; */
    color: #ab2424 !important;
    cursor: pointer;
    position: relative;
    z-index: 999;
  }
`