import React from 'react'
import { Form } from '@unform/web'
import Input from '../../components/Form/input/Input'
import Button from '../../components/Form/button/Button'
import { Container } from './styles'

function FormForgot(props) {
  return (
    <Container className="container">
      <div className="row justify-content-center pt-5">
        <div className="form-group col-12 text-center mt-5">
          <h1>Recuperar senha</h1>
          <h5 className="mt-2">Entre com seu e-mail</h5>
        </div>

        <div className="col-12 col-md-5 text-center mt-4">
          <Form className="form-group" ref={props.formRef} onSubmit={props.submit} noValidate>
            <div className="input-group mt-4">
              <Input type="email" name="email" placeholder="E-mail" />
            </div>

            <div className="mt-5 text-center">
              <Button
                type="submit"
                template="button1"
                bgColor="#00FFA0"
                color="#4e4e4e"
                className="btn btn-lg col-7 font-weight-bolder"
                label="Enviar" />
            </div>
          </Form>
        </div>
      </div>
    </Container >
  )
}
FormForgot.defaultProps = {
  submit: Function,
  formRef: Function
}

export default FormForgot