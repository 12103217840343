import React, { useEffect, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import InputLabel from '../../../components/Form/input/InputLabel';
import InputMaskLabel from '../../../components/Form/input/InputMaskLabel';
import InputCurrencyLabel from '../../../components/Form/input/InputCurrencyLabel';
import TextAreaLabel from '../../../components/Form/input/TextAreaLabel';
import SelectLabel from '../../../components/Form/input/SelectLabel';
import { stringToTime } from '../../../actions/util';
import { indexAreas } from '../../../actions/area';

function FormCourse(props) {
  const [options, setOptions] = useState([]);
  const optionsStatus = [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
  ];
  const [isUpdate, setIsUpdate] = useState(false);

  const getOptionAreas = useCallback(async () => {
    indexAreas().then(response => {
      const options = response.map(item => {
        return { label: item.name, value: item.id };
      });
      setOptions(options);
    });
  }, []);

  useEffect(() => {
    if (options.length === 0) getOptionAreas();

    if (props.initialData.id && !isUpdate) {
      props.formRef.current.setFieldValue(
        'area_id',
        options.find(option => option.value === props.initialData.area_id)
      );

      props.formRef.current.setFieldValue(
        'status',
        optionsStatus.find(option => option.value === props.initialData.status)
      );

      props.formRef.current.setFieldValue(
        'duration',
        stringToTime(props.initialData.duration)
      );

      setTimeout(() => {
        setIsUpdate(true);
      }, 200);
    }

    if (props.area_id && !isUpdate) {
      props.formRef.current.setFieldValue(
        'area_id',
        options.find(option => option.value === props.area_id)
      );

      setTimeout(() => {
        setIsUpdate(true);
      }, 200);
    }
  }, [props, getOptionAreas, options, isUpdate, optionsStatus]);

  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate
    >
      <div className="row">
        <div className="col-sm col-md-6">
          <SelectLabel
            name="area_id"
            label="* Área"
            placeholder="Selecione.."
            options={options}
          />
        </div>

        {props.initialData.id && (
          <div className="col-sm col-md-6">
            <SelectLabel
              name="status"
              label="* Status do curso"
              placeholder="Selecione.."
              options={optionsStatus}
              isDisabled={!props.canActivateCourse}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-sm col-md-6">
          <InputLabel name="name" label="* Nome" />
        </div>

        <div className="col-6 col-md-3">
          <InputMaskLabel
            mask="999:99"
            name="duration"
            label="* Duração H/mm"
          />
        </div>

        <div className="col-6 col-md-3">
          <InputCurrencyLabel
            name="price"
            label="* Valor"
            defaultValue={props.initialData.price}
          />
        </div>
      </div>

      <TextAreaLabel
        name="requirements"
        label="Pré-requisitos"
        rows="3"
        placeholder="Informe os conhecimentos necessários para o aluno realizar esse curso!"
      />

      <TextAreaLabel
        name="description"
        label="Descrição"
        rows="3"
        viewCount={true}
        placeholder={`Informe as vantagens, conhecimentos adquiridos e o que for importante relacionado ao curso!`}
      />

      <div className="mt-4 d-flex justify-content-end">
        <div
          className={
            props.initialData.id
              ? 'col-sm col-md-3 pl-0'
              : 'col-sm col-md-2 pl-0'
          }
        >
          <Link to="/courses">
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
            </button>
          </Link>
        </div>

        <div
          className={
            props.initialData.id
              ? 'col-sm col-md-3 pr-0'
              : 'col-sm col-md-2 pr-0'
          }
        >
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  );
}

FormCourse.defaultProps = {
  submit: Function,
  submitTopic: Function,
  formRef: Function,
  initialData: Object,
};

export default FormCourse;
