import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../actions/api'
import { toastSuccess } from '../../../actions/toast'
import { Form } from '@unform/web'
import InputLabel from '../../../components/Form/input/InputLabel'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function Content(props) {
  const [layout, setLayout] = useState({})
  const [course, setCourse] = useState({})
  const [editing, setEditing] = useState(false)
  const formRef = useRef(null)
  const optionsButton = [
    { label: 'Button 1', value: 'button1' },
    { label: 'Button 2', value: 'button2' },
    { label: 'Button 3', value: 'button3' },
    { label: 'Button 4', value: 'button4' },
    { label: 'Button 5', value: 'button5' },
    { label: 'Button 6', value: 'button6' },
  ]

  const submit = useCallback(async data => {
    layout.about_title = data.about_title
    layout.about_title_color = data.about_title_color
    layout.content_title = data.content_title
    layout.content_title_color = data.content_title_color
    layout.content_text_color = data.content_text_color
    layout.button_name = data.button_name

    await api.post('layout-course-details', layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Cursos atualizado!')
      })
  }, [layout])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.course) setCourse(props.course)
  }, [props])

  return (
    <Form
      ref={formRef}
      initialData={layout}
      onSubmit={submit}
      noValidate>

      <Container className="mt-4 mb-4" layout={layout}>
        <div className="row">
          <div className="col-2 mt-4">
            <LeftBar color={layout.banner_background_color} />
          </div>
          <div className="col-10 col-md-6">
            {!editing ? (
              <>
                <h1>{layout.about_title}</h1>
                <p>{course.description}</p>
              </>
            ) : (
                <>
                  <div className="col-12 d-flex pr-0 text-left">
                    <InputLabel name="about_title" label="Alterar título" />
                    <div className="col-4">
                      <InputColorLabel
                        name="about_title_color"
                        label="Alterar cor da fonte"
                        height="40px"
                        defaultValue={layout.about_title_color} />
                    </div>
                  </div>

                  <div className="ml-3 mt-2">
                    <h6>Descrição</h6>
                    Para alterar a descrição do curso,
                    vá para a página de
                      <Link to={`/courses`}> <b>Cursos.</b></Link>
                  </div>
                </>
              )}
          </div>
        </div>

        <div className="d-flex">
          <ContentCourse layout={layout} className="col-12 col-md-8">
            {!editing ? (
              <div>
                <h2>{layout.content_title}</h2>

                <ul className="ml-5 mt-3">
                  {course.topics && course.topics.map((topic, i) => (
                    <li key={i}>
                      <span className="mr-2"> {topic.number}</span>
                      {topic.name}

                      <Subtopics>
                        {topic.topics && topic.topics.map((subtopic, x) => (
                          <li key={x}>
                            <span className="mr-2"> {subtopic.number}</span>
                            {subtopic.name}
                          </li>
                        ))}
                      </Subtopics>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
                <>
                  <div className="col-12 d-flex pr-0 text-left">
                    <div className="col-8">
                      <InputLabel name="content_title" label="Alterar título" />
                    </div>
                    <div className="col-4">
                      <InputColorLabel
                        name="content_title_color"
                        label="Alterar cor da fonte"
                        height="40px"
                        defaultValue={layout.content_title_color} />
                    </div>
                  </div>

                  <div className="col-12 d-flex mt-2">
                    <div className="col-8">
                      <h6>Conteúdo</h6>
                        Para alterar o conteúdo do curso,
                        vá para a página de
                        <Link to={`/courses`}> <b>Cursos.</b></Link>
                    </div>

                    <div className="col-4 pl-3">
                      <InputColorLabel
                        name="content_text_color"
                        label="Alterar cor do conteúdo"
                        height="40px"
                        defaultValue={layout.content_text_color} />
                    </div>
                  </div>
                </>
              )}
          </ContentCourse>
          <div className="col-2 col-md-4">
            <RightBar color={layout.banner_background_color} />
          </div>
        </div>


        {editing && (
          <>
            <div className="row justify-content-center">
              <div className="col-sm col-md-6 text-left mb-3">
                <SelectLabel
                  name="button_name"
                  label="Modelo cartão"
                  onChange={e => setLayout({ ...layout, button_name: e.value })}
                  defaultValue={layout.button_name}
                  placeholder="Selecione.."
                  options={optionsButton}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="d-flex col-sm col-md-6 text-left mb-3">
                <InputColorLabel
                  name="button_background_color"
                  label="Cor Botão"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    button_background_color: formRef.current.getFieldValue('button_background_color')
                  })}
                  defaultValue={layout.button_background_color} />

                <InputColorLabel
                  name="button_background_color_hover"
                  label="Cor Botão (hover)"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    button_background_color_hover: formRef.current.getFieldValue('button_background_color_hover')
                  })}
                  defaultValue={layout.button_background_color_hover} />

                <InputColorLabel
                  name="button_text_color"
                  label="Cor Texto"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    button_text_color: formRef.current.getFieldValue('button_text_color')
                  })}
                  defaultValue={layout.button_text_color} />

                <InputColorLabel
                  name="button_text_color_hover"
                  label="Cor Texto (hover)"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    button_text_color_hover: formRef.current.getFieldValue('button_text_color_hover')
                  })}
                  defaultValue={layout.button_text_color_hover} />

              </div>
            </div>
          </>
        )}
        <div className="row mt-4 justify-content-center">
          <div className="col-sm col-md-8 text-center">
            <Button type="button" layout={layout} className="btn btn-info btn-lg btn-block">
              ADICIONAR AO CARRINHO
            </Button>
          </div>
        </div>

        {!editing ? (
          <div className="col-12 text-center mt-3">
            <Link
              to="#"
              className="btn btn-info mb-3"
              onClick={() => setEditing(true)}>
              Editar Layout
            </Link>
          </div>
        ) : (
            <div className="col-12 text-center mb-3">
              <button type="submit" className="btn btn-lg btn-info">
                Atualizar
              </button>
              <button type="button"
                className="btn btn-lg btn-warning m-2"
                onClick={() => {
                  setEditing(false)
                }}>
                Cancelar
              </button>
            </div>
          )}
      </Container>
    </Form>
  )
}

Content.defaultProps = {
  course: Object,
  layout: Object,
  course_content: Array
}

export default Content

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  h1{
    font-size: 30px;
    color: ${props => props.layout.about_title_color};
    font-weight: bold;
    text-transform:  uppercase;
  }

  p{
    font-size: 18px;
    color: ${props => props.layout.about_text_color}
  }
`

export const ContentCourse = styled.div`
  background-color: ${props => props.layout.content_background_color};
  margin-left: -20px;
  padding: 20px;
  margin-top: 10px;

  h2{
    margin-left: 80px;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    color: ${props => props.layout.content_title_color};
  }

  li {
    color: ${props => props.layout.content_text_color};
    font-size: 16px;
    list-style-type: none;
  }
`

export const LeftBar = styled.div`
  background: ${props => props.color};
  height: 60%;
  margin-top: 20px;
  margin-left: -20px;
`

export const RightBar = styled.div`
  background: ${props => props.color};
  height: 70px;
  width: 120%;
  margin-right: -50px;
  margin-top: 50px;
  float: right;

  @media (max-width: 499px) {
    margin-right: 0;
    width: 140%;
  }
  
  @media (max-width: 399px) {
    width: 180%;
  }
`
export const Button = styled.button`
  background: ${props => props.layout.button_background_color} !important;
  color: ${props => props.layout.button_text_color} !important;
  border: none !important;
  border-radius: ${props => {
    switch (props.layout.button_name) {
      case 'button1':
        return 'none'

      case 'button2':
        return '24px'

      case 'button3':
        return '24px 0 24px 24px'

      case 'button4':
        return '24px 24px 24px 0'

      case 'button5':
        return '0 24px 24px 24px'

      default:
        return 'none'
    }
  }};

  :hover{
    background: ${props => props.layout.button_background_color_hover} !important;
    color: ${props => props.layout.button_text_color_hover} !important;
  }
`

export const Subtopics = styled.ul`
  margin: 2px 10px 10px -20px;
`