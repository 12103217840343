import styled from 'styled-components'

export const Container = styled.div`
  h1{
    color: #4E4E4E;
    font-weight: bold;
  }

  input {
    background: #EBEBEB;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: #707070;
    font-size: 16px;
  }

  label {
    color: #4d4d4d !important;
  }
  
`