import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import App from './App';
import { TenantProvider } from './contexts/TenantContext';

const store = configureStore();

const Component = () => (
  <Provider store={store}>
    <TenantProvider>
      <App />
    </TenantProvider>
  </Provider>
);

let render = () => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};
render();
if (module.hot) {
  module.hot.accept(Component => {
    render();
  });
}
