import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  background-color: #F0F0F0;
  padding: 20px;
  margin-right: 10px;

  .image {
    /* border-radius: 100px; */
    max-height: 150px;
  }
`

export const GroupButton = styled.div`
  button {
    background-color: ${props => props.children[0].props.disabled ? `#64b2e2` : `#0288d1`};
    border: none;
    color: white;
    padding: 12px; 
    cursor: pointer; 
    float: left;
  }
  button:first-child {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  button:last-child {
    border-radius: 0 0.3rem 0.3rem 0;
  }
`

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 50%;

  .img-preview {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 50px;
  }
`