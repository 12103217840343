import api from './api'
import { toastError, toastSuccess } from './toast'
import { handleError } from './handle-error'

export const loadTenantContacts = () => {
  const response = new Promise((resolve, reject) => {
    api.get(`tenant-contacts`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const saveTenantContact = contact => {
  const response = new Promise((resolve, reject) => {
    api.post(`tenant-contacts`, contact)
      .then(response => {
        resolve(response.data)
        toastSuccess('Registro incluído!')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}

export const updateTenantContact = contact => {
  const response = new Promise((resolve, reject) => {
    api.put(`tenant-contacts/${contact.id}`, contact)
      .then(response => {
        resolve(response.data)
        toastSuccess('Registro atualizado!')
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}

export const loadTenantContactById = id => {
  const response = new Promise((resolve, reject) => {
    api.get(`tenant-contacts/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      })
  })
  return response
}

export const loadLayoutContacts = () => {
  const response = new Promise((resolve, reject) => {
    api.get(`layout-contacts`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}

export const formatPhone = string => {
  const phone = string.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
  return phone
}
