import React from 'react'
import { Form } from '@unform/web'
import Button from '../../components/Form/button/Button'
import InputLabel from '../../components/Form/input/InputLabel'
import { Container } from './styles'

function FormReset(props) {
  return (
    <Container className="container">
      <div className="row justify-content-center pt-5">
        <div className="form-group col-12 text-center mt-5">
          <h1>Alterar senha</h1>
          <h5 className="mt-3">Por favor, entre com a nova senha</h5>
        </div>

        <div className="col-12 col-md-5 text-center mt-4">
          <Form className="form-group" ref={props.formRef} onSubmit={props.submit} noValidate>
            <div className="input-group mt-4">
              <InputLabel type="password" name="password" label="Senha" />
            </div>

            <div className="input-group mt-4">
              <InputLabel
                type="password"
                name="password_confirmation" label="Confirmar Senha" />
            </div>

            <div className="mt-5 text-center">
              <Button
                type="submit"
                template="button1"
                bgColor="#00FFA0"
                color="#4e4e4e"
                className="btn btn-lg col-7 font-weight-bolder"
                label="Enviar" />
            </div>
          </Form>
        </div>
      </div>
    </Container >
  )
}
FormReset.defaultProps = {
  submit: Function,
  formRef: Function
}

export default FormReset