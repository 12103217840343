import React, { Component } from 'react'
import BannerTop from './BannerTop'
import Areas from './Areas'
import Contact from '../shared/Contact'
import Faq from '../shared/Faq'

class Home extends Component {

  componentDidMount() {
    // NOTE - para deixar o modulo de ALUNO funcionando
    // o sistema não direciona para a Home do site
    // apenas para o login e assim para a pagina de cursos matriculados
    this.props.history.push('/dashboard')
  }

  render() {
    return (
      <div className="mb-5">
        <BannerTop />

        <Areas />

        <Faq />

        <Contact />
      </div>
    )
  }
}

export default Home
