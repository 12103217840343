import React, { Component } from 'react'
import { indexFaqs } from '../../../actions/faq'
import Contact from '../shared/Contact'
import ListFaqs from './ListFaqs'

class Faqs extends Component {

  constructor(props) {
    super()

    this.state = {
      faqs: {},
      layout: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const id = this.props.match.params.id
    indexFaqs(id)
      .then(faqs => this.setState({ faqs }))

    // loadLayoutAreaDetail()
    //   .then(layout => this.setState({ layout }))
    const layout = {
      title: 'Perguntas frequentes',
      title_font_color: '#4E4E4E',
      faq_card_background_color: '#303f9f',
      faq_card_name: 'card1',
      faq_question_color: '#ffffff',
      faq_answer_color: '#ffffff',
      button_label: 'Ver todas as perguntas',
      button_background_color: '#303f9f',
      button_background_color_hover: '#46a8dd',
      button_text_color: '#ffffff',
      button_text_color_hover: '#303f9f',
    }
    this.setState({ layout })
  }

  render() {
    return (
      <div className="mb-5">
        <ListFaqs faqs={this.state.faqs} layout={this.state.layout} />
        <Contact />
      </div>
    )
  }
}

export default Faqs
