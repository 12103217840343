import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles'

export default function TextAreaLabel({ name, label, rows, cols, placeholder, viewCount, ...rest }) {
  const inputRef = useRef(null)
  const [value, setValue] = useState(0)

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container >
      <div className="row justify-content-between m-0 p-0">
        <label className="mt-3 text-left" htmlFor={fieldName}>{label}</label>
        {viewCount && (
          <p className={value > 254 ? 'text-danger' : ''}>{value} / 254</p>
        )}
      </div>

      <textarea
        id={fieldName}
        ref={inputRef}
        rows={rows}
        cols={cols}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={e => setValue(e.target.value.length)}
        className={error ? 'input-error' : ''}
        {...rest}
      />

      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
}