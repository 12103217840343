import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { setConfig } from '../../actions/config'
import { logoff } from '../../actions/auth'
import ModalConfirmAlterPassword from '../profile/ModalAlterPassword'

const ProfileSidebar = (props) => {
  const [user, setUser] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [alterPassword, setAlterPassword] = useState(false)

  const closeSidebar = useCallback(() => {
    props.setConfig('collapsed', false)
  }, [props])

  useEffect(() => {
    if (props.user.id) {
      let user_ = props.user
      user_.name = `${user_.name.lenght > 23 ? user_.name.substr(0, 23) + '...' : user_.name}`
      setUser(user_)
    }
  }, [props])

  return (
    <li>
      <div
        className={
          isOpen
            ? 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children is-open'
            : 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children'
        }
        onClick={() => setIsOpen(!isOpen)}>
        <i className="material-icons" style={{ fontSize: '16px' }}>account_box</i>
        <div className="col text-left pl-1">
          <span className="title">{user.name}</span>
        </div>
      </div>
      <ul className="list-unstyled">
        <MenuLi onClick={() => closeSidebar()}>
          <Link
            to={`/profile/${user.id}`}
            className="btn btn-default btn-flat btn-sidebar btn-sidebar-2">
            <span className="title" onClick={closeSidebar}>Meus dados</span>
          </Link>
        </MenuLi>
        <MenuLi>
          <Link
            to="#"
            onClick={() => setAlterPassword(true)}
            className="btn btn-default btn-flat btn-sidebar btn-sidebar-2">
            <span className="title" onClick={closeSidebar}>Alterar senha</span>
          </Link>
        </MenuLi>
        <MenuLi>
          <Link
            to="/logout"
            onClick={logoff}
            className="btn btn-default btn-flat btn-sidebar btn-sidebar-2">
            <span className="title ml-3" onClick={closeSidebar}>Sair</span>
          </Link>
        </MenuLi>
      </ul>

      <ModalConfirmAlterPassword
        user={user || null}
        isOpen={alterPassword}
        isHide={() => setAlterPassword(false)} />
    </li>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    config: state.config
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setConfig: (key, value) => dispatch(setConfig(key, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar)

export const MenuLi = styled.li`
  a {
    :hover {
      background-color: #DC3545 !important;
    }
  }

  span {
    margin-left: 52px !important;
  }
`
