import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import { loadImageArea } from '../../../actions/area'

function BannerArea(props) {
  const [layout, setLayout] = useState({})
  const [imageArea, setImageArea] = useState('')

  const prepareImage = useCallback(area => {
    if (area.resources.length > 0) {
      loadImageArea(area.resources[0].id)
        .then(thumbnail => {
          setImageArea(thumbnail)
        })
    }
  }, [])

  useEffect(() => {
    if (props.area.id) prepareImage(props.area)
    if (props.layout) setLayout(props.layout)
  }, [props, prepareImage])

  return (
    <Banner backgroundImage={imageArea} layout={layout} area={props.area}>
      <div className="bg-color"></div>
      <div className="text-card col-md-5 text-center p-3">
        <h1>{props.area.name}</h1>

        <p className="mt-2">{props.area.description}</p>
      </div>
    </Banner>
  )
}

BannerArea.defaultProps = {
  area: Object
}

export default BannerArea

export const Banner = styled.div`
  font-family: 'Oswald', sans-serif;
  height: 400px;
  margin-left: -15px;
  margin-right: -20px;
  margin-top: -18px;
  
  background: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  h1 { font-size: 26px; }
  p { 
    font-size: 16px; 
    font-weight: normal;
  }

  .bg-color {
    width: 100%;
    height: 400px;
    background: ${props => props.area.primary_color};
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.5;
  }
  
  .text-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background: ${props => props.layout.banner_background_color};
    font-size: 30px;
    color: ${props => props.layout.banner_text_color};
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
    bottom: 1;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-weight: bold;
      text-transform: uppercase; 
    }
  }
`
