import React, { useEffect, useState } from 'react'
import {
  FaFacebookF, FaInstagram, FaLinkedinIn, FaRegEnvelope, FaWhatsapp
} from 'react-icons/fa'
import { AiOutlineYoutube } from 'react-icons/ai'
import { FiTwitter } from 'react-icons/fi'
import styled from 'styled-components'
import { loadTenantContacts, loadLayoutContacts } from '../../../actions/contact'

function Contact(props) {
  const [layout, setLayout] = useState({})
  const [contacts, setContacts] = useState([])
  const [whatsUrl, setWhatsUrl] = useState('')

  useEffect(() => {
    let isSubscribed = true;

    loadLayoutContacts().then(layout => {
      if (isSubscribed) setLayout(layout)
    })

    return () => (isSubscribed = false)
  }, [])

  useEffect(() => {
    let isSubscribed = true;

    loadTenantContacts().then(contacts => {
      if (isSubscribed) {
        setContacts(contacts)

        let url = ''

        for (let i = 0; i < contacts.length; i++) {
          const _contact = contacts[i];
          if (_contact.name === 'whatsapp') {
            if (window.screen.availWidth > 600) {
              url = `https://web.whatsapp.com/send?text=Olá, gostaria de mais informações&phone=55${_contact.value}`
            } else {
              url = `https://api.whatsapp.com/send?text=Olá, gostaria de mais informações.&phone=55${_contact.value}`
            }
          }
        }

        setWhatsUrl(url)
      }
    })

    return () => (isSubscribed = false)
  }, [])

  return (
    <>
      {contacts.length > 0 && (
        <Container layout={layout}>
          <div className="row text-center">
            <div className="col">
              <h2>{layout.title}</h2>
              <h5>{layout.description}</h5>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            {contacts.map((item, i) => (
              <div key={i} className="col-4 col-md-2 mb-5">
                {item.name === 'whatsapp' && (
                  <a target="_blank" href={whatsUrl} rel="noopener noreferrer">
                    <FaWhatsapp size={50} color="#34af23" className="col" />
                  </a>
                )}

                {item.name === 'email' && (
                  <a target="_blank" href={`mailto: ${item.value}`} rel="noopener noreferrer">
                    <FaRegEnvelope size={50} color="#da4d39" className="col" />
                  </a>
                )}

                <div className="text-center">
                  <a target="_blank" href={item.value} rel="noopener noreferrer">
                    {item.name === 'facebook' && (
                      <FaFacebookF size={45} color="#3b5998" className="col" />
                    )}

                    {item.name === 'instagram' && (
                      <FaInstagram size={50} color="#C13584" className="col" />
                    )}

                    {item.name === 'linkedin' && (
                      <div className="text-center">
                        <FaLinkedinIn size={45} color="#007bb6" className="col" />
                      </div>
                    )}

                    {item.name === 'youtube' && (
                      <AiOutlineYoutube size={50} color="#bb0000" className="col" />
                    )}

                    {item.name === 'twiter' && (
                      <FiTwitter size={45} color="#007bb6" className="col" />
                    )}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </Container >
      )}
    </>
  )
}

export default Contact

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  background-color: ${props => props.layout.background_color};
  padding: 20px;
  margin-left: -20px !important;
  margin-right: -20px !important;

  h2 {
    color: ${props => props.layout.title_font_color};
    font-weight: bold;
    font-size: 22px
  }

  h5{
    font-size: 18px;
    font-style: italic;
    color: ${props => props.layout.description_font_color};
  }
`