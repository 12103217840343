import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { AiOutlineClose, AiOutlineFileExclamation } from 'react-icons/ai'
import { saveAs } from 'file-saver';
import ReactPlayer from "react-player";
import api from '../../actions/api'
import { toastError } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import VideoPlayer from '../../components/video/VideoPlayerAdmin'

function PreviewResource(props) {
  const [previewResource, setPreviewResource] = useState({ url: '', type: '', extname: '', name: '' })

  const getImage = useCallback(async resource => {
    await api.get(`image-files/${resource.id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const buffer = Buffer.from(result.data, 'base64');
        const blob = new Blob([buffer]);
        const imageUrl = URL.createObjectURL(blob);

        setPreviewResource({ url: imageUrl, type: 'image', extname: resource.extname, name: resource.name })
      })
      .catch(error => {
        handleError(toastError(error))
      })
  }, [])

  const getPDF = useCallback(async resource => {
    await api.get(`other-files/${resource.id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const file = new Blob([result.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        setPreviewResource({ url: fileURL, type: 'pdf', extname: 'pdf', name: resource.name })
      })
      .catch(error => {
        handleError(toastError(error))
      })
  }, [])

  const getFile = useCallback(async resource => {
    await api.get(`other-files/${resource.id}`, { responseType: 'arraybuffer' })
      .then(result => {
        const file = new Blob([result.data])

        setPreviewResource({ url: file, type: 'other', extname: resource.extname, name: resource.name })
      })
      .catch(error => {
        handleError(toastError(error))
      })
  }, [])

  const downloadFile = useCallback(file => {
    saveAs(file.url, `${file.name}.${file.extname}`)
  }, [])

  const getTypeMedia = useCallback(() => {
    const dispositive = window.navigator.platform

    if (dispositive === 'iPhone') {
      return 'mp4'
    } else {
      return 'dash'
    }
  }, [])

  const prepareResource = useCallback(resource => {
    if (resource.type === undefined) {
      setPreviewResource({ url: '', type: '', extname: '', name: '' })
    }

    if (resource.type === 'video') {
      setPreviewResource({})

      setTimeout(() => {
        const _type = getTypeMedia()
        let _url = ''

        if (_type === 'mp4') {
          const urlMp4 = resource.url.replace('.mpd', '_720.mp4')
          _url = `${process.env.REACT_APP_API_BASE_URL}${urlMp4}`
        } else {
          _url = `${process.env.REACT_APP_API_BASE_URL}${resource.url}`
        }

        setPreviewResource({
          url: _url,
          type: resource.type,
          media_type: _type,
          extname: resource.extname,
          name: resource.name
        })
      }, 100);
    }

    if (resource.type === 'image') {
      getImage(resource)
    }

    if (resource.subtype === 'pdf') {
      getPDF(resource)
    }

    if (resource.type === 'application' && resource.subtype !== 'pdf') {
      getFile(resource)
    }

    if (resource.type === 'url') {
      setPreviewResource({ url: resource.url, type: 'link' })
    }

    if (resource.type === 'youtube') {
      setPreviewResource({ url: resource.url, type: 'youtube' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFile, getImage, getPDF])

  useEffect(() => {
    if (props.resource) prepareResource(props.resource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resource])

  return (
    <>
      {previewResource.type && (
        <Container className="col-sm text-center mb-5">
          <div className="row bg-header">
            <div className="col m-0 p-2">
              <h6 >{previewResource.name}</h6>
            </div>

            <CloseButton className="justify-content-end m-0 p-2">
              <AiOutlineClose className="close" size={20} onClick={() => props.closePreview()} />
            </CloseButton>
          </div>

          {previewResource.type === 'other' && (
            <div>
              <div className="text-info">
                <div>
                  <AiOutlineFileExclamation size={100} />
                </div>

              </div>

              <h5 className="mt-3">Esse documento não pode ser visualizado.</h5>
              <h6 >Deseja baixar esse documento?</h6>

              <button
                className="btn btn-lg btn-info mt-3"
                onClick={() => downloadFile(previewResource)}>
                Baixar Arquivo
              </button>
            </div>
          )}

          {previewResource.extname === 'pdf' && (
            <div className="col" style={{ 'height': '500px' }}>
              <object data={previewResource.url} type="pplication/pdf">
                <iframe
                  title={previewResource.name}
                  src={previewResource.url}
                  width="100%" height="100%"
                  style={{ 'border': 'none' }} />
              </object>
            </div>
          )}

          {previewResource.type === 'video' && (
            <VideoContainer className="col-12">
              <VideoPlayer
                src={previewResource.url}
                width={85}
                type={previewResource.media_type} />
            </VideoContainer>
          )}

          {previewResource.type === 'youtube' && (

            <VideoContainer className="col-12">
              <ReactPlayer
                url={previewResource.url}
              />
            </VideoContainer>
          )}

          {previewResource.type === 'link' && (
            <div className="col">
              <h4>Link Externo</h4>
              <h6>Deseja abrir esse link em uma noja janela?</h6>
              <a target="_blank" href={`${previewResource.url}`} rel="noopener noreferrer">
                <button className="btn btn-lg btn-info">Abrir Link</button>
              </a>
            </div>
          )}

          {previewResource.type === 'image' && (
            <img src={previewResource.url} className="img-fluid" alt="Imagem" style={{ 'maxHeight': '300px' }} />
          )}
          <div id="preview"></div>
        </Container>
      )}
    </>
  )
}

PreviewResource.defaultProps = {
  resource: Object,
  closePreview: Function
}

export default PreviewResource

export const Container = styled.div`
  padding-bottom: 10px;
  background-color: #E6E6E6 ;
  border-radius: 3px;

  .bg-header {
    margin-top: -20px;
    color: black;
    border-radius: 3px 3px 0 0;
    margin-bottom: 5px;
  }
`

export const VideoContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CloseButton = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  
  .close {
    :hover {
      cursor: pointer;
      color: red !important;
    }
  }
`

export const PreviewUpload = styled.div`
  margin-top: 20px;
  background-color: #F0F0F0;
  padding: 20px;

  img {
    max-width: 400px;
    max-height: 300px;
  }

  video {
    max-width: 400px;
  }

  .loading {
    font-style: italic;
  }
`

export const OtherFile = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
`

//** EXEMPLO COM PDF READER */

// import { PDFReader } from 'reactjs-pdf-reader'

// const [fileUpload, setFileUpload] = useState({})
// const [numPages, setNumPages] = useState(null)
// const [pageNumber, setPageNumber] = useState(1)

// const onDocumentLoadSuccess = ({ numPages }) => {
//   setNumPages(numPages);
// }

// const onDocumentComplete = (totalPage, title, otherObj) => {
//   console.log(otherObj);
// }


// <div className="mb-5">
//   <div className="border rounded" style={{ overflow: 'scroll', width: '100%', height: '600px' }}>
//     <div className="col bg-dark">
//       <button type="button" onClick={() => setPageNumber(pageNumber - 1)}>previus</button>
//       <button type="button" onClick={() => setPageNumber(pageNumber + 1)}>Next</button>
//       <p className="text-white">Pagina: {pageNumber}</p>
//     </div>

//     <PDFReader
//       onDocumentComplete={onDocumentComplete}
//       scale={1}
//       page={pageNumber}
//       url={previewResource.url} />

//     <div className="col bg-dark">
//       <button type="button" onClick={() => setPageNumber(pageNumber - 1)}>previus</button>
//       <button type="button" onClick={() => setPageNumber(pageNumber + 1)}>Next</button>
//       <p className="text-white">Pagina: {pageNumber}</p>
//     </div>
//   </div>
// </div>