import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import FormArea from './FormArea'
import AreaCourses from './AreaCourses'
import Widget from '../../../components/widget'
import CropImage from '../../../components/crop-image/CropImage'
import api from '../../../actions/api'
import { showArea, loadImageArea } from '../../../actions/area'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { setBreadcrumb } from '../../../reducers/breadcrumb'
import { ImageArea } from './styles'

function Area(props) {
  const formRef = useRef(null)
  const [area, setArea] = useState({})
  const [preview, setPreview] = useState('')
  const [changeImage, setChangeImage] = useState(false)
  const [initialData, setInitialData] = useState({})
  const [appendFormData, setAppendFormData] = useState([])
  const dispatch = useDispatch()

  const getImageArea = useCallback(area => {
    if (area.resources.length > 0) {
      const resource = area.resources[0]
      loadImageArea(resource.id)
        .then(image => setPreview(image))
        .catch(() => {
          setPreview('/assets/images/crop.png')
        })
    } else {
      setPreview('/assets/images/crop.png')
    }
  }, [])

  const getArea = useCallback(async (id) => {
    await showArea(id)
      .then(area => {
        if (!formRef.current) return null
        setArea(area)
        setInitialData(area)
        getImageArea(area)

        setAppendFormData([
          { field: 'area_id', value: area.id },
          { field: 'type', value: 'LOGO' },
        ])

        const items = [
          { page: 'Áreas', url: '/areas' },
          { page: area.name, url: '#' },
          { page: 'Editar', url: '#' },
        ]
        dispatch(setBreadcrumb(items))
      }).catch(() => {
        props.history.push('/areas')
      })
  }, [props, getImageArea, dispatch])

  const save = useCallback(async (data) => {
    await api.post('areas', data)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        getArea(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }, [getArea])

  const update = useCallback(async (data) => {
    await api.put(`areas/${area.id}`, data)
      .then(result => {
        toastSuccess(`Registro de ${data.name} atualizado!`);
        getArea(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }, [area, getArea])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        primary_color: Yup.string(),
        second_color: Yup.string(),
        description: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Descrição mínimo 8 caracteres') : Yup.string()
        }),
      })

      await schema.validate(data, { abortEarly: false })

      area.id ? update(data) : save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }

  }, [area, save, update])

  useEffect(() => {
    const id = props.match.params.id
    if (id && Object.entries(area).length === 0) getArea(id)
  }, [props, getArea, area])

  useEffect(() => {
    if (area.id) getArea(area.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeImage])

  return (
    <Widget title={area.id ? `Editar ${area.name}` : 'Nova Área'} >
      <div className="row justify-content-center">
        <div className={area.id ? 'col-sm col-md-8' : 'col-sm'}>
          <FormArea
            initialData={initialData}
            submit={submit}
            formRef={formRef}
          />
        </div>

        {area.id && (
          <ImageArea className="mt-4 text-center col-sm">
            <img
              className="img-fluid image"
              src={preview} alt="preview" />

            <div className="row justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => {
                  setChangeImage(true)
                  window.scrollTo(0, document.body.scrollHeight);
                }}>
                Alterar imagem
              </button>
            </div>
          </ImageArea>
        )}
      </div>

      <div className="row mt-3 mb-5">
        <div className="col-sm">
          <CropImage
            show={changeImage}
            hide={() => setChangeImage(false)}
            appendFormData={appendFormData}
            size="1"
            post_url={`area-image-files`}
            image={preview || null} />
        </div>
      </div>

      {area.id && (
        <AreaCourses
          area_id={initialData.id}
          courses={initialData.courses} />
      )}
    </Widget >
  )
}

export default Area;