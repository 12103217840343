import api from './api'
import { toastError } from './toast'
import { handleError } from './handle-error'

export const loadPurchases = () => {
  const result = new Promise((resolve, reject) => {
    api.get(`purchases`)
      .then(response => {
        if (response.data.length > 0) {
          resolve(response.data)
        } else {
          resolve([])
        }
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const loadPurchaseById = id => {
  const response = new Promise((resolve, reject) => {
    api.get(`purchases/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return response
}