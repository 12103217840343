import React, { useEffect, useState, useCallback } from 'react'
import { Form } from '@unform/web'
import { withRouter } from 'react-router-dom'
import { loadRoles } from '../../../../actions/user'
import InputLabel from '../../../../components/Form/input/InputLabel'
import SelectLabel from '../../../../components/Form/input/SelectLabel'

function FormEditUser(props) {
  const [roles, setRoles] = useState([])

  const getRoles = useCallback(() => {
    loadRoles().then(data => {
      if (!props.formRef.current) return null
      const options = data.map(item => {
        return { label: item.description, value: item.id }
      })
      setRoles(options)
    })
  }, [props, setRoles])

  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      ref={props.formRef}
      initialData={props.user}
      onSubmit={props.submit}
      noValidate>
      <div className="row justify-content-center">
        <div className="col-sm">
          <InputLabel name="name" label="Nome" />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm">
          <InputLabel name="email" label="E-mail" />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm d-flex p-0">
          <div className="col">
            <InputLabel name="username" label="Usuário" />
          </div>

          {!props.user.id && (
            <div className="col">
              <SelectLabel
                name="role"
                label="Perfil"
                placeholder="Selecione.."
                options={roles}
              />
            </div>
          )}
        </div>
      </div>

      {!props.user.id && (
        <div className="row justify-content-center">
          <div className="col-sm d-flex p-0">
            <div className="col">
              <InputLabel type="password" name="password" label="Senha" />
            </div>
            <div className="col">
              <InputLabel type="password" name="confirm_password" label="Confirmar Senha" />
            </div>
          </div>
        </div>
      )}

      <div className="row justify-content-center mt-5">
        <div className="col-sm d-flex justify-content-end p-0">
          <div className="col">
            <button type="button" onClick={() => props.history.goBack()} className="btn btn-lg  btn-block border-info">
              Voltar
            </button>
          </div>
          <div className="col">
            <button type="submit" className="btn btn-lg btn-block btn-info">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </Form >
  )
}

FormEditUser.defaultProps = {
  submit: Function,
  formRef: Function,
  user: Object,
}

export default withRouter(FormEditUser)