import React, { useEffect, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Form } from '@unform/web'
import axios from 'axios'
import api from '../../../actions/api'
import { toastSuccess } from '../../../actions/toast'
import { loadImageArea } from '../../../actions/area'
import InputLabel from '../../../components/Form/input/InputLabel'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function Areas(props) {
  const formRef = useRef(null)
  const [areas, setAreas] = useState([])
  const [layout, setLayout] = useState({})
  const [editing, setEditing] = useState(false)
  const optionsCard = [
    { label: 'Card 1', value: 'card1' },
    { label: 'Card 2', value: 'card2' },
    { label: 'Card 3', value: 'card3' },
    { label: 'Card 4', value: 'card4' },
    { label: 'Card 5', value: 'card5' },
    { label: 'Card 6', value: 'card6' },
  ]
  const optionsButton = [
    { label: 'Botão 1', value: 'button1' },
    { label: 'Botão 2', value: 'button2' },
    { label: 'Botão 3', value: 'button3' },
    { label: 'Botão 4', value: 'button4' },
    { label: 'Botão 5', value: 'button5' },
    { label: 'Botão 6', value: 'button6' },
  ]

  const prepareImages = useCallback(areas => {
    for (let i = 0; i < areas.length; i++) {
      const area = areas[i];
      const item = areas[i].resources[0];

      if (item && item.id) {
        loadImageArea(item.id).then(thumbnail => {
          let img = document.getElementById(area.id)
          img.src = thumbnail
        })
      } else {
        let img = new Image()
        img.src = '/assets/images/crop.png'
        img.id = 'image'
        img.classList.add('img-fluid')

        document.getElementById(area.id)
          .appendChild(img)
      }
    }
  }, [])

  const submit = useCallback(async data => {
    const _layout = {
      "title": data.title,
      "title_font_color": data.title_font_color,
      "card_name": data.card_name,
      "button_background_color": data.button_background_color,
      "button_background_color_hover": data.button_background_color_hover,
      "button_text_color": data.button_text_color,
      "button_text_color_hover": data.button_text_color_hover,
      "button_name": data.button_name
    }

    await api.post('layout-areas', _layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Áreas atualizado!')
      })
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()

    api.get('layout-areas', { cancelToken: source.token })
      .then(result => {
        setLayout(result.data)
      })
      .catch(() => { })

    return () => source.cancel()
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()

    api.get('areas', { cancelToken: source.token })
      .then(result => {
        setAreas(result.data)
        prepareImages(result.data)
      })
      .catch(() => { })

    return () => source.cancel()
  }, [editing, prepareImages])

  return (
    <div className="container mt-4 ">
      <Container className="row" layout={layout}>
        {!editing && (
          <>
            {areas.map((area, i) => (
              <CardArea key={i} className="card mb-5" layout={layout} >
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <img
                      id={area.id}
                      src="/assets/images/no-image.png"
                      className="img-fluid" alt="foto area" />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <p className="card-text text-left">Área</p>
                      <div className="line"></div>
                      <h5 className="card-title text-left">{area.name}</h5>
                    </div>
                    <div className="card-footer">
                      <Link
                        to={`area-detail/${area.id}`}
                        className="btn btn-info btn-sm col-sm">
                        + CURSOS
                      </Link>
                    </div>
                  </div>
                </div>
              </CardArea>
            ))}
          </>
        )}

        {editing && (
          <Form
            ref={formRef}
            initialData={layout}
            onSubmit={submit}
            noValidate>
            <div className="row">
              <div className="col-12 d-flex pr-0">
                <InputLabel name="title" label="Título" />
                <InputColorLabel
                  name="title_font_color"
                  label="Cor Fonte"
                  defaultValue={layout.title_font_color} />
              </div>

              <div className="col-sm col-md-6">
                <SelectLabel
                  name="card_name"
                  label="Bordas Imagem"
                  onChange={e => setLayout({ ...layout, card_name: e.value })}
                  defaultValue={layout.card_name}
                  placeholder="Selecione.."
                  options={optionsCard}
                />
              </div>

              <div className="col-sm col-md-6">
                <SelectLabel
                  name="button_name"
                  label="Modelo Botão"
                  onChange={e => setLayout({ ...layout, button_name: e.value })}
                  defaultValue={layout.button_name}
                  placeholder="Selecione.."
                  options={optionsButton}
                />
              </div>

              <div className="col-sm col-md-6 p-0 d-flex justify-content-between mb-4">
                <InputColorLabel
                  name="button_background_color"
                  label="Cor Botão"
                  setColor={() => setLayout({ ...layout, button_background_color: formRef.current.getFieldValue('button_background_color') })}
                  defaultValue={layout.button_background_color} />

                <InputColorLabel
                  name="button_background_color_hover"
                  label="Cor Botão (hover)"
                  setColor={() => setLayout({ ...layout, button_background_color_hover: formRef.current.getFieldValue('button_background_color_hover') })}
                  defaultValue={layout.button_background_color_hover} />

                <InputColorLabel
                  name="button_text_color"
                  label="Fonte Botão"
                  setColor={() => setLayout({ ...layout, button_text_color: formRef.current.getFieldValue('button_text_color') })}
                  defaultValue={layout.button_text_color} />

                <InputColorLabel
                  name="button_text_color_hover"
                  label="Fonte Botão (hover)"
                  setColor={() => setLayout({ ...layout, button_text_color_hover: formRef.current.getFieldValue('button_text_color_hover') })}
                  defaultValue={layout.button_text_color_hover} />
              </div>

              <div className="col-12 row m-2">
                {areas.map((area, i) => (
                  <CardArea key={i} className="card mb-5" layout={layout} >
                    <div className="row no-gutters">
                      <div className="col-md-6">
                        <img
                          id={area.id}
                          src="/assets/images/no-image.png"
                          className="img-fluid" alt="foto area" />
                      </div>
                      <div className="col-md-6">
                        <div className="card-body">
                          <p className="card-text text-left">Área</p>
                          <div className="line"></div>
                          <h5 className="card-title text-left">{area.name}</h5>
                        </div>
                        <div className="card-footer">
                          <Link
                            to="#"
                            className="btn btn-info btn-sm col-sm">
                            + CURSOS
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardArea>
                ))}
              </div>

              <div className="col-12 text-center mb-3">
                <button type="submit" className="btn btn-lg btn-info">
                  Atualizar
                </button>
                <button type="button"
                  className="btn btn-lg btn-warning m-2"
                  onClick={() => {
                    setEditing(false)
                    prepareImages(areas)
                  }}>
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}

        {!editing && (
          <div className="col-12 text-center mb-3">
            <button
              className="btn btn-info"
              onClick={() => {
                setEditing(true)
                prepareImages(areas)
              }}>
              Editar Áreas de Atuação
            </button>
          </div>
        )}

        {areas && areas.length === 0 && (
          <div className="col-12 mt-3 mb-3 bg-warning text-center">
            <h1 className="mt-2">Atenção!!</h1>
            <div className="text-center p-5">
              <h6>Você ainda não possui áreas cadastradas.</h6>
              <h6>Para incluir uma nova área acesse a página
              <Link to={`/areas/`}> <b>Áreas.</b></Link>
              </h6>
            </div>
          </div>
        )}
      </Container>
    </div >
  )
}

export default Areas

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  
  h1 {
    font-size: 26px;
    color: ${props => props.layout.title_font_color};
    font-weight: bold;
  }
`

export const CardArea = styled.div`
  width: 340px;
  color: ${props => props.layout.card_text_color};
  border: none !important;
  .card-footer {
    border: none !important;
    background: none;
  }
  .card-body {
    height: 125px;
  }

  h5{
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px; 
  }

  .line {
    margin-top: -15px;
    margin-bottom: 10px;
    height: 3px;
    width: 40px;
    background-color: ${props => props.layout.button_background_color}; 
  }

  a {
    border: none;
    background: ${props => props.layout.button_background_color} !important;
    color: ${props => props.layout.button_text_color} !important;
    border-radius: ${props => {
    switch (props.layout.button_name) {
      case 'button1':
        return 'none'

      case 'button2':
        return '24px'

      case 'button3':
        return '24px 0 24px 24px'

      case 'button4':
        return '24px 24px 24px 0'

      case 'button5':
        return '0 24px 24px 24px'

      case 'button6':
        return '0 0 0 0'

      default:
        return 'none'
    }
  }};
    
    :hover {
        background: ${props => props.layout.button_background_color_hover} !important;
        color: ${props => props.layout.button_text_color_hover} !important;
    }
  }

  img {
    border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px'

      case 'card3':
        return '24px 0 24px 24px'

      case 'card4':
        return '24px 24px 24px 0'

      case 'card5':
        return '0 24px 24px 24px'

      case 'card6':
        return '12px'

      default:
        return 'none'
    }
  }};
  }

  @media (max-width: 800px) {
    width: 350px;
    p {font-size: 12px};
    h5 {font-size: 14px};
    .card-body {
      height: 135px;
    }
  }
  
  @media (max-width: 736px) {
    width: 180px;
    p {font-size: 12px};
    h5 {font-size: 14px};
  }
  
  @media (max-width: 568px) {
    width: 100%;
    p {font-size: 14px};
    h5 {font-size: 20px};
    .card-body {
      height: 100px;
    }
  }
  
  @media (max-width: 499px) {
    width: 100%;
    p {font-size: 14px};
    h5 {font-size: 18px};

    a {
      height: 40px;
    }

    .btn.btn-sm {
      padding: 10px 10px !important;
      font-size: 14px !important;
    }
  }
`