import React from 'react'
import styled from 'styled-components'
import { Progress } from 'reactstrap';

function ProgressCourse(props) {
  return (
    <Container area_color={props.area_color}>
      <div className="d-flex align-items-center">
        <div className="col p-0">
          <Progress value={props.progress} max="100" />
        </div>
        <span className="ml-1">{Math.round(props.progress)}%</span>
      </div>
    </Container>
  )
}

export default ProgressCourse

export const Container = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  .progress {
    border-radius: 0;
  }

  .progress-bar {
    background-color: ${props => props.area_color} !important;
  }
`