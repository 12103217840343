export const menuAdmin = () => {
  const menu = [
    {
      title: 'Usuário',
      items: [
        {
          url: `/tenant-informations`,
          icon: 'business',
          title: 'Dados empresa',
          items: []
        },
        {
          url: '/',
          icon: 'dashboard',
          title: 'Dashboard',
          items: []
        }
      ]
    },
    {
      title: 'Ações',
      items: [
        {
          url: '/areas',
          icon: 'list_alt',
          title: 'Áreas',
          items: []
        },
        {
          url: '/courses',
          icon: 'school',
          title: 'Cursos',
          items: []
        },
        {
          url: '/classes',
          icon: 'class',
          title: 'Turmas',
          items: []
        },
        {
          url: '/subscriptions',
          icon: 'view_list',
          title: 'Matrículas',
          items: []
        },
        {
          url: '/users',
          icon: 'supervisor_account',
          title: 'Usuários',
          items: []
        },
      ]
    },
    {
      url: '#',
      icon: 'public',
      title: 'Personalizar Site',
      items: [
        {
          url: '/site-admin/home',
          icon: 'web',
          title: 'Home',
          items: []
        },
        {
          url: '/site-admin/area-detail',
          icon: 'web',
          title: 'Área Selecionada',
          items: []
        },
        {
          url: '/site-admin/course-detail',
          icon: 'web',
          title: 'Curso Selecionado',
          items: []
        },
        {
          url: '/tenant-contacts',
          icon: 'web',
          title: 'Contatos',
          items: []
        },
        {
          url: '/faqs-adm',
          icon: 'web',
          title: 'FAQs',
          items: []
        }
      ]
    },
  ]
  return menu
}
