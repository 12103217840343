import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../actions/api'
import { toastSuccess } from '../../../actions/toast'
import { Form } from '@unform/web'
import { loadImageCourse } from '../../../actions/course'
import { formatPrice } from '../../../actions/util'
import InputLabel from '../../../components/Form/input/InputLabel'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function AreaCourses(props) {
  const [layout, setLayout] = useState({})
  const [editing, setEditing] = useState(false)
  const formRef = useRef(null)
  const optionsCard = [
    { label: 'Card 1', value: 'card1' },
    { label: 'Card 2', value: 'card2' },
    { label: 'Card 3', value: 'card3' },
    { label: 'Card 4', value: 'card4' },
    { label: 'Card 5', value: 'card5' },
    { label: 'Card 6', value: 'card6' },
  ]

  const prepareImages = useCallback(courses => {
    for (let i = 0; i < courses.length; i++) {
      const course = courses[i];
      if (course.resources.length > 0 && course.resources[0].id) {
        loadImageCourse(course.resources[0].id).then(thumbnail => {
          let img = document.getElementById(course.id)
          img.src = thumbnail
        })
      }
    }
  }, [])

  const submit = useCallback(async data => {
    layout.courses_title = data.courses_title

    await api.post('layout-area-details', layout)
      .then(result => {
        setLayout(result.data)
        setEditing(false)
        toastSuccess('Layout Áreas atualizado!')
      })
  }, [layout])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.area.courses && props.area.courses.length > 0) {
      prepareImages(props.area.courses);
    }
  }, [props, prepareImages])

  return (
    <div className="container mt-4 mb-3">
      <Container className="row text-center" layout={layout}>
        {!editing && (
          <div className="col-12 mt-3 p-5 mb-3">
            <h1>{layout.courses_title}</h1>
          </div>
        )}

        {editing && (
          <div className="col-12 mt-3 mb-3 bg-warning">
            <h1 className="mt-2">Atenção!!</h1>
            <div className="text-left p-5">
              <h6>- A alterações impactam em todas as áreas de atuação.</h6>
              <h6>- Para alterar as cores do cartão com as infomações do curso,
              altere as cores primária e secundária no cadastro de
                <Link to={`/areas`}> <b>Áreas.</b></Link>
              </h6>
            </div>
          </div>
        )}

        {editing && (
          <Form
            ref={formRef}
            initialData={layout}
            onSubmit={submit}
            noValidate>
            <div className="row">
              <div className="col-12 d-flex pr-0 text-left">
                <InputLabel name="courses_title" label="Título" />
                <InputColorLabel
                  name="courses_title_color"
                  label="Cor Fonte"
                  height="40px"
                  defaultValue={layout.courses_title_color} />
              </div>

              <div className="col-sm col-md-6 text-left">
                <SelectLabel
                  name="card_name"
                  label="Modelo cartão"
                  onChange={e => setLayout({ ...layout, card_name: e.value })}
                  defaultValue={layout.card_name}
                  placeholder="Selecione.."
                  options={optionsCard}
                />
              </div>

              <div className="col-sm col-md-6 p-0 d-flex text-left mb-4">
                {/* <InputColorLabel
                  name="card_background_color"
                  label="Cor Cartão"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    card_background_color: formRef.current.getFieldValue('card_background_color')
                  })}
                  defaultValue={layout.card_background_color} />

                <InputColorLabel
                  name="card_background_color_hover"
                  label="Cor Cartão (hover)"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    card_background_color_hover: formRef.current.getFieldValue('card_background_color_hover')
                  })}
                  defaultValue={layout.card_background_color_hover} /> */}

                <InputColorLabel
                  name="card_text_color"
                  label="Fonte Cartão"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    card_text_color: formRef.current.getFieldValue('card_text_color')
                  })}
                  defaultValue={layout.card_text_color} />

                <InputColorLabel
                  name="card_text_color_hover"
                  label="Fonte Cartão (hover)"
                  height="40px"
                  setColor={() => setLayout({
                    ...layout,
                    card_text_color_hover: formRef.current.getFieldValue('card_text_color_hover')
                  })}
                  defaultValue={layout.card_text_color_hover} />
              </div>

              <div className="col-12 text-center mb-3">
                <button type="submit" className="btn btn-lg btn-info">
                  Atualizar
                </button>
                <button type="button"
                  className="btn btn-lg btn-warning m-2"
                  onClick={() => {
                    setEditing(false)
                  }}>
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}

        <div className="row">
          {props.area.id && props.area.courses.map((course, i) => (
            <div key={i} className="col-sm col-md-4">
              <Link to={`/site-admin/course-detail/${course.id}`}>
                <CardCourse index={i} className="row no-gutters mb-3"
                  area={props.area}
                  layout={layout}>
                  <div className="col-md-5">
                    <img id={course.id} src={`/assets/images/course${i + 1}.jpg`} className="img-fluid"
                      alt="course" />
                  </div>
                  <div className="col-md-7">
                    <div className=" card-body">
                      <h5 className="card-title">{course.name}</h5>
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                      <p>{course.duration.hours}H </p>
                      <p className="ml-3">|</p>
                      <p>{formatPrice(course.value)}</p>
                    </div>
                  </div>
                </CardCourse>
              </Link>
            </div>
          ))
          }
        </div>

        {!editing && (
          <div className="col-12 text-center">
            <button
              className="btn btn-info mb-3"
              onClick={() => setEditing(true)}>
              Editar Layout Cursos
            </button>
          </div>
        )}

        {props.area.courses && props.area.courses.length === 0 && (
          <div className="col-12 mt-3 mb-3 bg-warning">
            <h1 className="mt-2">Atenção!!</h1>
            <div className="text-center p-5">
              <h6>Você ainda não possui cursos nessa área.</h6>
              <h6>Para incluir cursos acesse a página
              <Link to={`/areas/${props.area.id}`}> <b>{props.area.name}.</b></Link>
              </h6>
            </div>
          </div>
        )}


      </Container>
    </div >
  )
}

AreaCourses.defaultProps = {
  area: Object,
  layout: Object
}

export default AreaCourses

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  
  h1 {
    font-size: 26px;
    color: ${props => props.layout.courses_title_color};
    font-weight: bold;
  }
`

export const CardCourse = styled.div`
  background-color: ${props => props.area.primary_color};
  color: ${props => props.layout.card_text_color};
  max-height: 120px;

  border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px'

      case 'card3':
        return '24px 0 24px 24px'

      case 'card4':
        return '24px 24px 24px 0'

      case 'card5':
        return '0 24px 24px 24px'

      default:
        return 'none'
    }
  }};
  
  h5{
    font-size: 20px;
    font-weight: bold;
  }

  p { font-size: 16px; }

  .card-footer {
    border: none !important;
    background: none;
  }
  .card-body {
    height: 75px;
  }

  :hover {
    cursor: pointer;
    background-color: ${props => props.area.second_color};
    color: ${props => props.layout.card_text_color_hover};
  }

  img{
    max-height: 120px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card1':
        return 'none'

      case 'card2':
        return '24px 0 0 24px'

      case 'card3':
        return '24px 0 0 24px'

      case 'card4':
        return '24px 0 0 0'

      case 'card5':
        return '0 0 0 24px'

      default:
        return 'none'
    }
  }};
  }

  @media (max-width: 800px) {
    p {font-size: 12px};
    h5 {font-size: 14px};
    max-height: 100px;

    .card-body {
      height: 60px;
    }
  }
  
  @media (max-width: 736px) {
    max-height: 200px;
    p {font-size: 12px};
    h5 {font-size: 14px};

    img {
      border-radius: ${props => {
    switch (props.layout.card_name) {
      case 'card2':
        return '24px 24px 0 0'

      case 'card3':
        return '24px 0 0 0'

      case 'card4':
        return '24px 24px 0 0'

      case 'card5':
        return '0 24px 0 0'

      default:
        return 'none'
    }
  }};
    }
  }
  
  @media (min-width: 350px) and (max-width: 499px) {
    max-height: 240px;
    h5 {font-size: 22px};
    p {font-size: 18px};
  }
  
  @media (max-width: 349px) {
    max-height: 230px;
    h5 {font-size: 18px};
    p {font-size: 16px};
  }
`