import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import InputLabel from '../../../components/Form/input/InputLabel'
import TextAreaLabel from '../../../components/Form/input/TextAreaLabel'

const FormFAq = (props) => (
  <Form
    ref={props.formRef}
    initialData={props.initialData}
    onSubmit={props.submit}
    noValidate>

    <InputLabel name="question" label="Pergunta" />

    <TextAreaLabel
      name="answer"
      label="Resposta"
      viewCount={true}
      rows="3"
    />

    <div className="mt-4 d-flex justify-content-end">
      <div className="col-sm col-md-3 pl-0">
        <Link to="/faqs-adm">
          <button type="button" className="btn btn-lg btn-block border-info">
            Voltar
            </button>
        </Link>
      </div>

      <div className="col-sm col-md-3 pr-0">
        <button type="submit" className="btn btn-lg btn-block btn-info">
          {props.initialData.id ? 'Atualizar' : 'Salvar'}
        </button>
      </div>
    </div>
  </Form >
)

FormFAq.defaultProps = {
  submit: Function,
  submitTopic: Function,
  formRef: Function,
  initialData: Object,
}

export default FormFAq