import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { isAuthenticated } from '../../../actions/auth'

const MenuItems = (props) => {
  return (
    <>
      {!isAuthenticated() && (
        <Container className="mt-2 d-flex">
          <div className="row">

            <div className="col">
              <Link to="/login">
                <h4>Login</h4>
              </Link>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export default MenuItems

export const Container = styled.div`
  a {
    :hover {
      color: #fff;
    }
  }
`