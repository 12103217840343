import React, { useEffect, useState } from 'react'
import { isAuthenticated } from '../../actions/auth'
import Menu from './Menu'
import { menuSite } from './menu-site'
import { menuAdmin } from './menu-admin'
import { menuStudent } from './menu-student'
import ProfileSidebar from '../profile-sidebar'
import ProgressCourse from './ProgressCourse'
import './left-sidebar-1.css'

const LeftSidebar = (props) => {
  const [menu, setMenu] = useState([{ title: '', items: [] }])
  const [viewProfile, setViewProfile] = useState(false)

  useEffect(() => {
    if (!isAuthenticated()) {
      setMenu(menuSite)
    }

    if (props.user && isAuthenticated()) {
      if (props.user.roles[0] === 'student') {
        if (props.navigation) {
          setMenu(Object.values(props.navigation))
        } else {
          setMenu(menuStudent)
        }
      } else {
        setMenu(menuAdmin)
        setViewProfile(true)
      }

      if (props.location.pathname.includes('my-courses')) {
        setViewProfile(false)
      } else {
        setViewProfile(true)
      }
    }

    const location = props.location.pathname
    if (!location.includes('my-courses') && props.user.roles[0] === 'student') {
      setMenu(menuStudent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <div>
      <div className="left-sidebar-placeholder" />
      <div className="left-sidebar left-sidebar-1">
        <div className="wrapper">
          <div className="content">
            {menu.map((item, i) => (
              <div key={i} className="section">
                <div className="section-title">{item.title}</div>
                <ul className="list-unstyled">
                  {(i === 0 && isAuthenticated()) && (
                    <>
                      {viewProfile ? (
                        <ProfileSidebar />
                      ) : (
                        <ProgressCourse progress={item.progress} area_color={item.area_color || ''} />
                      )}
                    </>
                  )}
                  {item.items.map((items, i) => <Menu key={i} items={items} />)}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftSidebar