import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../../components/widget'

import api from '../../../actions/api'
import { loadTenantContacts } from '../../../actions/contact'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import DataTable from 'react-data-table-component';
import Loading from '../../../components/loading'
import DeleteModal from '../../../components/delete-modal'

class ListTenantContacts extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      isOpenModal: false,
      itemDelete: {},
      actions: [
        {
          name: 'Novo Contato',
          class_btn: 'btn-info',
          class_collumn: 'col col-md-2',
          route: '/tenant-contacts/new'
        }
      ]
    }

    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Nome',
        selector: 'name',
        sortable: true,
        grow: 2
      },
      {
        name: 'Endereço',
        selector: 'value',
        sortable: true,
        grow: 2
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  componentDidMount() {
    this.getListTenantContacts();
  }

  getListTenantContacts = async () => {
    this.setState({ loading: true });
    loadTenantContacts().then(contacts => {
      this.setState({
        data: contacts,
        loading: false,
      });
    })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  onConfirmDelete(item) {
    this.setState({ isOpenModal: true, itemDelete: item })
  }

  onDeleteItem = async () => {
    const { itemDelete } = this.state;

    await api.delete(`tenant-contacts/${itemDelete.id}`)
      .then(() => {
        this.getListTenantContacts();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ isOpenModal: false, itemDelete: { name: '' } })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  onCancelDelete = () => {
    this.setState({ isOpenModal: false, itemDelete: { name: '' } })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/tenant-contacts/${reg.id}`)
  };

  render() {
    return (
      <Widget title="Contatos" actions={this.state.actions} line={false}>
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>
        </div>

        <DeleteModal
          isOpen={this.state.isOpenModal}
          isHide={this.onCancelDelete}
          itemDeleteName={this.state.itemDelete.name}
          onDeleteItem={this.onDeleteItem}
        />
      </Widget >
    )
  }
}

export default ListTenantContacts