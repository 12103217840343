import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import TableSubscriptions from './TableSubscriptions'
import FormSearch from './FormSearch'
import DeleteModal from '../../../components/delete-modal'
import { index, remove } from '../../../actions/subscription'

const actions = [
  {
    name: 'Nova Matrícula',
    class_btn: 'btn-info',
    class_collumn: 'col col-md-2',
    route: '/subscriptions/new'
  }
]

const ListSubscription = (props) => {
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [itemDelete, setItemDelete] = useState({ name: '' })
  const [params, setParams] = useState({ page: 1, perPage: 10 })
  const dispatch = useDispatch()

  const loadSubscriptions = useCallback(() => {
    index(params).then(response => {
      const _data = response.data.map(item => ({
        ...item,
        class_name: item.class && item.class.name ? item.class.name : ''
      }))
      response.data = _data

      setSubscriptions(response)
      setLoading(false)
    })
  }, [params])

  const handleRowClicked = useCallback(reg => {
    props.history.push(`/subscriptions/${reg.id}`)
  }, [props])

  const cancelDelete = useCallback(() => {
    setOpenModal(false)
    setItemDelete({ name: '' })
  }, [])

  const confirmDelete = useCallback((item) => {
    setOpenModal(true)
    setItemDelete({ id: item.id, name: item.user.name })
  }, [])

  const deleteItem = useCallback(() => {
    remove(itemDelete.id).then(() => {
      loadSubscriptions()
      setOpenModal(false)
      setItemDelete({ name: '' })
    })
  }, [loadSubscriptions, itemDelete])

  const handlePerRowsChange = useCallback(async (perPage,) => {
    params.perPage = perPage
    setParams(params)
    loadSubscriptions()
    window.scrollTo(0, 0)
  }, [params, loadSubscriptions])

  const onChangePage = useCallback(async page => {
    params.page = page
    setParams(params)
    loadSubscriptions()
  }, [params, loadSubscriptions])

  useEffect(() => {
    window.scrollTo(0, 0)
    let isSubscribed = true

    if (isSubscribed) {
      dispatch({
        type: 'SET_BREADCRUMB', items: [{ page: 'Matrículas', url: '#' }]
      })
      setLoading(true)
      loadSubscriptions()
    }

    return () => (isSubscribed = false)
  }, [loadSubscriptions, dispatch])

  const filter = useCallback(e => {
    if (e.type === 'user_name') params.user_name = e.value
    if (e.type === 'course_name') params.course_name = e.value
    if (e.type === 'class_name') params.class_name = e.value
    if (e.type === 'clear') {
      params.user_name = ''
      params.course_name = ''
      params.class_name = ''
    }

    setParams(params)
    loadSubscriptions()
  }, [params, loadSubscriptions])

  return (
    <Widget title="Matrículas" actions={actions} line={false}>

      <FormSearch filter={filter} />

      <TableSubscriptions
        data={subscriptions}
        progressPending={loading}
        confirmDelete={confirmDelete}
        onRowClicked={handleRowClicked}
        paginationPerPage={params.perPage}
        handlePerRowsChange={handlePerRowsChange}
        onChangePage={onChangePage}
      />

      <DeleteModal
        isOpen={openModal}
        isHide={cancelDelete}
        itemDeleteName={`a matrícula de ${itemDelete.name}`}
        onDeleteItem={deleteItem}
      />
    </Widget>
  )
}

export default ListSubscription