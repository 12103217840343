import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

function ListFaqs(props) {
  const [layout, setLayout] = useState({})
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.faqs) setFaqs(props.faqs)
  }, [props])

  return (
    <div className="container mt-4">
      <Container layout={layout}>
        <div className="col-12 mt-3 p-5 text-center">
          <h1>{layout.title}</h1>
        </div>

        <div className="mt-5">
          {faqs.length > 0 && faqs.map((faq, i) => (
            <ul key={i} className="list-group mb-4">
              <p>{faq.question}</p>
              <li className="list-group-item">
                <span>{faq.answer}</span>
              </li>
            </ul>
          ))}
        </div>
      </Container>
    </div >
  )
}

ListFaqs.defaultProps = {
  faqs: Array,
  layout: Object
}

export default ListFaqs

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;

  min-height: 550px;
  
  h1 {
    font-size: 26px;
    color: ${props => props.layout.title_font_color};
    font-weight: bold;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }

  span {
    font-size: 14px;
    font-weight: 100;
  }
`