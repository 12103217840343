import React, { useRef, useCallback, useState, useEffect } from 'react'
import * as Yup from 'yup'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import FormReset from './FormReset'
import ResetSuccess from './ResetSuccess'

function ResetPassword(props) {
  const formRef = useRef(null)
  const [success, setSuccess] = useState(false)
  const [token, setToken] = useState('')

  const submit = useCallback(async (data) => {
    await api.post(`reset/${token}`, data)
      .then(res => {
        setSuccess(true)
        toastSuccess('Senha Altera com sucesso')
        props.history.push('/reset-success')
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }, [props, token])

  const submitForm = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})

      const schema = Yup.object().shape({
        password: Yup.string().required('Senha é obrigatória'),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
      })

      await schema.validate(data, { abortEarly: false })

      submit(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [submit])

  useEffect(() => {
    if (props.location.search) {
      let token = props.location.search

      token = token.substr(7, 300)
      setToken(token)
    }

  }, [props])

  return (
    <>
      {success ? (<ResetSuccess />) : (
        <FormReset formRef={formRef} submit={submitForm} />
      )}
    </>
  )
}

export default ResetPassword