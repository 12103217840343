import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showThumbnail, showUserProfile, updateUserProfile } from '../../../actions/user'
import Widget from '../../../components/widget'
import FormUserProfile from './FormUserProfile'
import CropThumbnail from './CropThumbnail'
import { Container } from './styles'

function User(props) {
  const formRef = useRef(null)
  const [profile, setProfile] = useState({})
  const [preview, setPreview] = useState('')
  const [alterThumbnail, setAlterThumbnail] = useState(false)
  const [initialData, setInitialData] = useState({})
  const dispatch = useDispatch()
  const userLogged = useSelector(state => state.user)

  const setThumbnail = useCallback(user => {
    if (userLogged.thumbnail) {
      setPreview(userLogged.thumbnail)
    } else if (user.thumbnail) {
      showThumbnail(user.id)
        .then(result => setPreview(result))
    } else {
      setPreview('/assets/images/profile.jpeg')
    }
  }, [userLogged])

  const setBreadcrumb = useCallback(user => {
    dispatch({
      type: 'SET_BREADCRUMB',
      items: [
        { page: 'Perfil', url: '#' },
        { page: user.name ? user.name : '', url: '#' },
        { page: 'Editar', url: '#' },
      ]
    })
  }, [dispatch])

  const getProfile = useCallback(async id => {
    showUserProfile(id).then(data => {
      setProfile(data.user)
      setInitialData(data.user)
      setThumbnail(data.user)
      setBreadcrumb(data.user)
    })
  }, [setBreadcrumb, setThumbnail])

  const updateProfile = useCallback(async (data) => {
    data.id = profile.id

    updateUserProfile(data).then(user => {
      dispatch({
        type: 'SET_USER',
        user: user
      })
    })
  }, [dispatch, profile])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        username: Yup.string().min(3, 'Usuário mínimo 3 caracteres'),
        email: Yup.string(),
      })

      await schema.validate(data, { abortEarly: false })

      updateProfile(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [updateProfile])

  useEffect(() => {
    const id = props.match.params.id
    if (id && Object.entries(profile).length === 0) getProfile(id)
    if (userLogged.thumbnail) setPreview(userLogged.thumbnail)
  }, [props, getProfile, profile, userLogged]);

  return (
    <Widget title={profile.name || 'Perfil'}>
      {initialData && (
        <div className="row justify-content-center">
          <div className="col-sm col-md-8">
            <FormUserProfile
              initialData={initialData}
              formRef={formRef}
              submit={submit}
            />
          </div>

          <Container className="mt-4 text-center col-sm">
            <img
              className="img-fluid image"
              src={preview} alt="preview" />

            <div className="row justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => {
                  setAlterThumbnail(true)
                  window.scrollTo(0, document.body.scrollHeight);
                }}>
                Alterar imagem
              </button>
            </div>
          </Container>
        </div>
      )}

      <div className="row mt-3 mb-5">
        <div className="col-sm">
          <CropThumbnail
            show={alterThumbnail}
            hide={() => setAlterThumbnail(false)}
            user_id={profile.id || null}
            thumbnail={preview || null} />
        </div>
      </div>
    </Widget >
  )
}

export default User