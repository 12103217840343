import React, { useCallback } from 'react'
import './forbiden.css'


function Forbiden(props) {
  const toHome = useCallback(() => {
    props.history.push('/')
    // window.location.reload();
  }, [props])

  return (
    <div className="row col error-page p-2 mt-5">
      <h1 className="animated fadeIn delay-100 mt-5">403</h1>
      <h3 className="animated fadeIn delay-500 mt-5">Você não possui acesso a esse recurso</h3>
      <div className="btn btn-primary btn-lg btn-block animated fadeIn delay-1000 error-link mt-5" onClick={toHome}>
        <p>Retorne a página inicial</p>
      </div>
    </div>
  )
}

export default Forbiden