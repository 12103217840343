import React, { useCallback, useState, useEffect } from 'react';
import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import {
  Container,
  Cadastro,
  Footer,
  Loader,
  Error,
} from './MatricularAlunoSiteStyle';
import ClipLoader from 'react-spinners/ClipLoader';

export function MatricularAlunoSite(props) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [matricula, setMatricula] = useState(null);

  const matricularAluno = useCallback(async matricula => {
    try {
      const resp = await api.post(`subscriptions/enroll`, matricula);

      if (resp.data.cursos[0].message === 'Curso não existe no EAD') {
        setErrorMessage(`Desculpe, mas esse curso não foi encontrado!`);
      }

      setTimeout(() => {
        setMatricula(resp.data);
        toastSuccess('Matrícula realizada com sucesso!');
        setLoading(false);
      }, 1500);
    } catch (error) {
      toastError('Desculpe, não foi possível realizar a solicitação');
      setLoading(false);
      setErrorMessage(` Desculpe, mas houve um problema ao processar seu pedido. 
        O link fornecido parece estar corrompido ou incompleto.`);
    }
  }, []);

  useEffect(() => {
    try {
      const pedido = JSON.parse(decodeURIComponent(props.match.params.pedido));
      matricularAluno(pedido);
    } catch (error) {
      setLoading(false);
      setErrorMessage(`Desculpe, mas houve um problema ao processar seu pedido. O link
              fornecido parece estar corrompido ou incompleto.`);
      toastError('Desculpe, não foi possível realizar a solicitação');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader>
          <ClipLoader size={50} color={'#d1434d'} />
          <span>Aguarde, sua matrícula está sendo processado.</span>
        </Loader>
      ) : errorMessage ? (
        <Error className="row ">
          <div className="col col-md-6">
            <h1 className="text-center">Erro ao Processar Pedido!</h1>

            <p>{errorMessage}</p>

            <p>
              Por favor, tente novamente ou entre em contato com o suporte para
              obter ajuda.
            </p>

            <div className="contato">
              <span>(49) 3563-7010</span>
              <span>cacador@cetesc.com.br</span>
            </div>
          </div>
        </Error>
      ) : (
        <Cadastro className=" m-0">
          {matricula && (
            <div className="row mx-1 justify-content-center">
              <div className="col col-md-6">
                <div>
                  <h1>Parabéns!</h1>
                  <span>{matricula?.aluno?.nome}</span>
                </div>

                <p className="mt-5">
                  O curso de <b>{matricula?.cursos[0]?.name}</b>
                </p>
                <p>foi adicionado a sua plataforma de estudos.</p>

                <p>
                  Agora você já pode acessar nossa plataforma e ter acesso a
                  diversos cursos.
                </p>

                <p>Seja um profissional de destaque e alcance o sucesso!</p>

                <div className="row justify-content-center">
                  <div className="alerta col">
                    Verifique seu e-mail <b>({matricula?.aluno?.email})</b> e
                    confira seus dados de acesso.
                  </div>
                </div>
              </div>
            </div>
          )}
        </Cadastro>
      )}

      <Footer className="col-12">
        <div className="col text-center">
          <img src="/assets/images/logo_vermelho.svg" alt="Logo Cetesc" />
          <div>
            <span>Direitos reservados | Hyberica Softwares</span>
          </div>
        </div>
      </Footer>
    </Container>
  );
}
