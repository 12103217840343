
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import Purchase from './Purchase'

const Component = compose(
  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Minhas compras', url: '/purchases' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(Purchase),
    renderNothing
  )
)(Purchase)

export default Component