import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';

import { useField } from '@unform/core';

import { Container } from './styles';

const InputMaskLabel = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container className="mt-3">
      <label htmlFor={fieldName}>{label}</label>
      <ReactInputMask
        className={error ? 'input-error' : ''}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <span className="error text-left ml-1">{error}</span>}
    </Container>
  );
};

export default InputMaskLabel;
