import React, { useEffect, useState } from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'

import InputLabel from '../../../components/Form/input/InputLabel'
import InputMaskLabel from '../../../components/Form/input/InputMaskLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function FormContact(props) {
  const [selectedValue, setSelectedValue] = useState({})

  const optionsContact = [
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'E-mail', value: 'email' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Twiter', value: 'twiter' },
  ]

  useEffect(() => {
    if (props.initialData.name !== undefined) {
      setSelectedValue(props.initialData.name)

      props.formRef.current.setFieldValue('name', optionsContact
        .find(option => option.value === props.initialData.name))
    }
  }, [props, optionsContact])

  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate>

      <SelectLabel
        name="name"
        label="Nome"
        options={optionsContact}
        onChange={e => setSelectedValue(e.value)}
      />

      {selectedValue !== 'whatsapp' ? (
        <InputLabel
          name="value"
          label="Endereço"
          placeholder={selectedValue === 'email' ? 'Insira o e-mail' : 'Insira o link'}
        />
      ) : (
          <div className="mt-3">
            <InputMaskLabel
              mask="(99) 99999-9999"
              name="value" label="WhatsApp"
              placeholder="Insira seu WhatsApp" />
          </div>
        )}

      <div className="mt-4 d-flex justify-content-end">
        <div className="col-sm col-md-3 pl-0">
          <Link to="/tenant-contacts">
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
            </button>
          </Link>
        </div>

        <div className="col-sm col-md-3 pr-0">
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}

FormContact.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object
}

export default FormContact