export function checkout(
  state = { courses: [] },
  action
) {
  switch (action.type) {
    case 'ADD_CHECKOUT':
      return Object.assign({}, state, {
        ...action.checkout
      })

    case 'ADD_CHECKOUT_ITEM':
      state.courses.push(action.course)

      return Object.assign({}, state, {
        ...action.checkout
      })

    case 'REMOVE_CHECKOUT_ITEM':
      const index = state.courses.findIndex(course => course.id === action.course_id)
      state.courses.splice(index, 1)

      return Object.assign({}, state, {
        ...action.checkout
      })

    default:
      return state
  }
}


