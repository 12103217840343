import React, { useEffect, useCallback, useState } from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import api from '../../../actions/api'
import { toastError } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import InputLabel from '../../../components/Form/input/InputLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'

function FormClass(props) {
  const [isUpdate, setIsUpdate] = useState(false)
  const [options, setOptions] = useState([])

  const getOptionCourse = useCallback(async () => {
    await api.get('courses')
      .then(result => {
        const options = result.data.map(item => {
          return { label: item.name, value: item.id }
        })
        setOptions(options)
      })
      .catch(error => {
        handleError(toastError(error))
      })
  }, [])

  useEffect(() => {
    if (options.length === 0) getOptionCourse()

    if (props.initialData.id && !isUpdate) {
      props.formRef.current
        .setFieldValue("course_id", options.find(option =>
          option.value === props.initialData.course_id))

      setIsUpdate(true)
    }
  }, [props, getOptionCourse, options, isUpdate])
  return (
    <>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.submit}
        noValidate>
        <div className="row">
          <div className="col-sm col-md-4">
            <SelectLabel
              name="course_id"
              label="Curso"
              placeholder="Selecione.."
              options={options}
            />
          </div>

          <div className="col-sm">
            <InputLabel name="name" label="* Nome" />
          </div>
        </div>

        <InputLabel name="comments" label="Descrição" />

        <div className="mt-4 d-flex justify-content-end">
          <div className="col-sm col-md-2 pl-0">
            <Link to="/classes">
              <button type="button" className="btn btn-lg btn-block border-info">
                Voltar
              </button>
            </Link>
          </div>

          <div className="col-sm col-md-2 pr-0 ">
            <button type="submit" className="btn btn-lg btn-block btn-info">
              Salvar
            </button>
          </div>
        </div>
      </Form >
    </>
  )
}

FormClass.defaultProps = {
  submit: Function,
  submitTopic: Function,
  formRef: Function,
  initialData: Object,
  course: Object
}

export default FormClass