import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

function DeleteModal(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      wrapClassName="modal-danger"
      size="default">
      <ModalHeader >Deletar Registro</ModalHeader>
      <ModalBody>
        Deseja excluir {props.itemDeleteName}?
      </ModalBody>
      <ModalFooter>
        <button className="btn border-danger" onClick={props.isHide}>
          Cancelar
        </button>
        <button className="btn btn-info col-3" onClick={props.onDeleteItem}>
          Sim
        </button>
      </ModalFooter>
    </Modal>
  )
}

DeleteModal.defaultProps = {
  isOpen: Boolean,
  isHide: Boolean,
  itemDeleteName: String,
  onDeleteItem: Function
}

export default DeleteModal