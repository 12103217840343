import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ProgressBar from 'react-customizable-progressbar'
import ReactTooltip from 'react-tooltip'
import { setConfig } from '../../actions/config'
import { formatTitle } from '../../actions/util'
import styled from 'styled-components'
import '../../css/badges.css'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: true
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  closeSidebar = () => {
    this.props.setConfig('collapsed', false)
    window.scrollTo(0, 0)
  }

  render() {
    let items = this.props.items
    if (items.items.length === 0) {
      return (
        <li onClick={this.closeSidebar}>
          <Link
            to={items.url}
            className="btn btn-default btn-flat btn-sidebar btn-sidebar-1">
            <i className="material-icons">{items.icon}</i>
            {items.progress && (
              <ProgressTopic>
                <ProgressBar
                  radius={100}
                  progress={items.progress}
                  cut={120}
                  rotate={-210}
                  strokeWidth={30}
                  strokeColor={items.area_color}
                  strokeLinecap="square"
                  trackStrokeWidth={25}
                  trackStrokeColor="#EBEBEB"
                  trackStrokeLinecap="square"
                  pointerRadius={0}
                  initialAnimation={true}
                  transition="1.5s ease 0.5s"
                  trackTransition="0s ease"
                >
                  <div className="indicator-volume">
                    <div className="inner">{Math.round(items.progress)}%</div>
                  </div>
                </ProgressBar>
              </ProgressTopic>
            )}
            <TopicTitle >
              <p>{items.number}</p>
              <span className="title" data-tip='' data-for={items.title}>
                {formatTitle(items.title, 23)}
              </span>
              {items.title.length >= 23 && (
                <ReactTooltip id={items.title}>{items.title}</ReactTooltip>
              )}
            </TopicTitle>

            {/* <span className="title" onClick={this.closeSidebar}>{items.title}</span> */}
            {items.badge && (
              <span className={`ml-auto ${items.badge.className}`} >
                {items.badge.title}
              </span>
            )}
          </Link>
        </li>
      )
    } else {
      return (
        <li >
          <div
            className={
              this.state.isOpen
                ? 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children is-open'
                : 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children'
            }
            onClick={this.toggle}>
            <i className="material-icons">{items.icon}</i>
            {items.progress && (
              <ProgressTopic>
                <ProgressBar
                  radius={100}
                  progress={items.progress}
                  cut={120}
                  rotate={-210}
                  strokeWidth={30}
                  strokeColor={items.area_color}
                  strokeLinecap="square"
                  trackStrokeWidth={25}
                  trackStrokeColor="#EBEBEB"
                  trackStrokeLinecap="square"
                  pointerRadius={0}
                  initialAnimation={true}
                  transition="1.5s ease 0.5s"
                  trackTransition="0s ease"
                >
                  <div className="indicator-volume">
                    <div className="inner">{Math.round(items.progress)}%</div>
                  </div>
                </ProgressBar>
              </ProgressTopic>
            )}
            <TopicTitle>
              <p>{items.number}</p>
              <span className="title" data-tip='' data-for={items.title}>
                {formatTitle(items.title, 23)}
              </span>
              {items.title.length >= 23 && (
                <ReactTooltip id={items.title}>{items.title}</ReactTooltip>
              )}
            </TopicTitle>
          </div>
          <ul className="list-unstyled">
            {items.items.map((item, k) => (
              <MenuLi key={k} onClick={this.closeSidebar}>
                <Link
                  to={item.url}
                  className="btn btn-default btn-flat btn-sidebar btn-sidebar-2">
                  <i className="material-icons">{item.icon}</i>
                  {item.number && (
                    <ProgressLesson>
                      <ProgressBar
                        radius={100}
                        progress={item.progress}
                        strokeWidth={18}
                        strokeColor={item.area_color}
                        trackStrokeWidth={18}
                        // trackStrokeColor="#DC3545"
                        strokeLinecap="square"
                      >
                        <div className="indicator">
                          <div>{item.number}</div>
                        </div>
                      </ProgressBar>
                    </ProgressLesson>
                  )}
                  <span className="title"
                    // onClick={this.closeSidebar}
                    data-tip='' data-for={item.title}>
                    {formatTitle(item.title, 23)}
                  </span>
                  {item.title.length >= 23 && (
                    <ReactTooltip id={item.title}>{item.title}</ReactTooltip>
                  )}
                </Link>
              </MenuLi>
            ))}
          </ul>
        </li>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setConfig: (key, value) => dispatch(setConfig(key, value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu)

export const MenuLi = styled.li`
  a {
    :hover {
      background-color: #DC3545 !important;
    }
  }
`

export const TopicTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 15px;

  p {
    margin-right: 5px;
    font-size: 0.5rem;
  }
`

export const ProgressTopic = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40px;
  margin-left: -35px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.5rem; 

  svg {
    width: 35px;
    height: 35px;
  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-weight: 100;
    /* color: #555; */
    user-select: none;
  }

  .indicator-volume {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .inner {
    margin-bottom: 10px;
  }
`
export const ProgressLesson = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40px;
  margin-left: -10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.5rem; 

  svg {
    width: 30px;
    height: 30px;
  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-weight: 100;
    /* color: #555; */
    user-select: none;
  }
`