import React, { useCallback, useState, useEffect, useRef } from 'react'
// import { loadPurchaseById } from '../../../actions/purchase'
import Widget from '../../../components/widget'
import FormPurchase from './FormPurchase'

function Purchase(props) {
  const formRef = useRef(null)
  const [purchase, setPurchase] = useState({})

  const getPurchase = useCallback(async id => {
    // loadPurchaseById(id)
    //   .then(purchase => {
    //     setPurchase(purchase)
    //   })

    setPurchase({
      id: '38584a95-3fdd-424b-a57f-d0fe213fc535',
      discount_coupon_id: null,
      date: '2020-10-21T20:16:17.442Z',
      payment_id: null,
      amount_total: 600,
      discount: 240,
      total_pay: 360,
      instalments: 1,
      courses: [
        {
          id: 'bc363703-2901-41c5-92f8-00dc09e36240',
          name: 'Excel Avançado',
          duration: '90:00:00.000',
          price: 600,
          resources: [
            {
              id: '43f9f9ac-ff2b-4fd3-9b7e-ece24c003ff1',
            }
          ],
        }
      ]
    })
  }, [])



  useEffect(() => {
    const id = props.match.params.id
    if (id && Object.entries(purchase).length === 0) getPurchase(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <Widget title="Minhas Compras" >
      <FormPurchase
        initialData={purchase}
        formRef={formRef}
      />
    </Widget >
  )
}

export default Purchase;