import React, { useRef, useCallback, useState } from 'react'
import * as Yup from 'yup'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import FormForgot from './FormForgot'
import ForgotSuccess from './ForgotSuccess'

function Forgot(props) {
  const formRef = useRef(null)
  const [success, setSuccess] = useState(false)

  const submit = useCallback(async (data) => {
    await api.post('forgot', data)
      .then(res => {
        setSuccess(true)
        toastSuccess('E-mail enviado - confira seu e-mail')
        props.history.push('/forgot-success')
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }, [props])

  const submitForm = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
      })

      await schema.validate(data, { abortEarly: false })

      submit(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [submit])

  return (
    <>
      {success ? (<ForgotSuccess />) : (
        <FormForgot formRef={formRef} submit={submitForm} />
      )}
    </>
  )
}

export default Forgot