import React from 'react'
import { Form } from '@unform/web'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import InputLabel from '../../../components/Form/input/InputLabel'

function FormUserProfile(props) {
  return (
    <Form
      ref={props.formRef}
      initialData={props.initialData}
      onSubmit={props.submit}
      noValidate>
      <div className="row">
        <div className={!props.initialData.id ? 'col-sm col-md-12' : 'col-sm col-md-7'}>
          <InputLabel name="name" label="Nome" />
        </div>

        <div className={!props.initialData.id ? 'col-sm col-md-7' : 'col-sm'}>
          <InputLabel name="username" label="Usuário" />
        </div>
      </div>

      <InputLabel disabled name="email" label="E-mail" />

      <div className="mt-4 d-flex justify-content-end">
        <div className="col-sm col-md-3 pl-0">
          <Link to="/">
            <button type="button" className="btn btn-lg btn-block border-info">
              Voltar
            </button>
          </Link>
        </div>

        <div className="col-sm col-md-3 pr-0 ">
          <button type="submit" className="btn btn-lg btn-block btn-info">
            Salvar
          </button>
        </div>
      </div>
    </Form >
  )
}

FormUserProfile.defaultProps = {
  submit: Function,
  formRef: Function,
  initialData: Object,
}


const mapStateToProps = (state, ownProps) => {
  return {
    user_permissions: state.user.permissions
  }
}
export default connect(mapStateToProps)(FormUserProfile)