import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import MoonLoader from "react-spinners/MoonLoader"
import ReactTooltip from 'react-tooltip'
import { loadImage, loadVideoThumbnail } from '../../actions/resources'
import { loadPdfThumbnail, loadUrlThumbnail } from '../../actions/resources'
import { formatTitle } from '../../actions/util'
import { loadFileThumbnail, loadIcon } from '../../actions/resources'
import DeleteResource from './DeleteResource'
import PreviewResource from './PreviewResource'

function LessonResources(props) {
  const [resources, setResources] = useState([]);
  const [previewResource, setPreviewResource] = useState({})
  const [uploadingVideo, setUploadingVideo] = useState(false)

  const setElementImage = (id, img) => {
    document.getElementById(id)
      .appendChild(img)
      .classList.add('card-img-top')
  }

  const imageThumbnail = useCallback(async (item) => {
    loadImage(item.id).then(thumbnail => {
      let img = new Image()
      img.src = thumbnail;

      setElementImage(item.id, img)
    })
  }, [])

  const videoThumbnail = useCallback(async (item) => {
    if (item.url) {
      loadVideoThumbnail(item.id).then(thumbnail => {
        let img = new Image()
        img.src = thumbnail;

        setElementImage(item.id, img)
        setUploadingVideo(false)
      })
    } else {
      setUploadingVideo(false)
      setTimeout(() => {
        ReactDOM.render(
          <div className="row justify-content-center mt-3">
            <MoonLoader size={40} color={"#0288D1"} />
          </div>,
          document.getElementById(item.id)
        )
        setUploadingVideo(true)
      }, 100);

      // TODO - melhorar essa parte, fica atualizando msm sem estar na lesson
      // if (window.location.pathname.includes(props.lesson_id)) {
      //   setTimeout(() => {
      //     window.location.reload()
      //   }, 20000)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pdfThumbnail = useCallback(async (item) => {
    loadPdfThumbnail().then(thumbnail => {
      let img = new Image()
      img.src = thumbnail
      img.alt = item.name

      setElementImage(item.id, img)
    })
  }, [])

  const urlThumbnail = useCallback(async (item) => {
    loadUrlThumbnail().then(thumbnail => {
      let img = new Image()
      img.src = thumbnail
      img.alt = item.name

      setElementImage(item.id, img)
    })
  }, [])

  const fileThumbnail = useCallback(async (item) => {
    loadFileThumbnail().then(thumbnail => {
      let img = new Image()
      img.src = thumbnail
      img.alt = item.name

      setElementImage(item.id, img)
    })
  }, [])

  useEffect(() => {
    if (props.resources.length > resources.length ||
      props.resources.length < resources.length) setResources([])

    if (props.resources && resources.length === 0) {
      setResources(props.resources)

      for (let i = 0; i < props.resources.length; i++) {
        const item = props.resources[i];

        if (item.type === 'image') {
          imageThumbnail(item)
        } else if (item.type === 'video') {
          videoThumbnail(item)
        } else if (item.subtype === 'pdf') {
          pdfThumbnail(item)
        } else if (!item.file) {
          urlThumbnail(item)
        } else {
          fileThumbnail(item)
        }
      }
    }
  }, [props, props.resources.length, resources.length,
    imageThumbnail, pdfThumbnail, urlThumbnail, videoThumbnail, fileThumbnail])

  return (
    <>
      <div className="row mt-5 mb-5">
        <div className="col">
          {((resources && resources.length === 0) && (!props.uploadingFile)) && (
            <div className="col jumbotron text-center mb-4">
              <h6>Nenhum recurso incluido!</h6>
            </div>
          )}

          <div >
            <PreviewResource
              resource={previewResource || null}
              closePreview={() => setPreviewResource({})} />
          </div>

          <h5>Recursos da Aula</h5>

          <div className="row mt-3">
            {uploadingVideo && (
              <div className="col-12">
                <div className="alert alert-warning text-center p-0" role="alert">
                  <p className="mt-3"><b>ATENÇÃO!</b></p>
                  <p className="m-0">O vídeo levará alguns minutos até ser processado.</p>
                  <p>Você pode inserir outros recursos enquanto isso.</p>
                </div>
              </div>
            )}

            {resources.map((item, i) => (
              <div className="col-6 col-md-2" key={i}>
                <Card className="card text-center mb-2 shadow">
                  <div onClick={() => {
                    setPreviewResource(item)
                  }}>
                    <Preview id={item.id} />

                    <div className="card-body p-1">
                      <div className="d-flex justify-content-center">
                        {loadIcon(item)}
                        <p data-tip='' data-for={item.id} >{formatTitle(item.name, 15)}</p>
                        {
                          item.name.length >= 20 && (
                            <ReactTooltip id={item.id}>{item.name}</ReactTooltip>
                          )
                        }
                      </div>
                    </div>

                  </div>

                  <div className="row justify-content-center mb-2">
                    {/* {item.url && ( */}
                    <DeleteResource
                      label="Excluir"
                      size={14}
                      id={item.id} name={item.name}
                      updateLessonPage={() => {
                        setPreviewResource({ type: undefined })
                        props.updateLessonPage()
                        setUploadingVideo(false)
                      }} />
                    {/* )} */}
                  </div>
                </Card>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

LessonResources.defaultProps = {
  resources: Array,
  uploadingFile: Boolean,
  lesson_id: String,
  updateLessonPage: Function
}

export default LessonResources

export const Preview = styled.div`
/* mostra o thumbanail que vai ser renderizado */
  height: 5rem;
  padding: 3px;
`
export const Card = styled.div`
  height: 10rem;
  background-color: transparent !important;

  :hover {
    cursor: pointer;
    background-color: #f0f0f0 !important;
    color: #0288d1;
  }

  img {
    max-height: 100%;
    object-fit: cover;
  }
`