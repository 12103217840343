import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ReactTooltip from 'react-tooltip'
import { formatPrice, formatTimeString } from '../../../actions/util'

function BannerCourse(props) {
  const [layout, setLayout] = useState({})
  const [course, setCourse] = useState({ duration: { hours: 0 } })

  useEffect(() => {
    if (props.layout) setLayout(props.layout)
    if (props.course) setCourse(props.course)
  }, [props])

  return (
    <Banner layout={layout} areaColor={course.area || {}}>
      <div className="text-card col-md-5 text-center p-3">
        <h1>{course.name}</h1>

        <div className="col-12 row justify-content-between">
          <p>{course.duration ? formatTimeString(course.duration) : '00:00'} H</p>
          <p>|</p>
          <p>{course.price ? formatPrice(course.price) : 'R$ 0,00'}</p>
          <p>|</p>
          <span data-tip='' data-for='pre-requisitos'>Pré-requisitos </span>
          <ReactTooltip id='pre-requisitos' type="ligth" place="bottom">
            <div>
              <h5>Pré-requisitos</h5>
              {course.requirements ? (
                <h6>{course.requirements}</h6>
              ) : (
                  <h6>Sem pré-requisitos para este curso!</h6>
                )}
            </div>
          </ReactTooltip>
        </div>
      </div>
    </Banner>
  )
}

BannerCourse.defaultProps = {
  course: Object,
  layout: Object
}

export default BannerCourse

export const Banner = styled.div`
  font-family: 'Oswald', sans-serif;
  height: 400px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -18px;
  /* background: ${props => props.layout.banner_background_color}; */
  background: ${props => props.areaColor.primary_color};

  .text-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background: ${props => props.layout.banner_card_background_color};
    color: ${props => props.layout.banner_card_text_color};
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
    bottom: 1;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;

    h1 { 
      font-size: 30px; 
      margin-top: 120px;
      font-weight: bold;
      text-transform: uppercase; 
    }
    p { 
      font-size: 26px; 
      font-weight: normal;
      margin-top: 30px;
    }
    span { 
      font-size: 26px; 
      font-weight: normal;
      margin-top: 30px;
      /* color: ${props => props.layout.banner_background_color} !important; */
      color: ${props => props.areaColor.primary_color} !important;
      :hover{
        cursor: pointer;
      }
    }
  }

  /* TOOLTIP adjustments */
  .type-ligth {
    background: ${props => props.layout.banner_card_background_color} !important;
    color: ${props => props.layout.card_text_color} !important;
    border: 2px solid ${props => props.layout.banner_card_background_color} !important;
    box-shadow: 0 0 1px #000000;
  }
`
