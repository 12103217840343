import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import InputLabel from '../../components/Form/input/InputLabel'

function KeepTopic(props) {
  const formRef = useRef(null)
  const closeBtn = <button className="close" onClick={() =>
    props.hideModal()}>&times;</button>;

  // const [focus, setFocus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [initialData, setInitialData] = useState({})

  const save = useCallback(async (data) => {
    data.course_id = props.course_id
    data.topic_id = null

    await api.post('topics', data)
      .then(() => {
        toastSuccess(`Novo tópico incluído!`);
        props.updateCoursePage()
      }).catch(error => {
        toastError(handleError(error));
      })
    props.hideModal(false)
  }, [props])

  const update = useCallback(async (data) => {
    await api.put(`topics/${props.topic.id}`, data)
      .then(() => {
        toastSuccess(`Registro atualizado!`);
        props.updateCoursePage()
      }).catch(error => {
        toastError(handleError(error));
      })
    props.hideModal(false)
  }, [props])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        description: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Descrição mínimo 8 caracteres') : Yup.string()
        }),
      })

      await schema.validate(data, { abortEarly: false })

      props.topic ? update(data) : save(data)

    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [props, save, update])

  useEffect(() => {
    setShowModal(props.showModal)

    if (props.topic && showModal) setInitialData(props.topic)

    // const x = document.getElementById('topic_name')
    // if (!focus && x) {
    //   x.focus()
    //   setFocus(true)
    // }
    // if (!showModal) setFocus(false)
  }, [props, showModal, initialData])

  return (
    <Modal
      isOpen={showModal}
      centered={true}
      fade={false}
      wrapClassName="modal-info"
      size="default">
      <ModalHeader close={closeBtn}>{initialData.id ? 'Atualizar' : 'Novo'} Tópico</ModalHeader>
      <ModalBody>
        <Form
          ref={formRef}
          initialData={initialData}
          onSubmit={submit}
          noValidate
        >
          <div className="col-sm">
            <InputLabel id="topic_name" name="name" label="Nome" />

            <div className="d-flex justify-content-end mt-4">
              <div className="col-sm col-md-4 pl-0 mb-2">
                <button
                  type="button"
                  className="btn btn-lg btn-block border-danger"
                  onClick={e => {
                    e.preventDefault()
                    props.hideModal()
                  }}>
                  Cancelar
                </button>
              </div>

              <div className="col-sm pr-0 col-md-4">
                <button type="submit" className="btn btn-lg btn-block btn-info">
                  {initialData.id ? 'Atualizar' : 'Salvar'}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}
KeepTopic.defaultProps = {
  topic_id: String,
  showModal: Boolean,
  hideModal: Function,
  course_id: String,
  updateCoursePage: Function
}
export default KeepTopic