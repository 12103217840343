import React from 'react'
import { Form } from '@unform/web'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatPrice, formatTimeString } from '../../../actions/util'
import InputLabel from '../../../components/Form/input/InputLabel'

const FormPurchase = (props) => (
  <Form
    ref={props.formRef}
    initialData={props.initialData}
    onSubmit=""
    noValidate>

    <div className="row">
      <div className="col-sm">
        <InputLabel name="date" label="Data" />
      </div>
      <div className="col-sm">
        <InputLabel name="amount_total" label="Valor do pedido" />
      </div>
      <div className="col-sm">
        <InputLabel name="discount" label="Desconto" />
      </div>
      <div className="col-sm">
        <InputLabel name="total_pay" label="Total" />
      </div>
    </div>

    <div className="row col mt-5">
      <h5>Itens do pedido</h5>
    </div>

    <div className="table-widget-3 col">
      <TableCart>
        <table className="table table-unbordered table-striped">
          <thead>
            <tr className="d-flex justify-content-between align-items-center head">
              <td>#</td>
              <td>Curso</td>
              <td>Horas</td>
              <td>Valor</td>
            </tr>
          </thead>
          <tbody>
            {props.initialData.courses && props.initialData.courses.map((course, i) => (
              <tr key={i} className="row justify-content-between align-items-center">
                <td>
                  {course.resources && (
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/course-image-files/${course.resources[0].id}`}
                      crossOrigin="anonymous"
                      width="50"
                      className="img-fluid img-thumbnail"
                      alt="image_course"
                    />
                  )}
                </td>
                <td>{course.name}</td>
                <td>{formatTimeString(course.duration)}</td>
                <td>{formatPrice(course.price)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <TotalCart className="row justify-content-between align-items-center mt-5">
          <div><span>Total</span></div>
          <div><span>{formatPrice(props.initialData.total_pay)}</span></div>
        </TotalCart>

      </TableCart>
    </div>


    <div className="mt-4 d-flex justify-content-end">
      <div className="col-sm col-md-3 pl-0">
        <Link to="/purchases">
          <button type="button" className="btn btn-lg btn-block border-info">
            Voltar
            </button>
        </Link>
      </div>
    </div>
  </Form >
)

FormPurchase.defaultProps = {
  formRef: Function,
  initialData: Object,
}

export default FormPurchase

export const TableCart = styled.div`
  td {
    border-top: 0 !important;
  }

  .head{
    font-weight: bold;
  }
`

export const TotalCart = styled.div`
  span {
    font-size: 18px;
    font-weight: bold;
  }
`