import React, { createContext, useEffect, useState } from 'react';
import { string } from 'yup';

// interface ThemeData{
//   color_nav: string
// }

// interface TenantData {
//   name: string
//   logo_url: string,
//   theme: ThemeData
// }

interface TenantContextType {
  tenant: string;
  setTenant: (tenant: string) => void;
}

export const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
  const logos = [
    {
      name: 'cetesc',
      logo_url: '/assets/images/logo_branco.svg',
      color_nav: '#D1434D',
    },
    {
      name: 'farmagnus',
      logo_url: '/assets/images/logofarmagnus.svg',
      color_nav: '#fff',
    },
    {
      name: 'hyberica',
      logo_url: '/assets/images/hyberica.png',
      color_nav: '#706869',
    },
    {
      name: 'gosc',
      logo_url: '/assets/images/gosc.png',
      color_nav: '#2aaced',
    },
  ];

  const [tenant, setTenant] = useState(logos[0]);

  const getTenant = () => {
    const host = window.location.href;

    if (host.includes('cetesc')) {
      setTenant(logos[0]);
    } else if (host.includes('farmagnus')) {
      setTenant(logos[1]);
    } else if (host.includes('demo')) {
      setTenant(logos[2]);
    } else if (host.includes('gosc')) {
      setTenant(logos[3]);
    }
  };

  useEffect(() => {
    getTenant();
  }, []);

  return (
    <TenantContext.Provider value={{ tenant, setTenant }}>
      {children}
    </TenantContext.Provider>
  );
};
