import React, { useEffect } from 'react'
import IndigoPlayer from "indigo-player"
import 'indigo-player/lib/indigo-theme.css'

function VideoPlayerAdmin(props) {
  useEffect(() => {
    const element = document.getElementById('playerContainer')
    if (props.src) {
      let config = {
        autoplay: false,
        aspectRatio: 16 / 9,
        sources: [
          {
            type: `${props.type}`,
            src: `${props.src}`,
          },
        ],
        ui: { locale: 'pt-BR', pip: true },
        thumbnails: {
          src: '',
        },
      }

      const _player = IndigoPlayer.init(element, config)

      return () => _player.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src])

  return (
    <div id="playerContainer" style={{ width: `${props.width}vmin` }}></div >
  )
}

VideoPlayerAdmin.defaultProps = {
  src: String,
  type: String,
  width: Number,
  my_course_id: String,
  resource_id: String,
}

export default VideoPlayerAdmin
