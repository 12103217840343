import React, { useEffect, useState } from 'react';
import IndigoPlayer from 'indigo-player';
import { connect } from 'react-redux';
import 'indigo-player/lib/indigo-theme.css';
// import { formatTime } from '../../actions/time';
// import { postProgress } from '../../actions/resources';

function VideoPlayer(props) {
  const [player, setPlayer] = useState({});
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    const element = document.getElementById('playerContainer');

    if (props.src) {
      let config = {
        autoplay: true,
        aspectRatio: 16 / 9,
        sources: [
          {
            type: `${props.type}`,
            src: `${props.src}`,
          },
        ],
        ui: { locale: 'pt-BR', pip: true },
        thumbnails: {
          src: '',
        },
      };

      const _player = IndigoPlayer.init(element, config);

      setPlayer(_player);
      setPlayed(false);

      return () => {
        // const timeCurrent = getProgress(_player);
        // saveProgress(timeCurrent, props.resource_id, false);

        _player.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  useEffect(() => {
    var element = document.getElementById('playerContainer');

    element.addEventListener('click', function() {
      setPlayed(true);
    });

    if (played) {
      // player.on(IndigoPlayer.Events.STATE_CURRENTTIME_CHANGE, e => {
      //   // console.log(e.state.currentTime);
      //   // setCurrentTime(e.state.currentTime);
      // });

      player.on(IndigoPlayer.Events.STATE_ENDED, e => {
        // saveProgress(e.state.currentTime, props.resource_id, true);
      });

      player.on(IndigoPlayer.Events.STATE_PAUSED, e => {
        // saveProgress(e.state.currentTime, props.resource_id, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [played]);

  // const saveProgress = useCallback((currentTime, resource_id, ended) => {
  // const time = formatTime(currentTime);

  // const progress = {
  //   subscription_id: props.my_course_id,
  //   progress_time: time,
  //   resource_id: resource_id,
  //   completed: ended,
  // };

  // if (currentTime > 0) postProgress(progress)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getProgress = useCallback(player => {
  //   const objs = player.getStats().player;
  //   const obj = objs.find(o => o.mediaElement);

  //   return obj.mediaElement.currentTime;
  // }, []);

  return <div id="playerContainer" style={{ width: '100%' }}></div>;
}

VideoPlayer.defaultProps = {
  src: String,
  width: Number,
  my_course_id: String,
  resource_id: String,
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // setConfig: (key, value) => dispatch(setConfig(key, value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
