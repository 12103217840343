
import styled from 'styled-components'

export const EmptyCart = styled.div`
  margin-top: 80px;
  
  p{
    color: #999;
    font-size: 16px;
    margin-top: 30px;
  }

  span {
    color: #999;
    font-size: 14px;

    :hover{
      color: #303030
    }
  }

`

export const TableCart = styled.div`
  td {
    border-top: 0 !important;
  }
`

export const TotalCart = styled.div`
  span {
    font-size: 18px;
    font-weight: bold;
  }
`
