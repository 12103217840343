export const menuStudent = () => {
  const menu = [
    {
      title: 'Usuário',
      items: [
        {
          url: '/',
          icon: 'dashboard',
          title: 'Dashboard',
          items: []
        }
      ]
    }
  ]
  return menu
}
