import styled from 'styled-components'
import bgDesk from '../../assets/images/bg_campanha_escola.png'
import bgCetesc from '../../assets/images/bg_cetesc.png'

export const CampanhaEscolaContainer = styled.div`
  height: 100vh;
  margin-top: -90px;
  margin-left: -20px;
  margin-right: -20px;
`

export const Banner = styled.div`
  background-image: url(${bgDesk});
  background-size: cover;
  background-repeat: no-repeat;

  height: 100vh;

  h1 {
    font-size: 60px;
    color: #fff;
  }


  @media screen and (max-width: 499px) {
    h1 {
      font-size: 48px;
    }
  }
`
export const Cadastro = styled.div`
  background-image: url(${bgCetesc});
  background-size: cover;

  padding-bottom: 200px;

  h1 {
    font-weight: bold;
    color: #d1434d;
    font-size: 46px;
    margin-top: 80px;
    padding-bottom: 30px;
  }

  p {
    color: #fff;
    font-size: 22px;
  }

  form {
    background-color: #fff;
    padding: 20px;
    border-radius: 3px;
  }

  .btn-primary {
    background-color: #641b4d !important;
    border-radius: 30px;
    height: 50px;
    font-size: 20px !important;
    border: none;
    margin-top: 50px;
    padding-top: 10px;

    :hover {
      background-color: #d1434d !important;
      cursor: pointer;
    }
  }
`

export const Footer = styled.footer`
  height: 100px;
  position: absolute;
  bottom: 0;
  margin-top: -100px;

  span {
    color: #d1434d;
  }

  img {
    height: 50px;
  }
`
