import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Widget from '../../../components/widget';
import TableClasses from './TableClasses';
import FormSearch from './FormSearch';
import { indexClass } from '../../../actions/class';
import { indexCourses } from '../../../actions/course';
import { setBreadcrumb } from '../../../reducers/breadcrumb';

const actions = [
  {
    name: 'Nova Turma',
    class_btn: 'btn-info',
    class_collumn: 'col col-md-2',
    route: '/classes/new',
  },
];

const ListClass = props => {
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const dispatch = useDispatch();

  const loadClasses = useCallback(() => {
    indexClass(params).then(response => {
      response.data.forEach(item => {
        if (!item.course) {
          item.course = { name: '', id: '' };
        }
      });

      setClasses(response);
      setLoading(false);
    });
  }, [params]);

  const loadCourses = useCallback(() => {
    indexCourses({ status: 'ATIVO' }).then(response => {
      const options = response.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCourses(options);
    });
  }, []);

  const handlePerRowsChange = useCallback(
    async perPage => {
      params.perPage = perPage;
      setParams(params);
      loadClasses();
      window.scrollTo(0, 0);
    },
    [params, loadClasses]
  );

  const onChangePage = useCallback(
    async page => {
      params.page = page;
      setParams(params);
      loadClasses();
    },
    [params, loadClasses]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    let isSubscribed = true;

    if (isSubscribed) {
      dispatch(setBreadcrumb([{ page: 'Turmas', url: '#' }]));
      setLoading(true);
      loadClasses();
      loadCourses();
    }

    return () => (isSubscribed = false);
  }, [loadClasses, dispatch, loadCourses]);

  const filter = useCallback(
    e => {
      if (e.type === 'course_id') params.course_id = e.value;
      if (e.type === 'clear') {
        params.course_id = '';
      }

      setParams(params);
      loadClasses();
    },
    [params, loadClasses]
  );

  return (
    <Widget title="Turmas" actions={actions} line={false}>
      <FormSearch filter={filter} courses={courses} />

      <TableClasses
        data={classes}
        paginationPerPage={params.perPage}
        handlePerRowsChange={handlePerRowsChange}
        onChangePage={onChangePage}
        progressPending={loading}
        loadClasses={loadClasses}
      />
    </Widget>
  );
};

export default ListClass;
