import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F1F1F1;  

  h1 {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #2B88F4;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0154A0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #505050;
  }

  .form-check-label {
    font-size: 13px;
    padding-left: 2px;
  }
`

export const Subdomain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;

  .false-input {
    padding: 5px;
    background: #F9F9F9;
    border: 1px solid #D9D9D9;
    height: 30px;
    border-radius: 3px;
  }
`
