import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from '@unform/web'
import Input from '../../components/Form/input/Input'
import Button from '../../components/Form/button/Button'

import { Container, Forgot } from './styles'

function FormLogin(props) {
  return (
    <Container className="container">
      <div className="row justify-content-center pt-5">
        <div className="form-group col-12 text-center mt-5">
          <h1>Faça seu login</h1>
        </div>

        <div className="col-12 col-md-5 text-center mt-4">
          <Form className="form-group" ref={props.formRef} onSubmit={props.submit} noValidate>
            <div className="input-group mt-4">
              <Input type="email" name="email" placeholder="E-mail" />
            </div>

            <div className="input-group mt-3">
              <Input type="password" name="password" placeholder="Senha" />
            </div>

            <div className="mt-5 text-center">
              <Button
                type="submit"
                template="button1"
                bgColor="#00FFA0"
                color="#4e4e4e"
                className="btn btn-lg col-7 font-weight-bolder"
                label="Login" />
            </div>
          </Form>
        </div>
      </div>

      <Forgot className="text-center">
        <Link to="/forgot">
          <p>Esqueci minha senha</p>
        </Link>
      </Forgot>
    </Container >
    // <Container className="container col-md-4 col-sm">
    //   <Logo>
    //     <img width="100" src="/assets/images/favicon.png" alt="" />
    //     <h1>EAD</h1>
    //   </Logo>

    //   <div className="text-center mt-3">
    //     <h4 className="mb-4">Login</h4>
    //     <p>Por favor, entre com seu e-mail e senha</p>
    //   </div>

    //   <Form className="p-3" ref={props.formRef} onSubmit={props.submit} noValidate>
    //     <div className="mt-4">
    //       <InputLabel type="email" name="email" label="E-mail" />
    //     </div>

    //     <div className="mt-3">
    //       <InputLabel type="password" name="password" label="Senha" />
    //     </div>

    //     <div className="col-sm mt-4 text-center mb-2">
    //       <button type="submit" className="btn btn-lg btn-info p-2 col-6">
    //         Login
    //       </button>
    //     </div>

    //     <Forgot className="text-center">
    //       <Link to="/forgot">
    //         <p>Esqueci a senha</p>
    //       </Link>
    //     </Forgot>
    //   </Form>
    // </Container>
  )
}
FormLogin.defaultProps = {
  submit: Function,
  formRef: Function
}

export default FormLogin