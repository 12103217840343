import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import TableCourses from './TableCourses'
import { indexCourses } from '../../../actions/course'
import { setBreadcrumb } from '../../../reducers/breadcrumb'

const actions = [{
  name: 'Novo Curso',
  class_btn: 'btn-info',
  class_collumn: 'col col-md-2',
  route: '/courses/new'
}]

const ListCourse = () => {
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const loadCourses = useCallback(() => {
    indexCourses().then(response => {
      setCourses(response)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    let isSubscribed = true

    if (isSubscribed) {
      dispatch(setBreadcrumb([{ page: 'Cursos', url: '#' }]))
      setLoading(true)
      loadCourses()
    }

    return () => (isSubscribed = false)
  }, [loadCourses, dispatch])

  return (
    <Widget title="Cursos" actions={actions} line={false}>
      <TableCourses
        data={courses}
        loading={loading}
        setLoading={setLoading}
        loadCourses={loadCourses} />
    </Widget>
  )
}

export default ListCourse