import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { formatPrice } from '../../../actions/util'
import { isAuthenticated } from '../../../actions/auth'
import { postCheckoutItem, addCheckoutItem } from '../../../actions/checkout'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'

function Content(props) {
  const [layout, setLayout] = useState({})
  const [course, setCourse] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.course.id) {
      props.course.thumbnail = `${process.env.REACT_APP_API_BASE_URL}/course-image-files/${props.course.resources[0].id}`
      setCourse(props.course)
    }
  }, [props.course])

  useEffect(() => {
    if (props.layout.id) setLayout(props.layout)
  }, [props.layout])

  const includeCheckoutItem = () => {
    if (isAuthenticated()) {
      postCheckoutItem({ course_id: course.id }).then(checkout => {
        const new_course = checkout.courses.find(item => item.id === course.id)

        dispatch(addCheckoutItem(new_course))

        setViewModal(true)
      })
    } else {
      // abrir modal informado pra logar no sistema
    }
  }

  return (
    <Container className="mt-4 mb-4" layout={layout}>
      <div className="row">
        <div className="col-2 mt-4">
          <LeftBar color={layout.banner_background_color} />
        </div>
        <div className="col-10 col-md-6">
          <h1>{layout.about_title}</h1>
          <p>{course.description}</p>
        </div>
      </div>

      <div className="d-flex">
        <ContentCourse layout={layout} className="col-12 col-md-8">
          <div>
            <h2>{layout.content_title}</h2>

            <ul className="ml-5 mt-3">
              {course.topics && course.topics.map((topic, i) => (
                <li key={i}>
                  <span className="mr-2"> {topic.number}</span>
                  {topic.name}

                  <Subtopics>
                    {topic.topics && topic.topics.map((subtopic, x) => (
                      <li key={x}>
                        <span className="mr-2"> {subtopic.number}</span>
                        {subtopic.name}
                      </li>
                    ))}
                  </Subtopics>
                </li>
              ))}
            </ul>
          </div>
        </ContentCourse>
        <div className="col-2 col-md-4">
          <RightBar color={layout.banner_background_color} />
        </div>
      </div>

      <div className="row mt-4 justify-content-center">
        <div className="col-sm col-md-8 text-center">
          <Button
            layout={layout}
            className="btn btn-info btn-lg btn-block mb-5"
            onClick={() => includeCheckoutItem()}>
            ADICIONAR AO CARRINHO
          </Button>
        </div>
      </div>


      {/* modal */}
      <Modal
        isOpen={viewModal}
        size="default"
        toggle={() => setViewModal(false)}>
        <ModalHeader toggle={() => setViewModal(false)}>
          Curso incluído no carrinho
        </ModalHeader>
        <ModalBody>
          <ModalCheckout className="d-flex justify-content-between align-items-center">
            <img
              src={course.thumbnail}
              crossOrigin="anonymous"
              width="70"
              className="img-fluid img-thumbnail"
              alt="image_course"
            />

            <span>{course.name}</span>

            <span>{formatPrice(course.price)}</span>

          </ModalCheckout>
        </ModalBody>
        <ModalFooter>
          <button className="btn border-danger" onClick={() => setViewModal(false)}>
            Continuar comprando
          </button>
          <Link to="/cart" className="btn btn-info col-4">
            Ir para o carrinho
          </Link>
        </ModalFooter>
      </Modal>
    </Container>
  )
}

Content.defaultProps = {
  course: Object,
  layout: Object,
  course_content: Array
}

export default Content

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  h1{
    font-size: 30px;
    color: ${props => props.layout.about_title_color};
    font-weight: bold;
    text-transform:  uppercase;
  }

  p{
    font-size: 18px;
    color: ${props => props.layout.about_text_color}
  }
`

export const ContentCourse = styled.div`
  background-color: ${props => props.layout.content_background_color};
  margin-left: -20px;
  padding: 20px;
  margin-top: 10px;

  h2{
    margin-left: 80px;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    color: ${props => props.layout.content_title_color};
  }

  li {
    color: ${props => props.layout.content_text_color};
    font-size: 16px;
    list-style-type: none;
  }
`

export const LeftBar = styled.div`
  background: ${props => props.color};
  height: 60%;
  margin-top: 20px;
  margin-left: -20px;
`

export const RightBar = styled.div`
  background: ${props => props.color};
  height: 70px;
  width: 120%;
  margin-right: -50px;
  margin-top: 50px;
  float: right;

  @media (max-width: 499px) {
    margin-right: 0;
    width: 140%;
  }
  
  @media (max-width: 399px) {
    width: 180%;
  }
`
export const Button = styled.button`
  background: ${props => props.layout.button_background_color} !important;
  color: ${props => props.layout.button_text_color} !important;
  border: none !important;
  border-radius: ${props => {
    switch (props.layout.button_name) {
      case 'button1':
        return 'none'

      case 'button2':
        return '24px'

      case 'button3':
        return '24px 0 24px 24px'

      case 'button4':
        return '24px 24px 24px 0'

      case 'button5':
        return '0 24px 24px 24px'

      default:
        return 'none'
    }
  }};

  :hover{
    background: ${props => props.layout.button_background_color_hover} !important;
    color: ${props => props.layout.button_text_color_hover} !important;
  }
`

export const Subtopics = styled.ul`
  margin: 2px 10px 10px -20px;
`

export const ModalCheckout = styled.div`
  span {
    font-size: 18px;
  }
`