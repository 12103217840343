import React, { useCallback, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import Widget from '../../../components/widget'
import FormCourse from './FormCourse'
import Topics from '../../topic/Topics'
import CropImage from '../../../components/crop-image/CropImage'
import { loadImageCourse, showCourse, saveCourse, updateCourse } from '../../../actions/course'
import { currencyToString } from '../../../actions/util'
import { setBreadcrumb } from '../../../reducers/breadcrumb'
import { ImageArea } from './styles'

function Course(props) {
  const formRef = useRef(null)
  const [course, setCourse] = useState({})
  const [preview, setPreview] = useState('')
  const [changeImage, setChangeImage] = useState(false)
  const [savedTheImage, setSavedTheImage] = useState(false)
  const [appendFormData, setAppendFormData] = useState([])
  const [canActivateCourse, setCanActivateCourse] = useState(false)
  const dispatch = useDispatch()

  const getImageCourse = useCallback(course => {
    if (course.resources.length > 0) {
      const resource = course.resources[0]
      loadImageCourse(resource.id)
        .then(image => setPreview(image))
        .catch(() => {
          setPreview('/assets/images/crop.png')
        })
    } else {
      setPreview('/assets/images/crop.png')
    }
  }, [])

  const activateCourse = useCallback(topics => {
    for (let i = 0; i < topics.length; i++) {
      const topic = topics[i];

      // verifica se tem licoes no topico e resources
      if (topic.lessons.length > 0) {
        for (let x = 0; x < topic.lessons.length; x++) {
          const lesson = topic.lessons[x];
          if (lesson.resources.length > 0) {
            setCanActivateCourse(true)
          }
        }
      }

      // verifica se tem subtopicos, licoes e resources
      if (topic.topics.length > 0) {
        for (let y = 0; y < topic.topics.length; y++) {
          const subtopic = topic.topics[y];

          if (subtopic.lessons.length > 0) {
            for (let x = 0; x < subtopic.lessons.length; x++) {
              const lesson = subtopic.lessons[x];
              if (lesson.resources.length > 0) {
                setCanActivateCourse(true)
              }
            }
          }

        }
      }
    }
  }, [])

  const updateBreadcrumb = useCallback(course => {
    let items = []

    if (course) {
      items = [
        { page: 'Cursos', url: '/courses' },
        {
          page: course.name.length >= 10 ? `${course.name.substr(0, 10)} ...` :
            course.name, url: '#'
        },
        { page: 'Editar', url: '#' },
      ]
    } else {
      items = [
        { page: 'Cursos', url: '/courses' },
        { page: 'Novo', url: '#' },
      ]
    }

    dispatch(setBreadcrumb(items))
  }, [dispatch])

  const getCourse = useCallback(async id => {
    await showCourse(id)
      .then(course => {
        if (!formRef.current) return null
        setCourse(course)
        getImageCourse(course)
        activateCourse(course.topics)
        setAppendFormData([
          { field: 'course_id', value: course.id },
          { field: 'type', value: 'image' },
        ])

        updateBreadcrumb(course)
      })
      .catch(() => props.history.push('/courses'));
  }, [props, updateBreadcrumb, getImageCourse, activateCourse])

  const save = useCallback(async (data) => {
    saveCourse(data).then(course => {
      setCourse(course)
      setPreview('/assets/images/crop.png')
    })
  }, [])

  const update = useCallback(async (data) => {
    data.id = course.id
    updateCourse(data).then(course => {
      setCourse(course)
    })
  }, [course])

  const submit = useCallback(async (data) => {
    data.price = currencyToString(data.price)

    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
        area_id: Yup.string().required('Área é obrigatṍrio'),
        duration: Yup.string().required('Duração é obrigatório'),
        price: Yup.string().required('Valor é obrigatório'),
        status: Yup.string(),
        requirements: Yup.string(),
        description: Yup.lazy(value => {
          return value ? Yup.string().min(8, 'Descrição mínimo 8 caracteres') : Yup.string()
        }),
      })

      await schema.validate(data, { abortEarly: false })

      course.id ? update(data) : save(data)
    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })
        formRef.current.setErrors(errorMessages)
      }
    }
  }, [course, save, update])

  useEffect(() => {
    let isSubscribed = true

    const id = props.match.params.id
    if (id && isSubscribed) {
      getCourse(id)
    } else {
      updateBreadcrumb(null)
    }

    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isSubscribed = true

    if (course.id && savedTheImage) {
      if (isSubscribed) {
        getCourse(course.id)
        setSavedTheImage(false)
      }
    }

    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTheImage])

  return (
    <Widget title={course.id ? `Editar ${course.name}` : 'Novo Curso'} >
      <div className="row justify-content-center">
        <div className={course.id ? 'col-sm col-md-8' : 'col-sm'}>
          <FormCourse
            initialData={course}
            formRef={formRef}
            area_id={props.match.params.area_id}
            course={course}
            submit={submit}
            canActivateCourse={canActivateCourse}
          />
        </div>

        {course.id && (
          <ImageArea className="mt-4 text-center col-sm">
            <img
              className="img-fluid image"
              src={preview}
              alt="preview" />

            <div className="row justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => {
                  setChangeImage(true)
                  window.scrollTo(0, 490)
                }}>
                Alterar imagem
              </button>
            </div>
          </ImageArea>
        )}
      </div>

      <div className="row mt-3 mb-5">
        <div className="col-sm">
          <CropImage
            show={changeImage}
            hide={() => {
              setChangeImage(false)
              setSavedTheImage(true)
            }}
            appendFormData={appendFormData}
            size="1"
            post_url={`course-image-files`}
            image={preview || null} />
        </div>
      </div>

      {course.id && (
        <Topics
          course_id={course.id}
          topics={course.topics}
          updateCoursePage={() => getCourse(course.id)}
        />
      )}
    </Widget >
  )
}

export default Course;