import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/Form/button/Button'
import { Progress } from 'reactstrap'


function Course(props) {
  const [course, setCourse] = useState({ course: { name: '' } })

  useEffect(() => {
    if (props.course && props.course.id) setCourse(props.course)
  }, [props])

  return (
    <Container>
      <Card className="text-center card">
        <div className="card-body ">
          <h3>{course.course.name}</h3>
        </div>

        <Link to={`/my-courses/${course.id}`}>
          <Button
            type="submit"
            template="button1"
            bgColor="#00FFA0"
            color="#4e4e4e"
            className="btn btn-lg mb-3 font-weight-bolder"
            label={course.course.perc_completed === 0 ? 'Iniciar estudos' : 'Continuar estudando'} />
        </Link>
        <Progressbar color={course.course.area || ''}>
          <Progress value={course.course.perc_completed} max="100" />
        </Progressbar>
      </Card>
    </Container>
  )
}

export default Course

export const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px;

  .card {
    border: 0 !important;
  }
`
export const Card = styled.div`
  height: 180px;

  h3 {
    font-weight: bold;
    font-size: 26px;
    color: #4E4E4E;
  }
`

export const Progressbar = styled.div`
  width:100%;
  bottom:0;
  box-shadow: 0px 3px 6px #00000029;

  .progress-bar {
    background-color: ${props => props.color.primary_color} !important;
  }

  .progress {
    border-radius: 0;
    background-color: ${props => props.color.second_color} !important;
  }
`