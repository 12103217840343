import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  FaFacebookF, FaInstagram, FaLinkedinIn, FaRegEnvelope, FaWhatsapp
} from 'react-icons/fa'
import { Form } from '@unform/web'
import { AiOutlineYoutube } from 'react-icons/ai'
import { FiTwitter } from 'react-icons/fi'
import styled from 'styled-components'
import api from '../../../actions/api'
import axios from 'axios'
import { toastSuccess, toastError } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import InputLabel from '../../../components/Form/input/InputLabel'
import SelectLabel from '../../../components/Form/input/SelectLabel'
import InputColorLabel from '../../../components/Form/input/InputColorLabel'

function ContactAdmin(props) {
  const formRef = useRef(null)
  const [layout, setLayout] = useState({})
  const [initialData, setInitialData] = useState({})
  const [contacts, setContacts] = useState([])
  const [optionsContact, setOptionsContact] = useState([])
  const [whatsUrl, setWhatsUrl] = useState('')
  const [editingLayout, setEditingLayout] = useState(false)
  const [editingContact, setEditingContact] = useState(false)
  const [handleContact, setHandleContact] = useState(false)

  const removerItem = useCallback((array, value) => {
    return array.filter(function (el) {
      return el.value !== value;
    });
  }, [])

  const getContacts = useCallback(() => {
    api.get('tenant-contacts')
      .then(result => {
        setContacts(result.data)

        let url = ''
        let options = [
          { label: 'Facebook', value: 'facebook' },
          { label: 'Instagram', value: 'instagram' },
          { label: 'Whatsapp', value: 'whatsapp' },
          { label: 'E-mail', value: 'email' },
          { label: 'Linkedin', value: 'linkedin' },
          { label: 'Youtube', value: 'youtube' },
          { label: 'Twiter', value: 'twiter' },
        ]

        for (let i = 0; i < result.data.length; i++) {
          const _contact = result.data[i];
          options = removerItem(options, _contact.name)

          if (_contact.name === 'whatsapp') {
            if (window.screen.availWidth > 600) {
              url = `https://web.whatsapp.com/send?text=Olá, gostaria de mais informações&phone=55${_contact.value}`
            } else {
              url = `https://api.whatsapp.com/send?text=Olá, gostaria de mais informações.&phone=55${_contact.value}`
            }
          }
        }
        setOptionsContact(options)
        setWhatsUrl(url)
      }).catch(() => { })
  }, [removerItem])

  const handleUpdateContact = useCallback(item => {
    setHandleContact(true)
    setInitialData(item)

    setOptionsContact([
      { label: 'Facebook', value: 'facebook' },
      { label: 'Instagram', value: 'instagram' },
      { label: 'Whatsapp', value: 'whatsapp' },
      { label: 'E-mail', value: 'email' },
      { label: 'Linkedin', value: 'linkedin' },
      { label: 'Youtube', value: 'youtube' },
      { label: 'Twiter', value: 'twiter' },
    ])
  }, [])

  const submit = useCallback(async data => {
    const _layout = {
      "title": data.title,
      "title_font_color": data.title_font_color,
      "description": data.description,
      "description_font_color": data.description_font_color,
      "background_color": data.background_color,
    }

    await api.post('layout-contacts', _layout)
      .then(result => {
        setLayout(result.data)
        setEditingLayout(false)
        toastSuccess('Layout Contato atualizado!')
      })
  }, [])

  const saveContact = useCallback(async data => {
    data.icon = "icon"

    await api.post('tenant-contacts', data)
      .then(result => {
        setEditingContact(false)
        setHandleContact(false)
        toastSuccess('Novo contato incluído!')
        getContacts()
      })
      .catch(error => toastError(handleError))
  }, [getContacts])

  const deleteContact = useCallback(async item => {
    await api.delete(`tenant-contacts/${item.id}`)
      .then(() => {
        setEditingContact(false)
        setHandleContact(false)
        toastSuccess('Contato removido com sucesso!')
        getContacts()
      })
      .catch(error => toastError(handleError))
  }, [getContacts])

  const updateContact = useCallback(async (data, id) => {
    data.icon = 'icon'
    await api.put(`tenant-contacts/${id}`, data)
      .then(() => {
        setEditingContact(false)
        setHandleContact(false)
        toastSuccess('Contato atualizado com sucesso!')
        getContacts()
      })
      .catch(error => toastError(handleError))
  }, [getContacts])

  useEffect(() => {
    const source = axios.CancelToken.source()

    api.get('layout-contacts')
      .then(result => setLayout(result.data))
      .catch(() => { })

    return () => source.cancel()
  }, [])

  useEffect(() => {
    getContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container layout={layout}>
      {!editingLayout && (
        <div className="row text-center">
          <div className="col">
            <h2>{layout.title}</h2>
            <h5>{layout.description}</h5>
          </div>
        </div>
      )}

      {editingLayout && (
        <Form
          ref={formRef}
          initialData={layout}
          onSubmit={submit}
          noValidate>
          <div className="row col pr-0 pl-3 justify-content-center">
            <div className="col-sm col-md-6 d-flex pr-0">
              <InputLabel name="title" label="Título" />
              <InputColorLabel
                name="title_font_color"
                label="Cor Fonte"
                height="42px"
                defaultValue={layout.title_font_color} />
            </div>
          </div>

          <div className="row col pr-0 pl-3 justify-content-center">
            <div className="col-sm col-md-6 d-flex pr-0">
              <InputLabel name="description" label="Descrição" />
              <InputColorLabel
                name="description_font_color"
                label="Cor Fonte"
                height="42px"
                defaultValue={layout.description_font_color} />
            </div>
          </div>

          <div className="row col pr-0 pl-3 justify-content-center">
            <div className="col-sm col-md-6 p-0">
              <InputColorLabel
                name="background_color"
                label="Cor Fundo"
                setColor={() => setLayout({ ...layout, background_color: formRef.current.getFieldValue('background_color') })}
                defaultValue={layout.background_color} />
            </div>
          </div>

          <div className="row col pr-0 pl-3 justify-content-center">
            <div className="col-12 text-center mb-3">
              <button type="submit" className="btn btn-lg btn-info">
                Atualizar
              </button>
              <button type="button"
                className="btn btn-lg btn-danger m-1"
                onClick={() => setEditingLayout(false)}>
                Cancelar
              </button>
            </div>
          </div>
        </Form>
      )}

      {!editingLayout && (
        <div className="col-12 text-center mt-4">
          <button
            className="btn btn-info"
            onClick={() => {
              setEditingLayout(true)
            }}>
            Editar Layout
          </button>
        </div>
      )}

      {contacts.length === 0 && !handleContact && (
        <div className="row text-center mt-3 justify-content-center">
          <div className="col-sm col-md-5  bg-light p-2">
            <h6>Você não possui contatos cadastrados!</h6>
            <span>Clique em EDITAR REDES SOCIAIS</span>
          </div>
        </div>
      )}

      {!handleContact && (
        <div className="row justify-content-center mt-4">
          {contacts.map((item, i) => (
            <div key={i} className="col-4 col-md-2 mb-5">
              <div className="p-2 card">
                {item.name === 'whatsapp' && (
                  <a target="_blank" href={whatsUrl} rel="noopener noreferrer">
                    <FaWhatsapp size={50} color="#34af23" className="col" />
                  </a>
                )}

                {item.name === 'email' && (
                  <a target="_blank" href={`mailto: ${item.value}`} rel="noopener noreferrer">
                    <FaRegEnvelope size={50} color="#da4d39" className="col" />
                  </a>
                )}

                <a target="_blank" href={item.value} rel="noopener noreferrer">
                  {item.name === 'facebook' && (
                    <FaFacebookF size={50} color="#3b5998" className="col" />
                  )}

                  {item.name === 'instagram' && (
                    <FaInstagram size={50} color="#C13584" className="col" />
                  )}

                  {item.name === 'linkedin' && (
                    <div className="text-center">
                      <FaLinkedinIn size={45} color="#007bb6" className="col" />
                    </div>
                  )}

                  {item.name === 'youtube' && (
                    <AiOutlineYoutube size={50} color="#bb0000" className="col" />
                  )}

                  {item.name === 'twiter' && (
                    <FiTwitter size={45} color="#007bb6" className="col" />
                  )}
                </a>

                {editingContact && (
                  <div className="row text-center mt-1">
                    <div className="col-sm mb-1">
                      <button
                        className="btn btn-sm btn-primary btn-block"
                        onClick={() => handleUpdateContact(item)}>
                        Editar
                    </button>
                    </div>
                    <div className="col-sm">
                      <button type="button"
                        className="btn btn-sm btn-danger btn-block"
                        onClick={() => deleteContact(item)}>
                        Excluir
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {editingContact && (
            <div className="col-12 text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setHandleContact(true)
                  getContacts()
                  setInitialData({})
                }}>
                Adicionar Rede Social
              </button>
              <button type="button"
                className="btn btn-lg btn-warning m-2"
                onClick={() => {
                  setEditingContact(false)
                  getContacts()
                  setInitialData({})
                }}>
                Cancelar
              </button>
            </div>
          )}
        </div>
      )
      }

      {handleContact && (
        <div className="row justify-content-center mt-4">
          <h6>Adicione suas redes sociais</h6>

          <div className="col-12">
            <Form
              ref={formRef}
              initialData={initialData}
              noValidate>
              <div className=" row justify-content-center">
                <div className="col-sm col-md-4">
                  <SelectLabel
                    name="name"
                    label="Rede Social"
                    placeholder="Escolha"
                    defaultValue={initialData.name}
                    options={optionsContact} />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-sm col-md-4">
                  <InputLabel placeholder="Link da rede social" name="value" label="Link" />
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                {initialData.name ? (
                  <button type="button"
                    onClick={() => updateContact(formRef.current.getData(), initialData.id)}
                    className="btn btn-lg btn-info col-md-1">
                    Atualizar
                  </button>
                ) : (
                    <button type="button" onClick={() => saveContact(formRef.current.getData())}
                      className="btn btn-lg btn-info col-md-1">
                      Salvar
                    </button>
                  )}
                <button type="button"
                  className="btn btn-lg btn-danger col-md-1 ml-2"
                  onClick={() => setHandleContact(false)}>
                  Cancelar
                </button>
              </div>
            </Form>
          </div>
        </div>
      )
      }

      {
        !editingContact && (
          <div className="col-12 text-center">
            <button
              className="btn btn-info"
              onClick={() => {
                setEditingContact(true)
              }}>
              Editar Redes Socias
          </button>
          </div>
        )
      }
    </Container >
  )
}

export default ContactAdmin

export const Container = styled.div`
  font-family: 'Oswald', sans-serif;
  background-color: ${props => props.layout.background_color};
  padding: 20px;
  margin-left: -20px !important;
  margin-right: -20px !important;

  h2 {
    color: ${props => props.layout.title_font_color};
    font-weight: bold;
    font-size: 22px
  }

  h5{
    font-size: 18px;
    font-style: italic;
    color: ${props => props.layout.description_font_color};
  }

  a {
    :hover { cursor: pointer;}
  }

  .react-select__menu {
    max-height: 120px;
  }

  .react-select__menu-list {
    max-height: 120px;
  }
`

export const DeleteContact = styled.div`
  margin-top: 5px;

  .bg-color { 
    background: #ff0000;
    height: 20px;
    border-radius: 4px;

    :hover {
      cursor: pointer;
      background: #b30000;
    }
  }

  p {
    color: white;
  }
`