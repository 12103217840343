import React, { useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import DeleteCourse from './DeleteCourse'

function AreaCourses(props) {
  const [courses, setCourses] = useState([])

  useEffect(() => {
    setCourses(props.courses)
  }, [props])
  return (
    <Container>
      <ul className="list-group mt-3">
        <li className="list-group-item row main-title">
          <h6>Cursos</h6>
        </li>
        {courses.map((course, i) => (
          <CourseLi key={i} index={i} className="list-group-item d-flex justify-content-between align-items-center">
            <Link to={`/courses/${course.id}`} className="col-11">
              <span><b>{course.number}</b> {course.name}</span>
            </Link>

            <div className="col-sm text-right">
              <DeleteCourse id={course.id} name={course.name} />
            </div>
          </CourseLi>
        ))}
      </ul>
      <div className="col-sm col-md-2 mt-3 p-0">
        <Link to={`/areas/${props.area_id}/courses/new`}>
          <button className="btn btn-lg btn-block btn-info">
            <IoMdAdd /> Add Curso
          </button>
        </Link>
      </div>
    </Container >
  )
}
AreaCourses.defaultProps = {
  courses: Array,
  area_id: String,
}

export default AreaCourses

export const Container = styled.div`
  h6 {
    font-size: 1.05rem;
    font-weight: bold;
  }

  span {
    font-size: 0.95rem;
  }
`

export const CourseLi = styled.div`
  /* background-color: transparent !important; */
  background-color: ${props => props.index % 2 === 0 ? 'transparent' : '#f9f9f9'} !important;

  :hover{
    cursor: pointer;
    background-color: #d8d8d8!important;
  }
`