import React, { Component } from 'react'
import Cropper from 'react-cropper'
import { connect } from 'react-redux'
import { FaUpload, FaCheck, FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { FaArrowUp, FaArrowDown, FaRedoAlt } from 'react-icons/fa'
import { FaUndoAlt, FaArrowsAltH, FaArrowsAltV } from 'react-icons/fa'
import { MdZoomIn, MdZoomOut, MdClose, MdRestore } from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import MoonLoader from "react-spinners/MoonLoader";

import api from '../../actions/api'
import { setUserRedux } from '../../actions/user'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import { Preview, GroupButton } from './styles'

class CropImage extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this)

    this.state = {
      loading: false,
      imageUrl: null,
      croppedImage: null,
      show: false,
      size: null
    }
  }

  componentDidUpdate() {
    if (this.props.show && !this.state.show)
      this.setState({ show: this.props.show })

    if (this.props.image && !this.state.imageUrl)
      this.setState({ imageUrl: this.props.image })

    if (this.props.size && !this.state.size)
      this.setState({ size: this.props.size })
  }

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const type = e.target.files[0].type
      const imageDataUrl = await this.readFile(e.target.files[0])
      this.setState({
        contentType: type,
        imageUrl: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
      })
    }
  }

  readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  cropperImage(e) {
    e.preventDefault()

    if (typeof this.cropper.getCroppedCanvas() === 'undefined') return;

    const data = this.cropper.getCroppedCanvas().toDataURL();

    this.setState({ croppedImage: data });

    return false
  }

  submit = async () => {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') return

    const data = this.cropper.getCroppedCanvas().toDataURL()

    const i = data.indexOf('base64,');
    const buffer = Buffer.from(data.slice(i + 7), 'base64');
    const file = new File([buffer], 'imagem.png', { type: `${this.state.contentType || 'image/png'}` });

    let formData = new FormData()
    formData.append('file', file)

    for (let i = 0; i < this.props.appendFormData.length; i++) {
      const element = this.props.appendFormData[i];
      formData.append(element.field, element.value)
    }

    this.setState({ loading: true })

    await api.post(this.props.post_url, formData)
      .then(() => {
        toastSuccess('Imagem alterada com sucesso!')
        this.setState({ loading: false })
        this.props.hide()
        this.setState({ show: false })
      })
      .catch(error => {
        toastError(handleError(error))
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <>
        {this.state.show && (
          <div className="row mb-5">
            <div className="col-12 text-center  mt-3">
              <h5><b>Alterar imagem de perfil</b></h5>
            </div>
            <div className="col-sm col-md-8">
              <Cropper
                style={{ height: 350, width: '100%' }}
                aspectRatio={parseInt(this.state.size)}
                preview=".img-preview"
                guides={false}
                src={this.state.imageUrl}
                ref={cropper => { this.cropper = cropper }}
              />
            </div>

            <Preview className="box col-sm">
              {!this.state.loading ? (
                <>
                  <h6>Preview</h6>
                  <div className="img-preview img-thumbnail" />
                </>
              ) : (
                  <div className="col-sm loader">
                    <MoonLoader color="#0288d1" />
                    <span className="text-info">...salvando imagem</span>
                  </div>
                )}
            </Preview>
            <div className="col-sm col-md-12">
              <div className="row col justify-content-center mt-3 p-0">
                <GroupButton className="group d-flex m-1">
                  <button
                    onClick={e => {
                      this.cropper.zoom(0.1)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <MdZoomIn size={24} data-tip='' data-for="zoonIn" />
                    <ReactTooltip id='zoonIn'>Zoom +</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.zoom(-0.1)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <MdZoomOut size={24} data-tip='' data-for="zoonOut" />
                    <ReactTooltip id='zoonOut'>Zoom -</ReactTooltip>
                  </button>
                </GroupButton>

                <GroupButton className="group d-flex m-1">
                  <button
                    onClick={e => {
                      this.cropper.move(-20, 0)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <FaArrowLeft size={18} data-tip='' data-for="left" />
                    <ReactTooltip id='left'>Mover para esquerda</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.move(20, 0)
                      this.cropperImage(e)
                    }}
                    disabled={!this.state.imageUrl}>
                    <FaArrowRight size={18} data-tip='' data-for="right" />
                    <ReactTooltip id='right'>Mover para direita</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.move(0, 20)
                      this.cropperImage(e)
                    }}
                    disabled={!this.state.imageUrl}>
                    <FaArrowUp size={18} data-tip='' data-for="up" />
                    <ReactTooltip id='up'>Mover para cima</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.move(0, -20)
                      this.cropperImage(e)
                    }}
                    disabled={!this.state.imageUrl}>
                    <FaArrowDown size={18} data-tip='' data-for="down" />
                    <ReactTooltip id='down'>Mover para baixo</ReactTooltip>
                  </button>
                </GroupButton>

                <GroupButton className="group d-flex m-1">
                  <button
                    onClick={e => {
                      this.cropper.rotate(-45)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <FaUndoAlt size={18} data-tip='' data-for="rotate1" />
                    <ReactTooltip id='rotate1'>Girar para esquerda</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.rotate(45)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <FaRedoAlt size={18} data-tip='' data-for="rotate2" />
                    <ReactTooltip id='rotate2'>Girar para direita</ReactTooltip>
                  </button>
                </GroupButton>

                <GroupButton className="group d-flex m-1">
                  <button
                    onClick={e => {
                      this.cropper.cropper.imageData.scaleX === 1 ?
                        this.cropper.scaleX(-1) : this.cropper.scaleX(1)

                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <FaArrowsAltH size={18} data-tip='' data-for="horizontal" />
                    <ReactTooltip id='horizontal'>Inverter horizontal</ReactTooltip>
                  </button>
                  <button
                    onClick={e => {
                      this.cropper.cropper.imageData.scaleY === 1 ?
                        this.cropper.scaleY(-1) : this.cropper.scaleY(1)
                      this.cropperImage(e)
                    }} disabled={!this.state.imageUrl}>
                    <FaArrowsAltV size={18} data-tip='' data-for="vertical" />
                    <ReactTooltip id='vertical'>Inverter vertical</ReactTooltip>
                  </button>
                </GroupButton>

                <button
                  disabled={!this.state.imageUrl}
                  className="btn btn-lg btn-info m-1"
                  onClick={e => {
                    this.cropper.reset()
                    this.cropperImage(e)
                  }}>
                  <MdRestore size={20} data-tip='' data-for="reset" />
                  <ReactTooltip id='reset'>Resetar corte</ReactTooltip>
                </button>

                <button className="btn btn-lg btn-info m-1" onClick={() => {
                  this.props.hide(false)
                  this.setState({ show: false, imageUrl: null })
                }}>
                  <MdClose size={20} data-tip='' data-for="close" />
                  <ReactTooltip id='close'>Fechar / Cancelar</ReactTooltip>
                </button>

                <button
                  className="btn btn-lg btn-success m-1"
                  disabled={!this.state.imageUrl}
                  onClick={() => this.submit()}>
                  <FaCheck data-tip='' data-for="save" />
                  <ReactTooltip id='save'>Salvar</ReactTooltip>
                </button>

                <button className="btn btn-lg btn-warning btn-file m-1">
                  <label htmlFor="thumbnail" className="text-white p-0 m-0"
                    style={{ fontSize: '16px' }}>
                    <FaUpload />
                  </label>
                  <input
                    id="thumbnail"
                    type="file"
                    name="thumbnail"
                    accept="image/*"
                    onChange={e => this.onFileChange(e)}
                    data-tip='' data-for="get" />
                  <ReactTooltip id='get'>Escolher imagem</ReactTooltip>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

CropImage.defaultProps = {
  show: Boolean,
  hide: Function,
  image: String,
  appendFormData: Array, // [{field: 'id', value: '1234'}]
  post_url: String
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: (key, value) => dispatch(setUserRedux(key, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CropImage)

